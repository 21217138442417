import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {Settings} from '../../services/settings';
import {RequestsService} from '../../services/requests/requests.service';
import * as enums from '../../enums/consts';
import {Options} from 'ng5-slider';
import {BetStatesEnum} from '../../enums/consts';
import {ErrorList} from '../../enums/errors';

@Component({
  selector: 'popup-betslip',
  templateUrl: './popup-betslip.component.html',
  styleUrls: ['./popup-betslip.css']
})

export class PopupBetslipComponent implements OnInit {
  public errorList = ErrorList;
  @Input() betslipData: any;
  @Output() public closeEvent: EventEmitter<any> = new EventEmitter();
  public showMatchesResults = false;
  public activeCashoutRequest = false;
  public cashoutAmount = 100;
  public cancelPercent = 100;
  public options: Options = {
    floor: 0,
    disabled: true,
    ceil: 0
  };
  public showPayInput = false;
  public pin = null;
  public BetStatesEnum = enums.BetStatesEnum;
  public SelectionStatesEnum = enums.SelectionStateEnum;
  showCancelBtn = true;
  public notify = {
    type: '',
    msg: '',
    code: 0
  };
  public objectKeys = Object.keys;

  public cashoutAmountError = false;

  constructor(
    public settings: Settings,
    private http: RequestsService,
    private zone: NgZone
  ) {

  }

  ngOnInit() {
    // this.settings.signalREvent.subscribe((ev1) => {
    /// if (this.settings.ConnectionStarted){
    this.settings.sportConn.hubProxy.on('CashoutResult', (ev) => {
      this.activeCashoutRequest = false;
      /* if (ev.Data && ev.Data > 0) {
         this.betslipData.CashOutAmount = 0;
         this.notify.code = 5556;
         this.notify.type = 'error';
         this.notify.msg = '';
       }*/

      if (ev.Data && ev.Data === 0) {
        this.betslipData.CashOutAmount = ev.Data;
        this.notify.code = 5557;
        this.notify.type = 'error';
        this.notify.msg = '';
      }

      if (ev.HasError) {
        this.notify.code = ev.ErrorCode;
        this.notify.type = 'error';
        this.notify.msg = '';
      }
      if (!ev.HasError) {
        this.betslipData.State = 12;
        this.betslipData.CashOutAmount = 0;
        this.betslipData.Payout = this.cashoutAmount;
      }
    });


    this.settings.sportConn.hubProxy.on('betCanceled', (ev) => {
      if (ev.HasError) {
        if (ev.ErrorCode < 0) {
          this.notify.type = 'error';
          this.notify.msg = ev.ErrorMessage;
        }
      } else {
        this.betslipData.State = this.BetStatesEnum.Cancelled;
      }
    });
    // }

    // });
    this.pin = '';
    this.showPayInput = false;
    this.notify = {
      type: '',
      code: 0,
      msg: ''
    };
    if (this.betslipData.CashOutAmount > 0) {
      this.options.ceil = this.betslipData.CashOutAmount;
      this.cashoutAmount = this.betslipData.HasPartialCashout ? Number(this.betslipData.CashOutAmount / 2) : this.betslipData.CashOutAmount;
      this.options.disabled = this.betslipData.HasPartialCashout ? false : true;
    }

    this.betslipData.Selections.forEach((item, key) => {
      if (item.IsLiveBet || item.SelectionState) {
        this.showCancelBtn = false;
      }
    });

    if (this.betslipData.State === BetStatesEnum.Cashout || this.betslipData.State === BetStatesEnum.Won || (this.betslipData.HasCashout && this.betslipData.State === BetStatesEnum.Pending)) {
      this.showPayInput = true;
    }

  }

  closeModal() {
    this.closeEvent.emit(true);
  }

  pay() {
    this.settings.SuccessBetData = null;
    if (!this.showPayInput) {
      return this.showPayInput = true;
    }
    this.http.httpPost(this.settings.apiUrls.Pay, {
      betSleepId: this.betslipData.Id,
      pin: this.pin
    })
      .subscribe((res: any) => {
        if (res.HasError) {
          this.notify.code = res.ErrorCode;
          this.notify.type = 'error';
        }
        if (!res.HasError) {
          this.notify.code = 5555;
          this.notify.type = 'success';
          if (res.Data) {
            this.settings.SuccessBetData = res.Data;
          }
          this.closeModal();
        }
      });
  }

  clearError() {
    this.notify = {
      type: '',
      code: 0,
      msg: ''
    };
  }

  setSuccess() {

  }

  toCashout() {

    if (!this.showPayInput) {
      return this.showPayInput = true;
    }
    if (this.cashoutAmount <= 0 || this.cashoutAmount > this.options.ceil) {
      return this.cashoutAmountError = true;
    } else {
      this.cashoutAmountError = false;
    }

    if (this.activeCashoutRequest) {
      return false;
    }
    if (this.pin) {
      this.settings.sportConn.hubProxy.invoke('cashout', {
        BetSleepId: this.betslipData.Id,
        ShopId: localStorage.getItem('shopId'),
        ShopToken: localStorage.getItem('shoptoken'),
        Pin: this.pin,
        Amount: this.options.ceil,
        PartialAmount: this.options.ceil !== this.cashoutAmount ? this.cashoutAmount : null,
      });
      this.activeCashoutRequest = true;
    }


    /* if (this.options.ceil === this.cashoutAmount) {
       this.http.httpPost(this.settings.apiUrls.Cashout, {
         BetSleepId: this.betslipData.Id,
         Pin: this.pin,
         Amount: this.options.ceil,
       }).subscribe((res: any) => {
         if (res.Data >= 0) {
           this.betslipData.CashOutAmount = res.Data;
           this.notify.code = 5556;
           this.notify.type = 'error';
         }

         if (res.Data && res.Data === 0) {
           this.betslipData.CashOutAmount = 0;
           this.notify.code = 5557;
           this.notify.type = 'error';
         }

         if (res.Data === -1 && !res.HasError) {
           this.betslipData.State = 12;
           this.betslipData.CashOutAmount = 0;
           this.betslipData.Payout = this.cashoutAmount;
         }
       });
     }
     if (this.options.ceil > this.cashoutAmount && this.cashoutAmount > 0) {
       this.http.httpPost(this.settings.apiUrls.PartialCashout, {
         BetSleepId: this.betslipData.Id,
         Pin: this.pin,
         Amount: this.options.ceil,
         PartialAmount: this.cashoutAmount
       }).subscribe((res: any) => {

         if (res.Data && res.Data > 0) {
           this.betslipData.CashOutAmount = 0;
           this.notify.code = 5556;
           this.notify.type = 'error';
         }

         if (res.Data && res.Data === 0) {
           this.betslipData.CashOutAmount = res.Data;
           this.notify.code = 5557;
           this.notify.type = 'error';
         }
         if (!res.Data && !res.HasError) {
           this.betslipData.State = 12;
           this.betslipData.CashOutAmount = 0;
           this.betslipData.Payout = this.cashoutAmount;
         }
       });
     }*/

  }


  cancelBet() {
    this.settings.sportConn.hubProxy.invoke('CancelBet', {
      BetSleepId: this.betslipData.Id,
      Percent: this.cancelPercent
    });
  }
}
