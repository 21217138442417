import {Component, OnInit} from '@angular/core';
import {Settings} from '../../../../core/services/settings';
import {RequestsService} from '../../../../core/services/requests/requests.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-statement-totals',
  templateUrl: './statement-totals.component.html'
})

export class StatementTotalsComponent implements OnInit {
  public CashiersList = [];
  public openedFilter = true;
  public isLoading = false;
  public TerminalsList = [];
  public Data = {
    NetStake: 0.00,
    Winnings: 0.00,
    Commission: 0.00,
    Profit: 0.00,
    BetSleeps: 0,
    IntegretionType: null,
  };
  public FilterObj = {
    cashierId: '',
    terminalId: '',
    fromDate: moment().set({hour: 0, minute: 0, second: 0, millisecond: 0}),
    toDate: moment().set({hour: 23, minute: 59, second: 59, millisecond: 0}),
  };

  constructor(
    private settings: Settings,
    private http: RequestsService
  ) {
    this.http.httpGet(this.settings.apiUrls.account.GetShopTerminals).subscribe((e: any) => {
      if (!e.HasError) {
        this.TerminalsList = e.Data;
        setTimeout(() => {
          $('#select2').selectric();
        }, 100);
      }
    });
    this.http.httpGet(this.settings.apiUrls.account.GetShopCashiers).subscribe((e: any) => {
      if (!e.HasError) {
        this.CashiersList = e.Data;
        setTimeout(() => {
          $('#select1').selectric();
        }, 100);
      }
    });

  }

  ngOnInit() {
    $('select').selectric();
    $('#datetimeStatementFrom').datetimepicker({
      format: 'dddd, MMM Do YYYY, HH:mm',
      defaultDate: this.FilterObj.fromDate
    }).on('dp.change', (e) => {
      this.FilterObj.fromDate = e.date ? new Date(e.date.format()) : '';
    });
    $('#datetimeStatementTo').datetimepicker({
      format: 'dddd, MMM Do YYYY, HH:mm',
      defaultDate: this.FilterObj.toDate
    }).on('dp.change', (e) => {
      this.FilterObj.toDate = e.date ? new Date(e.date.format()) : new Date();
    });
    $('#select1').on('change', (e) => {
      this.FilterObj.cashierId = $('#select1').val();
      console.log($('#select1').val());
    });
    $('#select2').on('change', (e) => {
      this.FilterObj.terminalId = $('#select2').val();
    });
  }

  getReport() {
    this.isLoading = true;
    this.http.httpPost(this.settings.apiUrls.reports.StatementTotals, this.FilterObj).subscribe((e: any) => {
      if (!e.HasError) {
        this.Data = e.Data;
      }
      this.isLoading = false;
    });
  }

  ssd(e) {
    console.log(e);
  }

}
