<div class="col-xs-left">
  <!--ko template:'match-left-section'-->
  <div class="square-section header todays-cash">
    <div class="row">
      <div class="col-xs-6" style="display: block;">
        <div class="time">
          <span style="display: inline;">{{date | date:'HH:mm:ss'}}</span>
          <div class="date">{{date | date:'dd MMM'}}
          </div>
        </div>
      </div>
      <div class="col-xs-6">
        <div class="btn btn-shop-grey fullwidth" [routerLink]="['/management/reports/todays-cash']">
          <i class="ifa-inline fa fa-thumbs-up"></i>
          {{'Todays cash' | translate}}
        </div>
      </div>
    </div>
  </div>
  <!-- Markets by date-->
  <div class="square-section header bet-aproval">
     <div class="row">
       <div class="col-xs-6">
         <a class="markets-title" title="{{'Reports' | translate}}" [routerLink]="['/management/reports']">
           <span> {{'Reports' | translate}}</span>
         </a>
       </div>
       <div class="col-xs-6" style="display: block;">
         <div class="btn btn-shop-grey fullwidth"  [routerLink]="['/management/bet-approval']">
           <i class="ifa-inline fa fa-repeat"></i>
           {{'Bet approvals' | translate}}
         </div>
       </div>
     </div>
  <!--  Search input-->
    <div class="row">
      <div class="col-xs-12">
        <div class="custom-search-input">
        <!--  <div class="col-xs-3 search_block_icons">
            <span  [routerLink]="['/management/bet-approval']" style="margin-right: 15px;">
              <i class="ifa-inline fa fa-repeat"></i>
            </span>
            <span  [routerLink]="['/management/reports/todays-cash']">
              <i class="ifa-inline fa fa-thumbs-up"></i>
            </span>
          </div>-->

          <div class="input-group col-xs-12">
            <input type="text" class="form-control input-md" name="gameFilter" placeholder="{{'Id, Category, League, Team' | translate}}" [(ngModel)]="gameFilter" (ngModelChange)="searchUpdate.next($event)">
            <span class="input-group-btn">
                <button class="btn btn-input primary-color" *ngIf="gameFilter" (click)="getGameFilter('')">
                    <i class="fa fa-times offset-right20"></i>
                </button>
                <button class="btn btn-input" type="button">
                    <i class="fa fa-search"></i>
                </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="matches-left-section" style="display: block;">
    <div class="matches-container">
      <ng-template ngFor let-match [ngForOf]="sportService.MatchesList | order: 'o' : 'desc'">
        <div class="row-match match-hover-row " [routerLink]="['/game', match.Id]"
             (mouseenter) ="match.hover = true" (mouseleave) ="match.hover = false"
             [ngClass]="{active: match.hover, 'row-live txt-live': match.o, 'row-live-soon txt-live-soo': match.c && (match.i != 'Interrupted' || match.i != 'Suspended'), ' row-susp text-suspended': match.i == 'Interrupted' || match.i == 'Suspended'}">
          <span class="" *ngIf="match.o" [ngClass]="{'susp': (match.i == 'Interrupted' || match.i == 'Suspended'), live: match.o && (match.i != 'Interrupted' || match.i != 'Suspended')}">
              <span class="fa fa-play" *ngIf="!match.IsBreak"></span>
              <span class="fa fa-pause" *ngIf="match.IsBreak"></span>
              <!--<span class="txt liveStatusText" *ngIf="match.IsBreak">SUSPENDEND</span>-->
              <span class="txt liveStatusText" *ngIf="match.HF " title="{{match.i}}">
                {{match.i | translate}}
              </span>
            <!--/ko-->
          </span>
          <div class="left-details" *ngIf="!match.o">
            <div class="info-marketId" title="{{match.Id}}">{{match.Id}}</div>
            <div class="time" *ngIf="!match.or">
              {{match.af | date: 'HH:mm'}}
              <span class="date">{{match.af | date: 'dd/MM'}}</span>
            </div>
          </div>
          <div class="middle-details marketId-live">
            <div class="info-marketId"  *ngIf="match.o">
              {{match.Id}}
            </div>
            <!--/ko-->
            <img src="./assets/images/flags/{{match.e}}.png" alt="324" class="flag-24" title="{{match.f}}">
            <div class="info-shortCode" title="{{match.f}}">{{match.s}}
            </div>
          </div>
          <div class="details">
            <div class="info-team" title="{{match.eventName}}"  *ngIf="match.or">
              {{match.eventName}}
            </div>
            <div class="info-team" title="{{match.j}}"  *ngIf="!match.or">
              {{match.j}}
            </div>
            <div class="info-team info-away-team" title="{{match.a}}" *ngIf="!match.or">
              <span>{{match.a}}</span>
              <span class="info-live-txt" *ngIf="match.c && !match.o">L</span>
            </div>
            <div class="info-match">
              <div class="info-match-soccer"  *ngIf="match.o">
                <div class="info-match-live-minute" *ngIf="match.ad">
                  <span title="{{match.ah}}&#39;">{{core.getMinuteFromUpdate(match)}}&#39; </span>
                </div>
                <div class="info-match-live-redcards">
                  <span title="0" style="display: none;">0</span>
                </div>

                <div class="info-match-live-yellowcards">
                  <span title="{{match.ai}}" *ngIf="match.ai">{{match.ai}}</span>
                </div>
                <div class="info-match-live-score">
                  <span title="{{match.k}}:{{match.b}}">{{match.k}}:{{match.b}}</span>
                </div>
                <div class="info-match-live-redcards">
                  <span title="{{match.ac}}" *ngIf="match.ac">{{match.ac}}</span>
                </div>
                <div class="info-match-ht">
                  <span title="HT" style="display: none;">HT</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <nav class="text-center">
          <ul class="pagination">
            <li [ngClass]="{disabled:sportService.PageIndex == 1}">
              <i class="fa fa-angle-double-left" aria-hidden="true" (click)="starPage()"></i>
            </li>
            <li [ngClass]="{disabled:sportService.PageIndex == 1}">
              <i class="fa fa-angle-left" aria-hidden="true" (click)="backPage()"></i>
            </li>
            <!-- ko foreach: PageButtons -->
            <ng-template let-page ngFor [ngForOf]="sportService.PagingArray">
              <li class="active" [ngClass]="{active: page == sportService.PageIndex}" (click)="changePage(page)">
                <a>{{page}}</a>
              </li>
            </ng-template>

            <li [ngClass]="{disabled:sportService.PageIndex == sportService.PagesCount}">
              <i class="fa fa-angle-right" aria-hidden="true" (click)="nextPage()"></i>
            </li>
            <li [ngClass]="{disabled:sportService.PageIndex == sportService.PagesCount}">
              <i class="fa fa-angle-double-right" aria-hidden="true" (click)="endPage()"></i>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>

  <script type="text/html" id="market-template">
    <!-- ko with: Market -->
    <div class="row-match match-hover-row">
      <span>
            <span class="fa"></span>
            <span class="txt"></span>
        <!--/ko-->
        </span>
      <div class="left-details">
        <div class="info-marketId"></div>
        <div class="time">
          <span class="date"></span>
        </div>
      </div>
      <!--/ko-->
      <div class="middle-details">
        <div class="info-marketId"></div>
        <!--/ko-->
        <img src="#" alt="" class="flag-24">
        <div class="info-shortCode"></div>
      </div>
      <div class="details">

        <div class="info-team outright"></div>
        <div class="info-team"></div>
        <div class="info-team info-away-team">
          <span></span>
          <span class="info-live-txt">L</span>
        </div>
        <div class="info-match">
        </div>

      </div>
    </div>
    <!--/ko-->
  </script>
  <!--/ko-->
</div>
