import {Component} from '@angular/core';
import {Settings} from '../../services/settings';
import {AccountReqService} from '../../services/requests/account-req.service';
import {Router} from '@angular/router';
@Component({
  selector: 'menu-component',
  templateUrl: './menu.component.html'
})

export class MenuComponent {
  constructor(
    public settings: Settings,
    private router: Router,
    public accountReqService: AccountReqService
  ) {

  }

  logout() {
    this.accountReqService.logAut();
  }

  resetBetsInfo() {
    this.settings.betsInfo.BetsAmount = 0;
    this.settings.betsInfo.BetsCount = 0;
    localStorage.removeItem('betsInfo');
  }
}
