<div class="dashboard-middleContent">
  <div class="row">
    <div class="col-xs-12">
      <div class="page-header">
        <div class="nav nav-tabs">
          <div [routerLink]="'/management/reports/todays-cash'"  routerLinkActive="active">
            <span>{{'Todays cash' | translate}}</span>
          </div>

          <div  [routerLink]="'/management/reports/statement-totals'"  routerLinkActive="active">
            <span>{{'Statement Totals' | translate}}</span>
          </div>

          <div  [routerLink]="'/management/reports/account-statement'"  routerLinkActive="active">
            <span>{{'Account Statement' | translate}}</span>
          </div>

          <div  [routerLink]="'/management/reports/betting-history'"  routerLinkActive="active">
            <span>{{'Betting History' | translate}}</span>
          </div>

        </div>
      </div>
      <router-outlet></router-outlet>

    </div>
  </div>
</div>
