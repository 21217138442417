export const Languages = [
  {code: 'en', text: 'English'},
  {code: 'it', text: 'Italiano'},
  {code: 'ge', text: 'Deutsche'},
  {code: 'ru', text: 'Russian'},
  {code: 'el', text: 'Ελληνικά'}
];

export const BetAprovState = {
  '-1': 'All Requests',
  '0': 'Pending',
  '1': 'Approved',
  '2': 'Rejected',
  '3': 'Expired',
};

export const BetStatesList = [
  {Id: '0', Name: 'Pending'},
  {Id: '6', Name: 'Won'},
  {Id: '10', Name: 'Lost'},
  {Id: '12', Name: 'Cashout'},
  {Id: '11', Name: 'All betslips (including cancelled)'},
];

export enum BetStatesEnum {
  Pending = 0,
  WaitingApproval = 3,
  Approved  = 4,
  Rejected = 5,
  Won = 6,
  Paid   = 7,
  Voided  = 8,
  Draft = 9,
  Lost = 10,
  Cancelled = 11,
  Cashout = 12,
}

export enum SelectionStateEnum {
  Lost = 1,
  Won = 2,
}


export enum TransactionTypes {
  BalanceBroughtForward = -1,
  Deposit = 0,
  Withdraw = 1,
  Bet = 2,
  Commission = 3,
  Win = 4,
  Cashout = 5,
  VoidedRefund = 6,
  CanceledRefund = 7,
  ComAdjustment = 8,
}

export const TransactionList = [
  {Id: -1, Name: 'BalanceBroughtForward'},
  {Id: 0, Name: 'Deposit'},
  {Id: 1, Name: 'Withdraw'},
  {Id: 2, Name: 'Bet'},
  {Id: 3, Name: 'Commission'},
  {Id: 4, Name: 'Win'},
  {Id: 5, Name: 'Cashout'},
  {Id: 6, Name: 'VoidedRefund'},
  {Id: 7, Name: 'CanceledRefund'},
  {Id: 8, Name: 'ComAdjustment'},
];

export const DateFilterList = [
  {Id: 0, Name: 'All'},
/*  {Id: 1, Name: 'Outrights'},
  {Id: 2, Name: 'All and outrights'},*/
  {Id: 3, Name: '1 hour'},
  {Id: 4, Name: '3 hours'},
  {Id: 5, Name: '5 hours'},
  {Id: 6, Name: '1 day'},
  {Id: 7, Name: '3 days'},
  /*{Id: 8, Name: 'Live'},
  {Id: 9, Name: 'Pre-match'},*/
];

export enum OddStates {
  IsActive = 0,
  IsSuspended = 1,
  IsClosed = 2,
  IsWon = 3,
  IsLose = 4
}

export enum closeSportTypes {
  Sport = 1,
  Tournament = 2,
  Category = 4,
  Match = 12,
}
