import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {MarketsModel, MatchModel, SelectionsModel} from '../../../core/models/match.model';
import {SportService} from '../../../core/services/sport/sport.service';
import {BetSlipService} from '../../../core/services/bet-slip/bet-slip.service';
import * as _ from 'lodash';
import {ActivatedRoute} from '@angular/router';
import {Settings} from '../../../core/services/settings';
import {OddStates} from '../../../core/enums/consts';
import {CoreService} from '../../../core/services/core.service';
import {RequestsService} from '../../../core/services/requests/requests.service';
import {tymeZones} from '../../../core/enums/country';

declare var moment: any;

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html'
})

export class GameComponent implements OnInit, OnDestroy {
  public timeZone = tymeZones[localStorage.getItem('timeZone') ? localStorage.getItem('timeZone') : 0].GMT;
  public match: MatchModel;
  public column = 6;
  private sub: any;
  private matchId = null;
  public BetType = '';
  public OddStates = OddStates;
  public selectedGroupId = '';
  public isLoading = false;

  constructor(
    private http: RequestsService,
    private core: CoreService,
    public sportService: SportService,
    private route: ActivatedRoute,
    public betSlipService: BetSlipService,
    public zone: NgZone,
    private settings: Settings
  ) {

    this.sub = this.route.params.subscribe(params => {
      if (params.id) {
        this.matchId = Number(params.id);
        this.sportService.activeMatchId = params.id;
        this.settings.signalREvent.subscribe((res: any) => {

          this.GetMatchById(params.id);
        });
      }
    });
    this.settings.sportConn.hubProxy.on('SingleMatchOdds', (e) => {
      this.zone.run(() => {
        this.match = JSON.parse(JSON.stringify(e));
        if (e.af.indexOf('Z') === -1) {
          e.af = e.af + 'Z';
        }
        this.match.af = moment(e.af).utcOffset(this.timeZone).format('YYYY-MM-DD HH:mm');
        this.betSlipService.BetSlipList.forEach((item, key) => {
          if (this.match.Id === item.matchId) {
            this.betSlipService.SelectSelection.emit(item);
          }
        });
      });
    });

    this.settings.sportConn.hubProxy.on('liveInfo', (evnt) => {


      if (evnt && evnt.length) {
        evnt.forEach((item, key) => {
          if (this.match.Id == evnt.EventId) {
            this.match.IsBreak = item.Change.IsBreak;
            this.match.ac = item.Change.ac;
            this.match.ai = item.Change.ai;
            this.match.HF = item.Change.HF;
            this.match.ah = item.Change.ah;
            this.match.IsBreack = item.Change.IsBreack;
            this.match.k = item.Change.k;
            this.match.b = item.Change.b;
          }
        });
      }
    });

    this.settings.sportConn.hubProxy.on('OddChange', (e) => {
      if (this.match && this.match.Id === e.EventId) {
        this.zone.run(() => {
          this.oddChanged(e);
        });
      }
    });

    this.settings.sportConn.hubProxy.on('NewMarket', (e) => {
      if (this.match && this.match.Id === e.EventId) {
        this.zone.run(() => {
          this.newMarketEvent(e);
        });
      }
    });

    this.settings.sportConn.hubProxy.on('SuspendMatch', (e) => {
      if (this.match && this.match.Id === e.EventId) {
        this.zone.run(() => {
          this.SuspendMatch(e);
        });
      }
    });

  }

  public SuspendMatch(event) {
    this.match.Markets.forEach((market: MarketsModel, mKey) => {
      market.Show = false;
    });
  }

  private newMarketEvent(event) {
    this.match.Markets.push(event.Change);
  }

  private oddChanged(event) {
    if (this.match.Id === event.EventId) {
      this.match.Markets.forEach((market: MarketsModel, mKey) => {
        /*if (!this.match.o && market.State === 66) {
          return false;
        }*/
        if (market.f == event.Change.f) {
          if (event.Change.MarketChanges.length) {
            event.Change.MarketChanges.forEach((item, key) => {
              if (item.Key === 'Show') {
                market[item.Key] = item.Value == '0' ? false : true;
              } else {
                market[item.Key] = item.Value;
              }
            });
          }
          if (event.Change.SelectionChanges) {
            event.Change.SelectionChanges.forEach((s: SelectionsModel, sI) => {
              if (s.IsNew) {
                market.j.push({
                  c: s.Id,
                  Suspended: s.Suspended,
                  Id: s.SelectionId,
                  Name: s.Name,
                  Odd: s.Odd,
                  Order: s.Order,
                });
              }
              if (s.Change) {
                market.j.forEach((selection, sKey) => {
                  if (selection.c === s.Id) {
                    s.Change.forEach((sIt, sInd) => {
                      if (sIt.Key === 'Odd' && sIt.Value !== selection.Odd) {
                        if (sIt.Value > selection.Odd) {
                          this.core.setTimeoutToSelection(selection, 'Up', sIt.Value);
                        }
                        if (sIt.Value < selection.Odd) {
                          this.core.setTimeoutToSelection(selection, 'Down', sIt.Value);
                        }
                      }
                      if (sIt.Key === 'e') {
                        selection.e = Number(sIt.Value);
                      }
                    });

                  }
                });
              }
            });
          }
        }
      });
    }
  }

  ngOnInit() {
    this.betSlipService.RemoveSelection.subscribe((e) => {
      if (this.match.Id === e.matchId) {
        const market = _.filter(this.match.Markets, {f: e.marketId});
        if (market.length) {
          market.forEach((item, ind) => {
            const selection = _.find(item.j, {c: e.selectionId});
            if (selection) {
              selection.selected = false;
            }
          });

        }
      }

    });
    this.betSlipService.SelectSelection.subscribe((e) => {
      if (this.match.Id === e.matchId) {
        const market = _.find(this.match.Markets, {f: e.marketId});
        if (market) {
          const selection = _.find(market.j, {c: e.selectionId});
          if (selection) {
            selection.selected = true;
          }
        }
      }

    });

  }

  addToBetSlip(matchId, marketId, marketName, minSelectionCount, selection) {
    const data = {
      matchId,
      isLive: this.match.o,
      home: this.match.j,
      away: this.match.a,
      eventName: this.match.eventName,
      category: this.match.f,
      categoryId: this.match.d,
      tournament: this.match.Competition,
      tournamentId: this.match.t,
      marketId,
      marketName,
      minSelectionCount,
      selectionId: selection.c,
      selection,
    };
    selection.selected = !selection.selected;

    this.betSlipService.addToBetSlip(data);

  }


  setNumberOfColumns(numb) {
    this.column = numb;
  }

  ExpandSelections(type) {
    if (this.match) {
      this.match.Markets.forEach((m, mI) => {
        m['open'] = type;
      });
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  GetMatchById(matchId) {
    const obj = {
      ConnectionId: this.settings.sportConn.connectionId,
      LanguageId: this.settings.activeLang,
      SportId: this.sportService.activeSportId,
      /*PageIndex: 1,
      PageSize: 5,*/
      EventId: !matchId ? 0 : matchId
    };

    this.isLoading = true;
    this.http.httpPost(`${this.settings.apiUrls.book.singleMatchOdds}`, obj)
      .subscribe((res: any) => {
        if (!res.HasError && res.Data) {
          if (res.Data.or) {
            this.column = 12;
          }
          this.match = res.Data;
          if (res.Data.af.indexOf('Z') === -1) {
            res.Data.af = res.Data.af + 'Z';
          }
          this.match.af = moment(res.Data.af).utcOffset(this.timeZone).format('YYYY-MM-DD HH:mm');
        }
        if (this.betSlipService.BetSlipList.length) {
          this.betSlipService.BetSlipList.forEach((item, index) => {
            if (this.match.Id == item.matchId) {
              const market = _.find(this.match.Markets, {f: item.marketId});
              if (market) {
                const selection = _.find(market.j, {c: item.selectionId});
                if (selection) {
                  selection.selected = true;
                }
              }
            }
          });
        }
        this.isLoading = false;
      });

    /*if (this.ConnectionStarted) {
      this.settings.sportConn.hubProxy.invoke('GetSingleMatchWithOdd', obj);
    } else {
      this.settings.sportConn.starting$.subscribe((e) => {
        this.settings.sportConn.hubProxy.invoke('GetSingleMatchWithOdd', obj);
      });
    }*/

  }

  hasActiveSellections(market) {
    let actives = 0;
    if (market.j && market.j.length) {
      market.j.forEach((selection, index) => {
        if (selection.e === OddStates.IsActive) {
          actives++;
          return true;
        }
      });

      if (actives > 0) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

}
