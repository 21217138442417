<div class="col-xs-middle" style="display: block;">
  <div class="col-middle">
    <div class="overflow-hidden">
      <!--Match Selection Header-->
      <!-- Scoreboard-->
      <div class="row" *ngIf="match">
        <div class="col-xs-12">
          <div class="match-selection-box">
            <!-- ko with: Market -->
            <div class="match-selection-title static">
              <div class="close" *ngIf="!match.or">
                <a routerLink="/sport" class="txtg">
                  <i class="fa fa-long-arrow-left"></i>
                </a>
              </div>
              <div class="time" *ngIf="!match.o && !match.or">
                <span>{{match.af | date: 'HH:mm'}}</span>
                <span class="date">{{match.af | date: 'dd MMM'}}</span>
              </div>
              <div class="middle-info">
                <img [src]="'./assets/images/flags/'+match.e+'.png'" alt="46" class="flag-32" title="{{match.f}}">
                <div class="market-id" title="{{match.Id}}">{{match.Id}}</div>
              </div>
              <div class="teams">
                <div class="teamsName" title="{{match.eventName}}">{{match.eventName}}</div>
                <div class="leagueName" title="{{match.f}} - {{match.r}}">{{match.f}} - {{match.r}}</div>
              </div>


              <div class="expand-options lmt-options btn-group" *ngIf="!match.or">
                <a href="javascript:void(0)" title="{{'TV Odds Viewer' | translate}}" class="btn match-selection-all">
                  <i class="fa fa-desktop"></i>
                </a>

                <a href="javascript:void(0)" title="{{'Head To Head' | translate}}" class="btn match-selection-all">
                  <i class="fa fa-bar-chart"></i>
                </a>


                <a href="javascript:void(0)" title="{{'Head To Head' | translate}}" class="btn match-selection-all">
                  <i class="fa fa-pie-chart"></i>
                </a>

                <!--ko if: typeof ShopLiveMatchTrackerStatus != "undefined" && (ShopLiveMatchTrackerStatus == 1 || ShopLiveMatchTrackerStatus == 2) -->
                <a href="javascript:void(0)" title="{{'Toggle Match Tracker' | translate}}" class="btn match-selection-all active">
                  <i class="fa fa-eye"></i>
                </a>

              </div>


              <div class="expand-options btn-group">
                <a href="javascript:void(0)" title="{{'Expand all' | translate}}" class="btn match-selection-all" (click)="ExpandSelections(true)">
                  <i class="fa fa-plus-circle"></i>
                </a>
                <a href="javascript:void(0)" title="{{'Collapse all' | translate}}" class="btn match-selection-all" (click)="ExpandSelections(false)">
                  <i class="fa fa-minus-circle"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row" *ngIf="match">
        <div class="col-xs-12">
          <div class="square-section options-row">
            <!--Search Bet type-->
            <div class="search-box">
              <div class="custom-input lightgreen btn-fullwidth">
                <div class="input-group col-xs-12">
                  <input type="text" class="form-control lightgreen input-md" placeholder="{{'Bet type' | translate}}" [value]="BetType" (keyup)="BetType=$event.target.value">
                  <span class="input-group-btn">
                    <button class="btn btn-input primary-color" *ngIf="BetType" (click)="BetType = ''">
                        <i class="fa fa-times offset-right20"></i>
                    </button>
                    <button class="btn btn-input" type="button">
                        <i class="fa fa-search"></i>
                    </button>
                  </span>
                </div>
              </div>
            </div>

            <div class="option-tabs">
              <div class="btn-group" data-toggle="buttons">
                <div class="btn btn-shop-grey"  (click)="selectedGroupId = ''" [ngClass]="{active: selectedGroupId == ''}">
                  <div class="bet">
                    <div class="title-bet-type" data-toggle="tooltip" data-placement="bottom" title="{{'ALL BET TYPES' | translate}}">{{'ALL BET TYPES' | translate}}</div>
                  </div>
                </div>
                <ng-template let-group ngFor [ngForOf]="match.w">
                  <div class="btn btn-shop-grey"  [ngClass]="{active: selectedGroupId == group.Id}" (click)="selectedGroupId = group.Id">
                    <div class="bet">
                      <div class="title-bet-type" data-toggle="tooltip" data-placement="bottom" title="{{group.Name}}">{{group.Name}}</div>
                    </div>
                  </div>
                </ng-template>


              </div>

            </div>

            <!--Select how many columns you want-->
            <div class="filters">
              <div class="columns-container " [ngClass]="'columns-'+column">
                <div class="bet-filter_columns" (click)="setNumberOfColumns(12)">
                  <i class="fa fa-bars"></i>
                </div>
                <div class="bet-filter_columns" (click)="setNumberOfColumns(6)">
                  <i class="fa fa-bars"></i>
                </div>
                <div class="bet-filter_columns" (click)="setNumberOfColumns(4)">
                  <i class="fa fa-bars"></i>
                </div>
                <!--<div class="bet-filter_columns" data-bind="click: setNumberOfColumns.bind($data, 4), clickBubble: false">-->
                <!--<i class="fa fa-bars"></i>-->
                <!--</div>-->
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Events -->
      <div class="row" *ngIf="match">
        <div class="col-xs-12">
          <div class="match-bettype-container match-bettype-container-with-lmt">

            <!-- Live Match Tracker -->
            <div class="match-bettype-box live-match-tracker-box col-xs-{{column}}"  *ngIf="!match.or">
              <div class="live-match-tracker">
                <div class="lmt-btns">
                  <div class="btn-group" data-toggle="buttons">
                    <div class="btn btn-lmt active">{{'Odds' | translate}}</div>
                    <div class="btn btn-lmt">{{'Head 2 Head' | translate}}</div>
                    <div class="btn btn-lmt">{{'Statistics' | translate}}</div>
                    <div class="btn btn-lmt">{{'Table' | translate}}</div>
                    <div class="btn btn-lmt" data-bind="text: Name, click: $parent.cmdSelectLMTTab.bind($data, $data, true), clickBubble: false, css: { 'active' : IsSelected }">{{'Commentary' | translate}}</div>

                  </div>
                </div>
                <div class="lmt-container">

                </div>
              </div>
            </div>

            <!-- ko foreach: MarketGroupEventDetails-->
            <ng-template  let-market ngFor [ngForOf]="match.Markets | betType: BetType | group: selectedGroupId">
              <div class="match-bettype-box col-xs-{{column}}" style="" *ngIf="market.Show && hasActiveSellections(market)">
                <a (click)="market.open = !market.open" >
                  <div class="square-section">
                    <div class="match-bettype-title" title="{{market.Name}}">{{market.Name}}</div>
                    <div class="match-bettype-right">
                      <div class="cashoutLogo" title="{{'Has Cashout' | translate}}">
                        <img src="./assets/images/svg/coins-solid.svg"  *ngIf="market.d">
                      </div>
                      <div class="market-element-ns">
                        <span class="txt">{{market.h}}</span>
                      </div>
                      <span class="bettype-caret">
                        <i class="fa " [ngClass]="!market.open ? 'fa-minus-circle' : 'fa-plus-circle' " ></i>
                    </span>
                    </div>
                  </div>
                </a>

                <div class="totals-category collapse-bettype-category" *ngIf="!market.open">
                  <ng-template  let-selection ngFor [ngForOf]="market.j | order: 'Order' | order: 'Base'">
                    <ng-template  [ngIf]="selection.e == OddStates.IsActive">
                      <div class="col-xs-4 selection-item" (click)="addToBetSlip(match.Id, market.f, market.Name, market.h, selection)">
                        <ul class="collapse-selections">
                          <li class="bettype-cell clickable active" [ngClass]="{'active': !selection.selected, 'selected': selection.selected, 'disabled': selection.disabled, 'oddIsUp': selection.Up, 'oddIsDown': selection.Down}">

                            <div class="info">
                              <span title="{{selection.Name}}">{{selection.Name}}</span>
                              <span data-bind="text: ($parent.Name.trim() == $parents[1].EventGroupName().trim() || $parents[1].HideSBVInEventItems) ? '' : $parent.SpecialValue"></span>
                            </div>

                            <div class="odds" title="{{selection.Odd}}">{{selection.Odd}}</div>
                            <i id="caret-sport" class="fa disabled" [ngClass]="{'fa-caret-up': selection.Up, 'fa-caret-down': selection.Down}" ></i>
                          </li>
                        </ul>
                      </div>
                    </ng-template>
                  </ng-template>

                  <div class="clearboth"></div>
                </div>
              </div>
            </ng-template>

          </div>
        </div>
        <!-- ko template: { name: 'full-is-busy', data: IsBusy } -->
        <div class="full_is_busy" data-bind="visible: IsVisible" style="display: none;">
        <div class="full_is_busy_background"></div>
        <div class="blurred_area"></div>
        <div class="full_is_busy_contents">
          <div class="full_is_busy_title">
            <span class="full_is_busy_title_icon"></span>
            <span class="full_is_busy_title_text" data-bind="text:Title">{{'Please wait...' | translate}}</span>
          </div>
          <div class="full_is_busy_message" data-bind="html:Message"></div>
        </div>
      </div>
      </div>
    </div>
  </div>
  <div class="full_is_busy" *ngIf="isLoading"  style="min-height: calc(100vh - 188px);">
  <div class="full_is_busy_background"></div>
  <div class="blurred_area"></div>
  <div class="full_is_busy_contents">
    <div class="full_is_busy_title">
      <span class="full_is_busy_title_icon"></span>
      <span class="full_is_busy_title_text">{{'Please wait...' | translate}}</span>
    </div>
    <div class="full_is_busy_message"></div>
  </div>
</div>
</div>
