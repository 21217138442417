import {Component, OnInit} from '@angular/core';
import {Settings} from '../../services/settings';

@Component({
  selector: 'app-shortcuts',
  templateUrl: './shortcuts.component.html'
})

export class ShortcutsComponent implements OnInit {
  constructor(
    public settings: Settings
  ) {
  }

  ngOnInit() {
  }

  closeModal(){
    this.settings.isShortcutsPopupOpened = false;
  }
}
