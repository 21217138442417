import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Settings} from '../settings';
@Injectable({
  providedIn: 'root'
})
export class TranslatorService {

  public defaultLanguage =  this.settings.activeLang;

  private availablelangs = [
    {code: 'en', text: 'English'},
    {code: 'ru', text: 'Russ'}
  ];

  constructor(
    public translate: TranslateService,
    public settings: Settings
  ) {
    if (localStorage.getItem('lang')) {
      this.defaultLanguage = localStorage.getItem('lang');
      this.settings.activeLang = localStorage.getItem('lang');
    }
    if (!translate.getDefaultLang()) {
      translate.setDefaultLang(this.defaultLanguage);
    }

    this.useLanguage(this.defaultLanguage);

  }

  useLanguage(lang: string = null) {
    localStorage.setItem('lang', lang);
    this.defaultLanguage = lang;
    this.settings.activeLang = lang;
    this.translate.use(lang || this.translate.getDefaultLang());
  }

  getAvailableLanguages() {
    return this.availablelangs;
  }

}
