<div class="dashboard-middleContent" style="display: block;">
  <div class="row">
    <div class="col-xs-12">
      <div class="page-header">
        <div class="nav nav-tabs">
          <div class="active">
            <span>{{'Last 48 hours' | translate}}</span>
          </div>
        </div>
      </div>

      <div class="tab-content">
        <div class="col-xs-left">
          <div class="box">
            <div class="box-header">
              <h2>
                <i class="fa fa-angle-right"></i>
                <span class="break">{{'Bet approvals' | translate}}</span>
              </h2>
              <div style="display:none" class="box-icon">
                <a href="#" class="btn-minimize"><i class="fa fa-chevron-up"></i></a>
              </div>
            </div>
            <div class="box-content">
              <div class="filter">
                <div class="filter-header" (click)="openedFilter = !openedFilter" [ngClass]="{'open': openedFilter}">
                  <i class="fa fa-filter"></i> {{'Filter' | translate}}
                  <span class="fa "  [ngClass]="openedFilter ? 'fa-toggle-on' : 'fa-toggle-off'"></span>
                </div>
                <div class="filter-body collapse in" [ngStyle]="{display: openedFilter? 'block' : 'none'}">
                  <div class="selectric-wrapper" [ngClass]="{'selectric-open': OpenedOptions}">
                    <div class="selectric-hide-select"></div>
                    <div class="selectric" (click)="OpenedOptions = !OpenedOptions">
                      <p class="label">{{BetAprovStates[selectedSport] | translate}}</p>
                      <b class="button">▾</b>
                    </div>
                      <div class="selectric-items" tabindex="-1" style="width: 100%">
                      <div class="selectric-scroll">
                        <ul>
                          <ng-template ngFor let-state [ngForOf]="objectKeys(BetAprovStates)">
                            <li data-index="" [ngClass]="{'selected': selectedSport == state}"
                                (click)="selectType(state)">{{BetAprovStates[state] | translate}}
                            </li>
                          </ng-template>

                        </ul>
                      </div>
                    </div>
                    <input class="selectric-input" tabindex="0">
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="reportsRecordsArea">
                <table class="table table-striped table-bordered">
                  <thead>
                  <tr>
                    <th>{{'ID' | translate}}</th>
                    <th>{{'DATE/TIME' | translate}}</th>
                    <th>{{'STAKE/WINNINGS' | translate}}</th>
                    <th>{{'Status/Expire' | translate}}</th>
                  </tr>
                  </thead>
                  <tbody>
                    <ng-template let-item ngFor [ngForOf]="BetAppList">
                      <tr (click)="clickToRow(item)">
                        <td>{{item.Id}}</td>
                        <td>{{item.CreateDate | date: 'MM.dd.yyy HH:mm'}}</td>
                        <td>{{item.ApprovedAmount}}</td>
                        <td>{{BetAprovStates[item.ApprovedType]}}</td>
                      </tr>
                    </ng-template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-right">
          <div class="box offset-bottom0">
            <div class="box-header">
              <h2>
                {{'Summary' | translate}}
              </h2>
              <div style="display:none" class="box-icon">
                <a href="#" class="btn-minimize"><i class="fa fa-chevron-up"></i></a>
                <a href="#" class="btn-close"><i class="fa fa-times"></i></a>
              </div>
            </div>
            <div class="bet-approvals" *ngIf="BetInfo">
              <div class="box-content" *ngIf="BetInfo['Id']">
                <div class="summary padd-h0">

                  <div class="betslip-box">
                    <div id="betslip-box" class="betslip-box">

                      <div class="betslip-box">
                        <div class="betslip-summary">
                          <div class="betslip-row no-padd">
                            <div class="col-xs-6 title">{{'Selections' | translate}}:</div>
                            <div class="col-xs-3 title">{{'Odds' | translate}}</div>
                            <div class="col-xs-3 title">{{'Stake' | translate}}</div>
                          </div>
                        </div>
                        <div class="modal_window betslip_scrollbar_container accumulator-odds-enabled">
                          <div class="betslip-odds">
                            <div id="betslipSelectionCollapse" class="collapse in">
                              <ng-template let-selection ngFor [ngForOf]="BetInfo.Selections">

                                <div class="betslip-market-selection">
                                  <div class="betslip-row">
                                    <div class="id-market">
                                      <div class="country">
                                        <span>{{selection.Index}}. {{selection.Region}} - {{selection.Competition}} - </span>
                                        <span>{{selection.af | date: 'dd/MM HH:mm'}}</span>
                                      </div>
                                      <div class="betslip-txt" title="{{selection.EventId}} {{selection.HomeName}} v {{selection.AwayName}}">{{selection.EventId}} {{selection.HomeName}} v {{selection.AwayName}} </div>
                                    </div>
                                  </div>

                                  <div class="betslip-row">
                                    <div class="bet-type">
                                      <div class="betslip-txt-number betslip_selection_second_line_banker" *ngIf="selection.IsBanker"> B</div>
                                      <div class="betslip-stake betslip_selection_second_line_status"></div>
                                    </div>
                                  </div>
                                  <div class="betslip-row">
                                    <div class="col-xs-1 no-padd">
                                      <div class="betslip-txt"> </div>
                                      <div class="betslip-txt G" [ngClass]="selection.GroupId ? selection.GroupId : ''" *ngIf="selection.GroupId">G</div>
                                    </div>
                                    <div class="col-xs-5 no-padd">
                                      <div class="betslip-txt padded" title="{{selection.MarketName}}: {{selection.SelectionName}}">{{selection.MarketName}}: {{selection.SelectionName}}</div>
                                    </div>
                                    <div class="col-xs-3 no-padd text-center">
                                      <div class="betslip-txt">{{selection.Odds}}</div>
                                      <div class="betslip-txt" style="display: none;"></div>
                                      <div style="display: none;" class="market-element-ns pull-right" data-bind="visible: NumberOfSelections > 1">
                                        <div class="txt">1</div>
                                      </div>
                                    </div>
                                    <div class="col-xs-3 no-padd text-center"></div>
                                  </div>
                                  <div class="betslip-row" style="display: none;">
                                    <div class="bet-info">

                                      <div class="col-xs-6 no-padd">
                                        <div class="currency-number" style="display: none;"></div>
                                      </div>
                                      <div class="col-xs-3 no-padd text-center">
                                        <div class="betslip-txt">1.91</div>
                                      </div>
                                      <div class="col-xs-3 no-padd text-center">
                                        <div class="betslip-stake" style="display: none;"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-template>

                            </div>
                            <ng-template let-lineGroup ngFor [ngForOf]="BetInfo.SystemSelections">
                              <div class="betslip-accumulator-header betslip-header">
                                <div class="betslip-row">
                                  <a class="btn btn-betslip-plus" data-toggle="collapse" (click)="lineGroup.open = !lineGroup.open" >
                                    <i class="fa fa-plus"></i>
                                  </a>
                                  <div class="betslip-txt">{{lineGroup.VariantName}}</div>
                                </div>
                              </div>
                              <div class="collapse" [ngClass]="{in:lineGroup.open}">
                                <div class="betslip-accumulator-body betslip-market-selection">
                                  <div class="betslip-row">
                                    <div class="bet-info systems">
                                      <div class="betslip-checkbox">
                                        <input type="checkbox" checked="">
                                      </div>
                                      <div class="betslip-txt" >{{lineGroup.CountOfLines}} LINES <span class="betslip_systems_multiplier_button">X</span></div>
                                      <div class="betslip-txt system-betlines">{{lineGroup.TotalOdds}}</div>
                                      <div class="betslip-txt" >€{{lineGroup.TotalStake}}</div>
                                      <!--/ko-->
                                    </div>
                                  </div>
                                  <!-- ko if:IsExpanded--><!--/ko-->
                                  <!--ko foreach: Items() -->
                                  <ng-template let-line ngFor [ngForOf]="objectKeys(lineGroup.SelectionStake)">
                                    <div class="betslip-row systems">
                                      <div class="bet-info systems">
                                        <div class="betslip-checkbox">
                                          <input type="checkbox">
                                        </div>
                                        <div class="betslip-txt-number system-betlines" title="{{line}}">{{line}}</div>

                                        <div class="betslip-txt system-betlines"></div>
                                        <div class="betslip-txt">€{{lineGroup.SelectionStake[line]}}</div>
                                      </div>
                                    </div>
                                  </ng-template>

                                </div>
                              </div>
                            </ng-template>
                          </div>
                        </div>

                        <div class="betslip-summary betslip-results">
                          <div class="betslip-row">
                            <div class="betslip-txt" style="display: block;">{{'BET PLACED BY' | translate}}:</div>
                            <div class="betslip-txt-results">shopadmin1260</div>
                          </div>
                          <div class="betslip-row">
                            <div class="betslip-txt" style="float:left;">{{'TOTAL STAKE' | translate}}</div>
                            <div class="betslip-txt-results">€{{BetInfo.BetAmount}}</div>
                          </div>

                          <div class="betslip-row">
                            <div class="betslip-txt">
                              <span class="pull-left">{{'TOTAL STAKE' | translate}}</span>

                              <span class="ex-tax">(ex. tax):</span>
                            </div>
                            <div class="betslip-txt-results" >€{{BetInfo.BetAmount}}</div>

                          </div>
                        </div>
                        <div class="betslip-summary betslip-results">
                          <div class="betslip-row">
                            <div class="betslip-txt">BETS:</div>
                            <div class="betslip-txt-results">{{BetInfo.BetsCount}}</div>
                          </div>
                          <div class="betslip-row">
                            <div class="betslip-txt">MAX. PAYOUT</div>
                            <div class="betslip-txt-results">€{{BetInfo.MaxPayout}}</div>
                          </div>
                          <div class="">
                            <div class="error_message error-effect-jelly" style="display: none;">
                            <div class="error_message_icon_close clickable">
                              <i class="fa fa-times"></i>
                            </div>
                            <div class="error_message_text clickable">
                              <span class="highlighted">{{'Error' | translate}}:</span>
                              <i class="fa fa-exclamation"></i>
                            </div>
                            <div class="error-success-progress"></div>
                          </div>
                          </div>
                          <div class="betslip-row" style="display: none;">
                            <div class="clickable"> </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div>
                    </div>
                  </div>


                </div>
                <div class="clearboth"></div>
                <div class="betslip-summary btns">
                  <!--<div class="betslip-row insert-pin">
                    <div class="cancel_pay_request" data-bind="visible: IsCancellationRequest" style="display: none;">
                      <div class="betslip-txt" data-bind="text: 'Insert Betslip Pin'.translate()">Insert Betslip Pin</div>
                      <div class="pin_error">
                        <div class="error_message error-effect-jelly" data-bind="visible: IsVisible, css: MessageTypeClass" style="display: none;">
                          <div class="error_message_icon_close clickable" data-bind="click: CloseButtonClick, clickBubble: false">
                            <i class="fa fa-times"></i>
                          </div>
                          <div class="error_message_text clickable" data-bind="click: CloseButtonClick, clickBubble: false">
                            &lt;!&ndash; ko if:Title().length > 0 &ndash;&gt;
                            <span class="highlighted" data-bind="html:Title">Error:</span>
                            <i class="fa fa-exclamation"></i>
                          </div>
                          <div class="error-success-progress"></div>
                        </div>
                        &lt;!&ndash; /ko &ndash;&gt;
                      </div>
                      <div class="custom-input betslip-input">
                        <div class="input-group col-xs-input">
                          <div class="button close small" data-bind="click: cmdClearInputPin, clickBubble: false, visible: InputPin().length > 0" style="display: none;"> </div>
                          <input type="text" class="form-control second-color input-md" data-bind="value: InputPin, valueUpdate: 'input', executeOnEnter: userCancelBetslip, attr: { 'placeholder': 'Betslip Pin'.translate() }" placeholder="Betslip Pin">
                        </div>
                      </div>
                    </div>
                  </div>-->
                  <div class="footer-buttons text-center" *ngIf="selectedRow">

                    <!-- Chasout -->
                    <div class="btn btn-shop-primary-color btn-betslip cashout" *ngIf="selectedRow.ApprovedType === 1 && selectedRow.State === 0" (click)="PlaceApprovedBet()">{{'Place Bet' | translate}}</div>
                  </div>
                </div>


                <!-- ko template: {name: 'full-is-busy', data: IsBusy}--><div class="full_is_busy" style="display: none;">
                <div class="full_is_busy_background"></div>
                <div class="blurred_area"></div>
                <div class="full_is_busy_contents">
                  <div class="full_is_busy_title">
                    <span class="full_is_busy_title_icon"></span>
                    <span class="full_is_busy_title_text">{{'Please wait...' | translate}}</span>
                  </div>
                  <div class="full_is_busy_message">{{'Loading betslip' | translate}}</div>
                </div>
              </div>
                <!-- /ko-->
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>
<app-print  id="asdf"  [betData]="SuccessBetData" *ngIf="SuccessBetData" style="display: none"></app-print>
