import {Component, OnInit} from '@angular/core';
import {Settings} from '../../../../core/services/settings';
import {RequestsService} from '../../../../core/services/requests/requests.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

declare var $: any;

@Component({
  selector: 'personal-info',
  templateUrl: './PersonalInfo.component.html'
})

export class PersonalInfoComponent implements OnInit {
  public valForm: FormGroup;
  public isSuccess = false;
  public countryLIst = [];

  constructor(
    private fb: FormBuilder,
    public settings: Settings,
    private http: RequestsService
  ) {


    this.valForm = this.fb.group({
      'Firstname': [null, Validators.required],
      'Lastname': ['', Validators.required],
      'Gender': [null, Validators.required],
      'Email': [null, Validators.compose([Validators.required])],
      'BirthDate': [null, Validators.required],
      'PhoneNumber': [null, Validators.required],
      'ResidenceCountryId': [null, Validators.required],
      'Address1': [null, Validators.required],
      'Address2': [null, Validators.required],
      'City': [null, Validators.required],
      'Country': [null, Validators.required],
      'CounrtyName': [null],
      'PostCode': [null, Validators.required]
    });
    this.http.httpGet(this.settings.apiUrls.GetPersonalSettings)
      .subscribe((data: any) => {
        if (!data.HasError) {
          $('#datetimeFrom').datetimepicker({format: 'dddd, MMM Do YYYY, HH:mm', defaultDate: data.Data.BirthDate}).on('dp.change', (e) => {
          });
          this.valForm.patchValue(
            {
              'Firstname': String(data.Data.Firstname),
              'Lastname': String(data.Data.Lastname),
              'Gender': String(data.Data.Gender),
              'Email': String(data.Data.Email),
              'BirthDate': String(data.Data.BirthDate),
              'PhoneNumber': String(data.Data.PhoneNumber),
              'ResidenceCountryId': String(data.Data.ResidenceCountryId),
              'Address1': String(data.Data.Address1),
              'Address2': String(data.Data.Address2),
              'City': String(data.Data.City),
              'Country': String(data.Data.Country),
              'CounrtyName': String(data.Data.CounrtyName),
              'PostCode': String(data.Data.PostCode)
            }
          );
          $('select').selectric();
        }
      });

    this.http.httpGet(this.settings.apiUrls.GetCountries)
      .subscribe((data: any) => {
        if (!data.HasError) {
          this.countryLIst = data.Data;
          setTimeout(() => {
            $('select').selectric();
          }, 200);
        }
      });
  }


  submitForm(ev, value: any) {
    ev.preventDefault();
    for (const c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      const obj = JSON.parse(JSON.stringify(value));
      this.isSuccess = false;
      this.http.httpPost(this.settings.apiUrls.UpdatePersonalOptions, obj)
        .subscribe((e: any) => {
          if (!e.HasError) {
            this.isSuccess = true;
          }

        });
    }
  }

  ngOnInit() {

    $('#ResidenceCountryId').on('change', (e) => {
      this.valForm.patchValue({
        ResidenceCountryId: $('#ResidenceCountryId').val()
      });
    });
    $('#Gender').on('change', (e) => {
      this.valForm.patchValue({
        Gender: $('#Gender').val()
      });
    });
  }
}
