<div class="col-xs-middle marketlist-minheight">
  <div class="col-middle">
    <div class="square-section">
      <div class="row">
        <div class="col-xs-12">
          <div class="bet bet-filter-height">
            <div class="bet-selection">
              <ng-template let-group ngFor [ngForOf]="sportService.GroupList">
                <div class="col-xs-3 offset-bottom" (click)="selectGroup(group)">
                  <div class="bet-tab " [ngClass]="{active: selectedGroupId == group.Id}">
                    <div class="title-bet-type" title="{{group.Name}}">{{group.Name}} </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="market-element" id="ssdferx" style="min-height: calc(100vh - 288px);">

      <table class="table table-striped market-element-table">
        <thead>
        <tr *ngIf="selectedGroupId">
          <ng-template let-market ngFor [ngForOf]="markets  | order: 'Id'">
            <th [colSpan]="market.Selections.length">
              <div class="market-element-title" title="{{market.Name}}">{{market.Name}}
              </div>
            </th>
            <th class="market-element-event-separate"></th>
          </ng-template>

          <th  [colSpan]="emptyColspan">
            <div class="market-element-title" title=""></div>
          </th>
          <th></th>
        </tr>
        <tr>
          <ng-template let-market ngFor [ngForOf]="markets  | order: 'Id'">
            <ng-template let-selection ngFor [ngForOf]="market.Selections | order: 'Order'">
              <th title="1">{{selection.Name}}</th>
            </ng-template>
            <th class="market-element-event-separate"></th>
          </ng-template>
          <th *ngFor="let empty of arrByNumber(emptyColspan)"></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <!-- ko foreach: SportMarkets -->
        <ng-template ngFor let-match [ngForOf]="sportService.MatchesList  | order: 'o' : 'desc'" [ngForTrackBy]="trackByMethod">
          <tr class="match-hover-table" (mouseenter) ="match.hover = true" (mouseleave) ="match.hover = false"
              [ngClass]="{active: match.hover, 'row-live txt-live': match.o, 'row-live-soon txt-live-soo': match.c && (match.i != 'Interrupted' || match.i != 'Suspended') , ' row-susp text-suspended': match.i == 'Interrupted' || match.i == 'Suspended'}">
            <ng-template let-market ngFor [ngForOf]="markets  | order: 'Id'" [ngForTrackBy]="trackByMethod">
              <ng-template let-selection ngFor [ngForOf]="market.Selections | order: 'Order'" let-selind="index"  [ngForTrackBy]="trackByMethod">
                    <td class="event-item" [match]="match"
                        [marketId]="market.Id"
                        [selectionId]="selection.Id"
                        [c]="selection.c"
                        [index]="selind" width="50"
                        [grSel]="selection"
                        (click)="addToBetSlip(match.Id, market.Id, market.Name, market.h, selrc.selections, selrc)" #selrc></td>
              </ng-template>
              <td class="market-element-event-separate"></td>
            </ng-template>
            <td width="50"  *ngFor="let empty of arrByNumber(emptyColspan)"></td>
                  <td width="50">
              <div class="market-element-other-events" [routerLink]="['/game', match.Id]">
                <span class="txt plus">+</span>
                <span class="txt">{{match.y}}</span>
              </div>
            </td>
          </tr>
        </ng-template>
        </tbody>
      </table>

      <div class="full_is_busy" style="min-height: calc(100vh - 288px);" *ngIf="sportService.isActiveEventsLoader">
        <div class="full_is_busy_background"></div>
        <div class="blurred_area"></div>
        <div class="full_is_busy_contents">
          <div class="full_is_busy_title">
            <span class="full_is_busy_title_icon"></span>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>


<div class="col-xs-middle accumulated-amount">
  <div class="row">
    <div class="col-xs-3 col-xs-offset-9" style="width: 287px; margin-left: calc(100% - 300px);">
      <div class="title" style="display: block;">
        <div class="txt">
          {{'Amount of Last Slips' | translate}} ({{settings.betsInfo.BetsCount}}):
        </div>
        <div class="amount second-color" style="text-align: right;">€{{settings.betsInfo.BetsAmount}}
        </div>
      </div>
      <div class="side-right">
        <a class="btn btn-shop-second-color btn-auto" title="{{'Reset' | translate}}" (click)="resetBetsInfo()">{{'Reset' | translate}}</a>
      </div>
    </div>
  </div>
</div>
