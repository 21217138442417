<div class="dashboard-middleContent">
  <div class="row">
    <div class="col-xs-12">
      <!-- Page header - tabs -->
      <div class="page-header">
        <div class="nav-tabs">

          <div [routerLink]="'/management/settings/general'"  routerLinkActive="active">
            <span>{{'General Settings' | translate}}</span>
          </div>

          <div [routerLink]="'/management/settings/sport'"  routerLinkActive="active">
            <span>{{'Sport Options' | translate}}</span>
          </div>

          <div  [routerLink]="'/management/settings/devices'"  routerLinkActive="active">
            <span >{{'Devices' | translate}}</span>
          </div>

          <div [routerLink]="'/management/settings/personal-info'"  routerLinkActive="active">
            <span>{{'Personal Information' | translate}}</span>
          </div>

          <div [routerLink]="'/management/settings/password'"  routerLinkActive="active">
            <span >{{'Password' | translate}}</span>
          </div>

        </div>
      </div>

      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="announcement-msg">
    <!-- ko template: { name: 'error-message', data: ErrorMessage } -->
    <div class="error_message error-effect-jelly" style="display: none;">
    <div class="error_message_icon_close clickable" data-bind="click: CloseButtonClick, clickBubble: false">
      <i class="fa fa-times"></i>
    </div>
    <div class="error_message_text clickable" data-bind="click: CloseButtonClick, clickBubble: false">
      <i class="fa fa-exclamation"></i>
    </div>
    <div class="error-success-progress"></div>
  </div>
    <!-- /ko -->
    <!-- ko template: { name: 'full-is-busy', data: IsBusy } -->
    <div class="full_is_busy" data-bind="visible: IsVisible" style="display: none;">
    <div class="full_is_busy_background"></div>
    <div class="blurred_area"></div>
    <div class="full_is_busy_contents">
      <div class="full_is_busy_title">
        <span class="full_is_busy_title_icon"></span>
        <span class="full_is_busy_title_text" data-bind="text:Title">{{'Please wait...' | translate}}</span>
      </div>
      <div class="full_is_busy_message" data-bind="html:Message"></div>
    </div>
  </div>
  </div>
</div>
