import {Injectable} from '@angular/core';
import {Settings} from '../settings';
import {RequestsService} from './requests.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class AccountReqService {
  constructor(
    private settings: Settings,
    private http: RequestsService,

  ) {

  }

  public logAut() {
    return this.http.httpPost(`${this.settings.platformUrl}api/${this.settings.partnerId}/account/CashierLogout`, {})
      .subscribe((e: any) => {
        if (!e.HasError) {
          localStorage.setItem('cashirToken', '');
          localStorage.setItem('timeZone', '');
          localStorage.setItem('cashirId', '');
          localStorage.setItem('cashierData', '');
          location.reload();
        }
      });
  }

  public fullLogout() {
    localStorage.clear();
    setTimeout(() => {
      location.reload();
    }, 500);
  }



}
