import {Component, NgZone, OnInit} from '@angular/core';
import {Settings} from '../../../core/services/settings';
import {RequestsService} from '../../../core/services/requests/requests.service';
import * as consts from '../../../core/enums/consts';
import * as _ from 'lodash';

@Component({
  selector: 'bet-approval',
  templateUrl: './bet-approvals.component.html'
})


export class BetApprovalsComponent implements OnInit {
  public BetInfo: any;
  public BetAppList = [];
  public openedFilter = true;
  public BetAprovStates = consts.BetAprovState;
  public selectedSport = '-1';
  public OpenedOptions: boolean;
  public BetCodesList = [];
  public selectedRow: any;
  public loading: boolean;
  public BetInPending: boolean;
  public SuccessBetData: any;
  public objectKeys = Object.keys;

  constructor(
    private settings: Settings,
    private http: RequestsService,
    private zone: NgZone
  ) {
    this.selectType('-1');

  }

  ngOnInit() {
    this.settings.sportConn.hubProxy.on('approvalChanged', (e: any) => {
      _.find(this.BetAppList, (item) => {

      });
    });

    this.settings.sportConn.hubProxy.on('PlaceBetResult', (e: any) => {
      setTimeout(() => {
        this.BetInPending = false;
      }, 500);
      if (!e.HasError) {
        this.zone.run(() => {
          this.selectedRow['State'] = 1;
          this.SuccessBetData = e.Data;
        });
      }
    });
  }

  public selectType(sport) {
    this.loading = true;
    this.selectedSport = sport;
    this.OpenedOptions = false;
    this.http.httpGet(this.settings.apiUrls.Sport.GetBetApprovals + sport)
      .subscribe((data: any) => {
        if (!data.HasError) {
          this.BetAppList = data.Data;
          this.loading = false;
        }
      });
  }

  public clickToRow(approvalItem) {
    this.http.httpGet(`${this.settings.apiUrls.settings.GetBetApprovalBetSleep}approvalId=${approvalItem.Id}&languageId=${this.settings.activeLang}`)
      .subscribe((data: any) => {
        if (!data.HasError) {
          this.selectedRow = approvalItem;
          this.BetInfo = data.Data;
          this.SuccessBetData = null;
          this.BetInfo['SystemSelections'].forEach((item, ind) => {
            item.SelectionStake = JSON.parse(item.SelectionStake);
          });
          console.log(data);
        }
      });
  }


  printTable() {
    const divToPrint = document.getElementById('printTable');
    const newWin = window.open('', '_blank', 'top=0,left=0,height=100vh,width=100vw');
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }

  PlaceApprovedBet() {
    this.settings.sportConn.hubProxy.invoke('PlaceApprovedBet', {
      languageId:  this.settings.activeLang,
      betId: this.BetInfo['Id'],
      cashierId: localStorage.getItem('cashirId')
    });
  }

  asdfg(obj) {
    obj.SelectionStake = JSON.parse(obj.SelectionStake);
    return this.objectKeys(obj.SelectionStake);
  }
}

