<div class="tab-content">
  <div class="row tab-pane active">
    <div>
      <div class="col-xs-12">
        <div class="box">
          <div class="box-header">
            <h2>
              <i class="fa fa-thumbs-up"></i>
              <span class="break">{{'Betting History' | translate}}</span>
            </h2>
          </div>
          <div class="box-content">
            <div class="filter">
              <div class="filter-header" (click)="openedFilter = !openedFilter" [ngClass]="{'open': openedFilter}">
                <i class="fa fa-filter"></i> {{'Filter' | translate}}
                <span class="fa "  [ngClass]="openedFilter ? 'fa-toggle-on' : 'fa-toggle-off'"></span>
              </div>
              <div class="filter-body collapse in" [ngStyle]="{display: openedFilter? 'block' : 'none'}">
                <select tabindex="0" style="" id="select2" [(ngModel)]="FilterObj.isLiveBet">
                  <option value="">{{'All markets' | translate}}</option>
                  <option value="1">{{'LIVE Markets' | translate}}</option>
                  <option value="0">{{'Non-live markets' | translate}}</option>
                </select>
                <select tabindex="0" style="" id="select3"  [(ngModel)]="FilterObj.betState">
                  <option value="">{{'All betslips' | translate}} </option>
                  <option *ngFor="let state of BetStatesList" [value]="state.Id">
                    {{state.Name | translate}}
                  </option>
                </select>
                <div class="custom-input primary-color">
                  <div class="input-group date" id="datetimeBettingHistoryFrom">
                    <input type="text" class="form-control">
                    <span class="input-group-addon">
                        <span class="fa fa-calendar"></span>
                    </span>
                  </div>
                </div>
                <div class="custom-input primary-color">
                  <div class="input-group date" id="datetimeBettingHistoryTo">
                    <input type="text" class="form-control">
                    <span class="input-group-addon">
                        <span class="fa fa-calendar"></span>
                    </span>
                  </div>
                </div>
                <div class="amount-reset">
                  <a  class="btn btn-shop-primary-color" title="Filter" (click)="getBets()">{{'Filter' | translate}}</a>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
            <div class="reportsRecordsArea">
              <table class="table table-striped table-bordered">
                <thead>
                <tr>
                  <th>{{'Date placed /Settled' | translate}}</th>
                  <th>{{'S/N' | translate}}</th>
                  <th>{{'Stake' | translate}}</th>
                  <th>{{'LINES' | translate}}</th>
                  <th>{{'POSSIBLE PAYOUT' | translate}}</th>
                  <th>{{'Void / Cancelled' | translate}}</th>
                  <th>{{'Winnings' | translate}}</th>
                  <th>{{'Paid' | translate}}</th>
                </tr>
                </thead>
                <tbody>
                <ng-template let-bet ngFor [ngForOf]="Data">
                  <tr (click)="SelectedRowId = bet.BetId" [ngClass]="{'selected': SelectedRowId == bet.BetId}">
                    <td>
                      <span>{{_core.DateFromNow(bet.CreateDate)}}</span>
                      <br>
                      <span>{{_core.DateFromNow(bet.DateSettled)}}</span>
                      <!-- /ko -->
                    </td>
                    <td>{{bet.BarCode}}</td>
                    <td style="text-align: right;">€{{bet.Stake}}</td>
                    <td >{{bet.Lines}}</td>
                    <td style="text-align: right;">€{{bet.PossibleMaxPayout}}</td>
                    <td>{{bet.Void}}</td>
                    <td style="text-align: right;">€{{bet.Winnings ? bet.Winnings : 0.00}}</td>
                    <td style="text-align: right;">€{{bet.Paid || 0}}</td>
                    <!--/ko-->
                  </tr>
                </ng-template>
                </tbody>

              </table>
            </div>

            <div class="clearfix"></div>

            <div class="footer-buttons-report">
              <div class="amount-reset">
                <div class="btn btn-shop-primary-color btn-137width" (click)="getBetSlip()">
                  {{'Display Bet Slip' | translate}}
                </div>
              </div>
            </div>
            <div class="clearfix"></div>

          </div>
        </div>
        <div class="full_is_busy" *ngIf="isLoading">
          <div class="full_is_busy_background"></div>
          <div class="blurred_area"></div>
          <div class="full_is_busy_contents">
            <div class="full_is_busy_title">
              <span class="full_is_busy_title_icon"></span>
              <span class="full_is_busy_title_text">{{'Please wait...' | translate}}</span>
            </div>
            <div class="full_is_busy_message"></div>
          </div>
        </div>
        <div class="error_message error-effect-jelly" style="display: none;">
          <div class="error_message_icon_close clickable">
            <i class="fa fa-times"></i>
          </div>
          <div class="error_message_text clickable">
          </div>
          <div class="error-success-progress"></div>
        </div>
      </div>
    </div>
  </div>
  <popup-betslip [betslipData]="BetInfo" *ngIf="BetInfo" (closeEvent)="RemoveBetslipData()"></popup-betslip>
</div>
