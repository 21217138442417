import {ChangeDetectionStrategy, Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {BetSlipService} from '../../../../core/services/bet-slip/bet-slip.service';
import {MarketsModel} from '../../../../core/models/match.model';

@Component({
  selector: '.event-item',
 // changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <span class="market-element-event active {{index == 0 ? 'odds-selections-' + _selection.h: ''}}"
          [ngClass]="{selected: selections.selected && _selection.Odd != 'N/A' && selections.e ==0,  sbv: grSel.Name == 'SBV',
           disabled : (_selection.Odd == 'N/A' || selections.e !=0) ||  !market || (market && !market.Show),
             'caret-down': _selection.Down,  'caret-up': _selection.Up}"
          *ngIf="_selection">
      {{(grSel.Name == 'SBV' || selections.e == 0) && market && market.Show ? _selection.Odd : 'N/A'}}
      <i id="caret-sport" class="fa active" [ngClass]="{'fa-caret-down': _selection.Down,  'fa-caret-up': _selection.Up}"></i>
    </span>
  `
})

export class EventItemComponent implements OnInit, OnDestroy {
  @Input() index: any;
  @Input() selectionId?: any;
  @Input() c?: any;

  @Input() match?: any;
  @Input() marketId?: any;
  @Input() grSel: any;
  public market: MarketsModel;

  public AddToBSlipEvent: any;
  public RemoveSelection: any;

  public selections: any;
  public minSelections = 1;

  get _selection() {
    this.selections = this.getSelection(this.match, this.marketId, this.selectionId);
    return this.selections;
  }

  constructor(
    public betSlipService: BetSlipService,
    public zone: NgZone
  ) {

  }

  ngOnInit() {
    this.selections = this.getSelection(this.match, this.marketId, this.selectionId);
    /*   this.AddToBSlipEvent = this.betSlipService.AddToBSlipEvent.subscribe((e) => {
         if (this.match.Id == e.matchId && this.marketId == e.marketId && this.selections.OddId == e.selectionId) {
           this.selections.selected = true;
         }
       });
       this.RemoveSelection = this.betSlipService.RemoveSelection.subscribe((e) => {
         if (this.match.Id == e.matchId && this.marketId == e.marketId && this.selections.OddId == e.selectionId) {
           this.selections.selected = false;
         }

       });*/
  }


  getSelection(match, marketId, selectionId) {
    /*if (marketId === 1043111) {

      this.market = _.find(match.Markets, {f: marketId});
      if (!this.market || !this.market.Show) {
        if (selectionId !== 0) {
          return {Odd: 'N/A'};
        } else {
          return {Odd: '-'};
        }
      }

      const ord = _.orderBy(this.market.j, 'Order');
      if (ord) {
        if (ord[0].e !== 0) {
          return {Odd: '-'};
        }
        if (selectionId !== 0) {
          this.selections = ord[this.index > 0 ? 1 : 0];
        } else {
          return {Odd: ord[0].Base};
        }
        if (!this.selections) {
          return {Odd: '-'};
        }
        this.selections.h = this.market.h;
        this.minSelections = this.market.h;
        return this.selections;
      }
    }
    if (marketId === 1043) {
      this.market = _.find(match.Markets, {f: marketId});
      if (!this.market || !this.market.Show) {
        if (selectionId !== 0) {
          return {Odd: 'N/A'};
        } else {
          return {Odd: '2.5'};
        }
      }
      this.minSelections = this.market.h;
      // this.selections.MinSelection = market.MinSelection;
      if (selectionId === 0) {
        return {Odd: '2.5'};
      } else if (this.index == 0) {
        const sel = _.find(this.market.j, {Base: '2.5', Order: 1});
        if (sel) {
          return sel;
        } else {
          return {Odd: 'N/A'};
        }
      } else if (this.index !== 0) {
        const sel = _.find(this.market.j, {Base: '2.5', Order: 2});
        if (sel || (sel && sel.Suspended)) {
          return sel;
        } else {
          return {Odd: 'N/A'};
        }
      }

    }
    if (marketId === 982) {
      this.market = _.find(match.Markets, {f: marketId});
      if (!this.market || !this.market.Show) {
        if (selectionId !== 0) {
          return {Odd: 'N/A'};
        } else {
          return {Odd: '160.5'};
        }
      }
      this.minSelections = this.market.h;
      // this.selections.MinSelection = market.MinSelection;
      if (selectionId === 0) {
        return {Odd: '160.5'};
      } else if (this.index == 0) {
        const sel = _.find(this.market.j, {Base: '160.5', Order: 1});
        if (sel) {
          return sel;
        } else {
          return {Odd: 'N/A'};
        }
      } else if (this.index !== 0) {
        const sel = _.find(this.market.j, {Base: '160.5', Order: 2});
        if (sel || (sel && sel.Suspended)) {
          return sel;
        } else {
          return {Odd: 'N/A'};
        }
      }

    } else
      if (marketId === 105) {
      this.market = _.find(match.Markets, {f: marketId});
      if (!this.market || !this.market.Show) {
        if (selectionId !== 0) {
          return {Odd: 'N/A'};
        } else {
          return {Odd: '0.5'};
        }
      }
      this.minSelections = this.market.h;
      // this.selections.MinSelection = market.MinSelection;
      if (selectionId === 0) {
        return {Odd: '0.5'};
      } else if (this.index == 0) {
        const sel = _.find(this.market.j, {Name: 'O 0.5'});
        if (sel) {
          return sel;
        } else {
          return {Odd: 'N/A'};
        }
      } else if (this.index !== 0) {
        const sel = _.find(this.market.j, {Name: 'U 0.5'});
        if (sel || (sel && sel.Suspended)) {
          return sel;
        } else {
          return {Odd: 'N/A'};
        }
      }

    } else {*/
      this.market = _.find(match.Markets, {f: marketId});
      if (!this.market || !this.market.Show) {
        return {Odd: 'N/A'};
      } else {
        this.selections = _.find(this.market.j, {Id: selectionId});
        if (this.grSel.Name === 'SBV' && this.market.j){
          return {Odd: this.market.j[0].Base};
        }

        if (!this.selections || (this.selections && this.selections.Suspended)) {
          return {Odd: 'N/A'};
        } else {
          this.selections.h = this.market.h;
          this.minSelections = this.market.h;
          return this.selections;
        }
      }
   /* }*/

  }

  ngOnDestroy() {
    /*    console.log(1);
        this.AddToBSlipEvent.unsubscribe();
        this.RemoveSelection.unsubscribe();*/
  }
}
