import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {SportService} from '../../../core/services/sport/sport.service';
import {BetSlipService} from '../../../core/services/bet-slip/bet-slip.service';
import {ActivatedRoute} from '@angular/router';
import {Settings} from '../../../core/services/settings';
import * as _ from 'lodash';

@Component({
  selector: 'app-all-events',
  templateUrl: './allEvents.component.html'
})
export class AllEventsComponent implements OnInit {
  private sub: any;
  public GroupList = this.sportService.GroupList;
  public selectedGroupId = 0;
  public markets = [];
  public emptyColspan = 15;
  public sel = {name: 111111111111};
  @ViewChild('selrc') selrc: ElementRef;
  public market: any;

  constructor(
    public sportService: SportService,
    private route: ActivatedRoute,
    public betSlipService: BetSlipService,
    public settings: Settings,
    public zone: NgZone
  ) {

    this.sportService.groupSuccessEvent.subscribe((e: any) => {
      if (e) {
        this.GroupList = e;

        if (this.GroupList.length) {
          this.selectGroup(this.GroupList[0]);
        } else {
          this.selectGroup({
            Id: -111111,
            Markets: [],
          });
        }
      }
    });
    this.sportService.groupGetEvent.subscribe((e) => {
      this.GroupList = e;
      if (this.GroupList.length) {
        this.selectGroup(this.GroupList[0]);
      } else {
        this.selectGroup({
          Id: -111111,
          Markets: [],
        });
      }
    });
  }


  selectGroup(group) {
    // this.zone.run(() => {
    this.emptyColspan = 15;
    this.selectedGroupId = group.Id;
    this.markets = group.Markets;
    if (this.markets.length) {
      this.markets.forEach((item, ind) => {
        if (item && item.Selections.length) {
          this.emptyColspan -= item.Selections.length;
        }
      });
    }
    // });
  }

  ngOnInit() {
    if (this.sportService.GroupList.length) {
      this.selectGroup(this.sportService.GroupList[0]);
    }


    this.sub = this.route.params.subscribe(params => {
      if (params.id) {
        if (this.sportService.activeSportId !== params.id) {
          this.sportService.PageIndex = 1;
          localStorage.setItem('PageIndex', '1');
        }
        if (this.sportService.activeSportId != params.id) {
          this.sportService.activeSportId = params.id;
          localStorage.setItem('activeSportId', params.id);
          this.zone.run(() => {
            this.sportService.getSportsInfo();
          });
        }
      }
    });
  }

  arrByNumber(number) {
    return new Array(number);
  }

  getSelection(match, marketId, selectionId) {
    const market = _.find(match.Markets, {f: marketId});
    if (!market) {
      return {Odd: 'N/A'};
    } else {
      const selection = _.find(market.j, {Id: selectionId});

      if (!selection) {
        return {Odd: 'N/A'};
      } else {
        selection.h = market.h;
        return selection;
      }
    }
  }

  addToBetSlip(matchId, marketId, marketName, minSelectionCount, selection, selrc) {
    if (!selection.Id || selection.e !== 0) {
      return false;
    }
    if (!selrc.market || (selrc.market && !selrc.market.Show)) {
      return false;
    }
    const match = _.find(this.sportService.MatchesList, {Id: matchId});
    const data = {
      matchId,
      isLive: match.o,
      home: match.j,
      away: match.a,
      eventName: match.eventName,
      category: match.f,
      categoryId: match.d,
      tournament: match.Competition,
      tournamentId: match.t,
      marketId,
      marketName,
      minSelectionCount: selrc.minSelections,
      selectionId: selrc.selections.c,
      selection: selrc.selections,
    };
    this.betSlipService.addToBetSlip(data);

  }

  resetBetsInfo() {
    this.settings.betsInfo.BetsAmount = 0;
    this.settings.betsInfo.BetsCount = 0;
    localStorage.removeItem('betsInfo');
  }

  trackByMethod(index: number, el: any): number {
    if (!el) {
      return null;
    }
    return el.Id;
  }
}
