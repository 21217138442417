import {Component} from '@angular/core';
import {Settings} from '../core/services/settings';

@Component({
  selector: 'app-route-component',
  templateUrl: './route.component.html'
})

export class RouteComponent {
  constructor(
    public settings: Settings
  ) {

  }

}
