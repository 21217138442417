import {LoginComponent} from './pages/login/login.component';
import {AllEventsComponent} from './sports/all/allEvents.component';
import {GameComponent} from './sports/game/game.component';
import {AppSportsComponent} from './sports/app-sports.component';
import {ManagementComponent} from './management/management.component';
import {BetApprovalsComponent} from './management/bet-approvals/bet-approvals.component';
import {SettingsComponent} from './management/settings/settings.component';
import {GeneralComponent} from './management/settings/general/general.component';
import {SportComponent} from './management/settings/sport/sport.component';
import {LoginGuardService} from '../core/services/login-guard.service';
import {PasswordComponent} from './management/settings/Password/Password.component';
import {PersonalInfoComponent} from './management/settings/personalInfo/PersonalInfo.component';
import {DevicesComponent} from './management/settings/devices/devices.component';
import {BetCodesComponent} from './management/bet-codes/bet-codes.component';
import {ReportsComponent} from './management/reports/reports.component';
import {TodayCashComponent} from './management/reports/today-cash/today-cash.component';
import {StatementTotalsComponent} from './management/reports/statement-totals/statement-totals.component';
import {AccountStatementComponent} from './management/reports/account-statement/account-statement.component';
import {BettingHistoryComponent} from './management/reports/betting-history/betting-history.component';
import {AnnouncementsComponent} from './management/Announcements/announcements.component';
import {BetInfoComponent} from './betInfo/betInfo.component';

export const routes = [

  {
    path: '',
    component: AppSportsComponent,
    canActivate: [LoginGuardService],
    children: [
      /*{ path: '', redirectTo: 'sport', pathMatch: 'full' },*/
 /*     {
        path: 'sport',
        component: AppSportsComponent,

        children: [*/
          /*{path: '', component: AllEventsComponent },*/
          { path: '', redirectTo: 'sport', pathMatch: 'full' },
          {path: 'sport', component: AllEventsComponent },
          {path: 'sport/:id', component: AllEventsComponent },
          {path: 'game/:id', component: GameComponent }
          /*  ]
      },*/
    ]

  },
  {
    path: 'management',
    component: ManagementComponent,
    canActivate: [LoginGuardService],
    children: [
      { path: '', redirectTo: 'bet-approval', pathMatch: 'full' },
      { path: 'bet-approval', component: BetApprovalsComponent },
      { path: 'bet-codes', component: BetCodesComponent },
      { path: 'announcements', component: AnnouncementsComponent },
      { path: 'settings',
        component: SettingsComponent,
        children: [
          {path: '', redirectTo: 'general', pathMatch: 'full' },
          {path: 'general', component: GeneralComponent },
          {path: 'sport', component: SportComponent },
          {path: 'devices', component: DevicesComponent },
          {path: 'password', component: PasswordComponent },
          {path: 'personal-info', component: PersonalInfoComponent },
        ]
      },
      { path: 'reports',
        component: ReportsComponent,
        children: [
          {path: '', redirectTo: 'todays-cash', pathMatch: 'full' },
          {path: 'todays-cash', component: TodayCashComponent },
          {path: 'statement-totals', component: StatementTotalsComponent },
          {path: 'account-statement', component: AccountStatementComponent },
          {path: 'betting-history', component: BettingHistoryComponent }
        ]
      },
    ]

  },

  // Not lazy-loaded routes
  { path: 'login', component: LoginComponent },
  { path: 'betslipInfo', component: BetInfoComponent },

  // Not found
  { path: '**', redirectTo: 'home' }

];
