import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {MenuComponent} from './components/menu/menu.component';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {BetTypePipe, FilterGroupPipe, FilterPipe, OrderPipe} from './pipe/pipes.service';
import {PrintComponent} from './components/print/print.component';
import {PopupBetslipComponent} from './components/popup-betslip/popup-betslip.component';
import {TodaysPrintComponent} from './components/todays-print/todays-print.component';
import {AnnoucmentPopupComponent} from './components/annoucment-popup/annoucment-popup.component';
import { Ng5SliderModule } from 'ng5-slider';
import {NgxBarcodeModule} from 'ngx-barcode';
import {ShortcutsComponent} from './components/Shortcuts/shortcuts.component';
import {TranslateModule} from '@ngx-translate/core';
import {TranslatorService} from './services/translator/translator.service';
@NgModule({
  exports: [
    MenuComponent,
    FilterPipe,
    OrderPipe,
    FilterGroupPipe,
    BetTypePipe,
    PrintComponent,
    PopupBetslipComponent,
    TodaysPrintComponent,
    AnnoucmentPopupComponent,
    ShortcutsComponent,
    TranslateModule,
    Ng5SliderModule
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    Ng5SliderModule,
    NgxBarcodeModule,
    TranslateModule,
  ],
  declarations: [
    MenuComponent,
    FilterPipe,
    OrderPipe,
    FilterGroupPipe,
    BetTypePipe,
    PrintComponent,
    PopupBetslipComponent,
    TodaysPrintComponent,
    AnnoucmentPopupComponent,
    ShortcutsComponent
  ],
  providers: []
})

export class CoreModule {
constructor(tr: TranslatorService){}
}
