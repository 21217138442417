import {Component, ElementRef, HostListener, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Settings} from '../../core/services/settings';
import {SportService} from '../../core/services/sport/sport.service';
import {Router} from '@angular/router';
import {RequestsService} from '../../core/services/requests/requests.service';
import * as consts from '../../core/enums/consts';

declare var $: any;

@Component({
  selector: 'app-sport',
  templateUrl: './app-sports.component.html'
})

export class AppSportsComponent implements OnInit, OnDestroy {
  public sportMenuOpened = false;
  public legueListOpened = false;
  public calendarFilterOpened = false;
  public SportsList = [];
  public SelectedSport = {};
  public CategoryList = [];
  public DateFilterList = consts.DateFilterList;
  selectedDateFilter = null;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      if (event.target.id == 'sport-selection-dropdown') {
        return false;
      }
      this.sportMenuOpened = false;

      if (event.target.id == 'league_list') {
        return false;
      }
      this.legueListOpened = false;
    }
  }

  constructor(
    public settings: Settings,
    public http: RequestsService,
    private eRef: ElementRef,
    public sportService: SportService,
    private route: Router,
    public zone: NgZone
  ) {

  }

  ngOnInit() {
    this.sportService.CDEvent.subscribe((e) => {
      if (e) {
        this.zone.run(() => {
        });
      }
    });
  }

  goToSport(sport) {
    if (sport.Id === this.sportService.activeSportId) {
      return false;
    }
    this.sportService.LeaguesList = [];
    this.route.navigate(['/sport', sport.Id]);
    this.sportMenuOpened = false;
    this.legueListOpened = false;
  }

  ngOnDestroy() {

  }

  expandAll(type) {
    const leng = this.CategoryList.length;
    for (let key = 0; key < leng; key++) {
      this.CategoryList[key].active = type;
    }
  }

  clearAllLeagues(type) {
    this.CategoryList.forEach((item, index) => {
      item.b.forEach((lItem, lIndex) => {
        lItem.noActive = type;
      });
    });
  }

  changeOddType(type) {
    if (this.sportService.OddType === 3) {
      this.route.navigate(['/sport']);
    }
    this.sportService.OddType = type;
    localStorage.setItem('OddType', type);
    this.sportService.PageIndex = 1;
    this.sportService.PagingArray.length = 0;
    this.sportService.GetMatchWithOdd();
  }

  FilterByCategory(category) {
    const arr = [];
    category.b.forEach((leg, ind) => {
      arr.push({Id: leg.Id, Group: leg.Group});
    });
    this.sportService.SelcetedSportInfo.a.forEach((item, index) => {
      item.b.forEach((leg, ind) => {
        if (item.Id !== category.Id) {
          leg.noActive = true;
        } else {
          leg.noActive = false;
        }
      });

    });
    this.sportService.LeaguesList = arr;

    this.sportService.PageIndex = 1;
    this.sportService.PagingArray.length = 0;
    this.sportService.GetMatchWithOdd();
    this.legueListOpened = false;
  }


  OpenLeguesList($ev) {
    $ev.stopPropagation();
    this.legueListOpened = !this.legueListOpened;
    this.CategoryList = JSON.parse(JSON.stringify(this.sportService.SelcetedSportInfo.a));
  }

  FilterByLegues() {
    const arr = [];
    this.CategoryList.forEach((cat, cInd) => {
      cat.b.forEach((leg, ind) => {
        if (!leg.noActive) {
          arr.push({Id: leg.Id, Group: leg.Group});
        }
      });
    });
    this.sportService.LeaguesList = arr;

    this.sportService.PageIndex = 1;
    this.sportService.PagingArray.length = 0;
    this.sportService.GetMatchWithOdd();
    this.sportService.SelcetedSportInfo.a = this.CategoryList;
    this.legueListOpened = false;
  }

  getFiterMatches(item) {
    this.sportService.PageIndex = 1;
    this.sportService.PagingArray.length = 0;
    this.sportService.dateFilter = item.Id;
    this.calendarFilterOpened = false;
    this.selectedDateFilter = item.Name;
    this.sportService.GetMatchWithOdd();
  }

}
