<div id="modalWindow" tabindex="-1" role="dialog" aria-labelledby="modal-windowLabel" style="display: block;" class="modal betslip-modal modal_report-print">
  <div class="modal-dialog" role="document">

    <div class="modal-content">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i></button>


      <div class="modal-header">
        <h4 class="modal-title">{{'Report' | translate}}</h4>
      </div>


      <div class="modal-body">
        <iframe src="" width="0" height="-118" *ngIf="false"></iframe>
      </div>

      <div class="modal-footer">
        <div class="btn btn-shop-primary-color btn-auto">{{'Print' | translate}}</div>
        <div class="btn btn-shop-second-color btn-auto">{{'Cancel' | translate}}</div>
      </div>

    </div>
  </div>
</div>
