import {Component, OnInit} from '@angular/core';
import {Settings} from '../../../core/services/settings';
import {RequestsService} from '../../../core/services/requests/requests.service';

declare var $: any;

@Component({
  selector: 'bet-codes',
  templateUrl: './bet-codes.component.html'
})

export class BetCodesComponent implements OnInit {
  public SportList = [];
  public selectedSport = {Id: 0, Name: ''};
  public OpenedOptions: boolean;
  public openedFilter = true;
  public BetCodesList = [];
  public loading: boolean;

  constructor(
    private settings: Settings,
    private http: RequestsService
  ) {
  }

  ngOnInit() {
    this.http.httpGet(this.settings.apiUrls.Sport.GetSports + this.settings.cashierData.LanguageId)
      .subscribe((data: any) => {
        if (!data.HasError) {
          this.SportList = data.Data;
          setTimeout(() => {
            $('select').selectric();
          }, 200);
        }
      });

    $('select').on('change', (e) => {
      this.selectedSport.Id = $('select').val();
      if (this.selectedSport.Id) {
        this.selectSport(this.selectedSport);
      } else {
        this.BetCodesList.length = 0;
      }
      /*this.valForm.patchValue({
        ResidenceCountryId: $('#ResidenceCountryId').val()
      });*/
    });
  }

  public selectSport(sport) {
    this.loading = true;
    this.selectedSport = sport;
    this.OpenedOptions = false;
    this.http.httpGet(this.settings.apiUrls.settings.GetBetCodes + sport.Id)
      .subscribe((data: any) => {
        if (!data.HasError) {
          this.BetCodesList = data.Data;
          this.loading = false;
        }
      });
  }


  printTable() {
    const newIframe = document.createElement('iframe');
    newIframe.width = '1px';
    newIframe.height = '1px';
    newIframe.src = 'about:blank';

    newIframe.onload = function () {
      const script_tag = newIframe.contentWindow.document.createElement('script');
      script_tag.type = 'text/javascript';
      const script = newIframe.contentWindow.document.createTextNode('function Print(){ window.focus(); window.print(); }');
      script_tag.appendChild(script);

      newIframe.contentWindow.document.body.innerHTML = document.getElementById('printTable').outerHTML;
      newIframe.contentWindow.document.body.appendChild(script_tag);

      setTimeout(function () {
        let contentWindow = newIframe.contentWindow;
        newIframe.contentWindow.print();
        newIframe.contentWindow.document.body.removeChild(script_tag);
        newIframe.parentElement.removeChild(newIframe);
      }, 200);
    };
    document.body.appendChild(newIframe);
  }
}
