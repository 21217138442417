<div class="aside" [ngClass]="{'BetslipExpanded': settings.IsBetslipExpanded}">
  <div class="square-section">
    <div class="row">
      <div class="col-xs-10">
        <div class="custom-input primary-color fullwidth">
          <div class="input-group col-xs-12">
            <div class="searching_betslip" style="display: none;"></div>
            <input id="scanner_input" type="text" class="form-control  input-md" placeholder="{{'Betslip Id' | translate}}"  (change)="searchBarCode = $event.target.value">
            <button class="btn btn-input primary-color" style="display: none;">
              <i class="fa fa-times offset-right20"></i></button>
            <button class="btn btn-input searchBetslipButton" type="button" (click)="searchBetSlip()">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>

        <div class="betslip_search_error" *ngIf="false">
          <div class="error_message error-effect-jelly">
            <div class="error_message_icon_close clickable">
              <i class="fa fa-times"></i>
            </div>
            <div class="error_message_text clickable">
              <span class="highlighted">{{'Error' | translate}}:</span>
              <i class="fa fa-exclamation"></i>
            </div>
            <div class="error-success-progress"></div>
          </div>
        </div>
      </div>
      <div class="col-xs-2">
        <div class="connectionStatus online">
          <i class="fa fa-sitemap"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="round-section no-offset">
    <div class="row">
      <div class="col-xs-5">
        <div class="custom-input  betByCode">
          <div class="input-group col-xs-12">
            <input id="betslip_event_code_market_code"
                   class="betslip_event_code_market_code form-control input-md" type="text" tabindex="1" [value]="searchBetCode.matchId ? searchBetCode.matchId : ''"
                   (keyup.enter)="enterBetCode($event, 'next')"
                   placeholder="{{'Match ID' | translate}}" (change)="searchBetCode.matchId = $event.target.value">
            <button class="btn btn-input primary-color" style="display: none;">
              <i class="fa fa-times"></i></button>
          </div>
        </div>
      </div>
      <div class="col-xs-4">
        <div class="custom-input ">
          <div class="input-group">
            <input id="betslip_event_code_bet_code" #name  class="betslip_event_code_bet_code form-control  input-md" (keyup.enter)="enterBetCode($event)"
                   type="text" [disabled]="searchBetCode.blockedCode"  placeholder="{{'Bet code' | translate}}" (change)="searchBetCode.betCode = $event.target.value"
                   [value]="searchBetCode.betCode ? searchBetCode.betCode : ''">
            <span class="btn btn-input clickable lockButton" (click)="searchBetCode.blockedCode = !searchBetCode.blockedCode">
                <i class="fa " [ngClass]="searchBetCode.blockedCode ? 'fa-lock' : 'fa-unlock'"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="col-xs-1">
        <div class="btn clear-all-filters transparent" title="{{'Search Bet Codes' | translate}}" (click)="searchByBetCode()">
          <i class="fa fa-search"></i>
        </div>
      </div>
      <div class="col-xs-2">
        <a class="betslip-caret" (click)="settings.betSlipClosed = !settings.betSlipClosed">
          <i class="fa fa-list"></i>
          <i class="fa " [ngClass]="!settings.betSlipClosed ? 'fa-angle-up' : 'fa-angle-down'"></i>
        </a>
      </div>
    </div>
  </div>
  <div class="betslip_bet_code_error">
    <div class="error_message error-effect-jelly" style="display: none;">
      <div class="error_message_icon_close clickable">
        <i class="fa fa-times"></i>
      </div>
      <div class="error_message_text clickable">
        <span class="highlighted">{{'Error' | translate}}:</span>
        <i class="fa fa-exclamation"></i>
      </div>
      <div class="error-success-progress"></div>
    </div>
  </div>

  <div class="betslip-code-search-container" *ngIf="searchBetResults">
    <div class="betslip-code-search-header">
      <div class="code" title="{{'Code' | translate}}">{{'Code' | translate}}</div>
      <div class="evname" title="{{'EVENT' | translate}}">{{'EVENT' | translate}}
      </div>
      <div class="evitemname" title="{{'Event Item' | translate}}">{{'Event Item' | translate}}
      </div>
      <div class="clickable button fa fa-times" (click)="clearBetResults()"></div>
    </div>
    <div class="betslip-code-search-body">
      <ul class="drop_down_list_body">
        <ng-template let-market ngFor [ngForOf]="searchBetResults.Markets">
          <ng-template let-selection ngFor [ngForOf]="market.j">
            <li (click)="selectBetCode(market, selection)">
              <div class="code" title="{{selection.BetCode}}">{{selection.BetCode}}</div>
              <div class="evname" title="{{market.Name}}">{{market.Name}}</div>
              <div class="evitemname" title="{{selection.Name}}">{{selection.Name}}</div>
            </li>
          </ng-template>
        </ng-template>

      </ul>
      <div class="footer">
        <span></span>
      </div>
    </div>
  </div>

  <div class="betslip-box " [ngClass]="!settings.betSlipClosed ? 'betslip-opened' : 'betslip-closed'">
    <div id="betslip_body" class="betslip-body no_multibetslip" style="">
      <div class="betslip-tab_body height100">
        <div class="betslip-expander-box">

        <!--  <span class="betslip-expander" (click)="settings.IsBetslipExpanded = !settings.IsBetslipExpanded">
              <i class="fa fa-arrow-left"></i>
              <span class="title" title="{{'Expand betslip' | translate}}">{{'Expand betslip' | translate}}</span>
          </span>-->

          <!--<div class="print-preview clickable" title="Print Preview">
            <i class="fa fa-print"></i>
          </div>-->

          <div class="betslip-selection">
            <span title="Selections: {{betSlipService.BetSlipList.length}}">{{'Selections' | translate}}: {{betSlipService.BetSlipList.length}}</span>
          </div>
        </div>
        <div class="betslip-empty bgbetslip-empty-2" style="" *ngIf="!betSlipService.BetSlipList.length">
          <div class="betslip-empty-title">
            {{'No bet has been selected' | translate}}!
          </div>
          <div class="betslip-empty-txt">
            {{'To select a new bet, please click on the respective odds.' | translate}}
          </div>
          <div class="betslip-last-bet" id="betslip_last_bet" style="text-align: center;" *ngIf="settings.lastBetObj.stake">
            <div class="last-bet-details">
              <span>{{settings.lastBetObj.time | date : 'dd-MM-yyyy HH:mm'}}</span>
              <span >, €</span>
              <span class="bet_maxpayout">{{settings.lastBetObj.stake}}</span>
              <span>,</span> <span >{{settings.lastBetObj.selectionCount}}</span><span>
                        </span><span >{{'Selections' | translate}}:</span>
            </div>
            <div class="btn btn-shop-primary-color btn-auto" (click)="copyLasBet()">
              <i class="fa fa-clipboard"></i> <span>{{'Copy Last Bet' | translate}}</span>
            </div>
          </div>
        </div>

        <!--Betslip Start-->
        <div class="betslip_box height100" style="" *ngIf="betSlipService.BetSlipList.length">
          <div class="betslip-odds betslip_scrollbar_container accumulator-odds-enabled" style="">
            <div id="betslipSelectionCollapse" class="collapse in">
              <ng-template let-betItem ngFor [ngForOf]="betSlipService.BetSlipList" let-betIndex="index">
                <div class="betslip-market-selection betslip_selection" [ngClass]="{opacity04: betItem.blockedMarket || betItem.selection.e ==1}">
                  <div class="betslip-order-number" title="1">{{betIndex + 1}}</div>
                  <div class="betslip-details"  title="{{betItem.category}} - {{betItem.tournament}} -  {{betItem.eventName}}" >
                    <div class="betslip-row">
                      <div class="id-market">
                        <div class="country" title="{{betIndex + 1}}. {{betItem.eventName}}" style="display: none;">{{betIndex+1}}. {{betItem.eventName}}</div>
                        <div class="betslip-txt team-row">
                          <span class="red" >
                            <i class="fa fa-ban" aria-hidden="true" title="Closed" *ngIf="betItem.blockedMarket || betItem.selection.e ==1"></i>
                            {{betItem.matchId}}
                          </span>
                          {{betItem.eventName}}
                        </div>
                      </div>
                      <div class="delete" (click)="betSlipService.remove(betItem.matchId, betItem.marketId, betItem.selectionId)">
                        <i class="fa fa-times"></i>
                      </div>
                    </div>
                    <div class="betslip-row betslip-second-row">
                      <span class="bet-type">
                        <!--<span class="liveIconText">Live</span>-->
                        <span class="separator betslip-txt" title="{{betItem.marketName}}">{{betItem.marketName}}</span>
                        <span class="betslip-number"  title="{{betItem.selection['Name']}}">{{betItem.selection['Name']}}</span>
                      </span>
                      <span class="market-element-ns">
                          <span class="txt">{{betItem.minSelectionCount}}</span>
                      </span>
                    </div>
                    <div class="betslip-row">
                      <div class="group-system-row" *ngIf="!betItem.blockedMarket || betItem.selection.e !==1">
                        <span class="icon-group-system banker white txtg betslip_selection_second_line_banker" *ngIf="show.system && settings.sportOptions.AllowBankers" (click)="setToBanker(betItem)" [ngClass]="{selected: betItem.isBanker}">B</span>
                        <span class="icon-group-system txtg dark" (click)="betItem.ShowGroupSelect = true" [ngClass]="!betItem.group ? 'G' : betItem.group" *ngIf="show.groups  && settings.sportOptions.AllowGroups">{{!betItem.group ? 'G' : betItem.group}}</span>
                      </div>
                      <div class="bet-info">
                        <span class="betslip-status won betslip_selection_second_line_status_success liveIconText" *ngIf="betItem.isLive">Live</span>
                        <span class="betslip-txt" [ngClass]="{'caret-up': betItem.selection.Up, 'caret-down': betItem.selection.Down}">{{betItem.selection.Odd}}
                          <i id="caret-sport" class="fa" [ngClass]="{'fa-caret-up': betItem.selection.Up, 'fa-caret-down': betItem.selection.Down}"></i>
                        </span>
                        <span class="betslip-stake">
                            <i class="fa fa-times"></i>
                        </span>
                      </div>
                      <div class="custom-input" *ngIf="!show.system && betItem.minSelectionCount == 1">
                        <div class="input-group col-xs-input" >
                          <input type="text" [attr.disabled]="betItem.blockedMarket || betItem.selection.e ==1 ? '' :  null" class="form-control input-md" placeholder="0.00" [value]="betItem.amount ? betItem.amount : ''" (change)="betAmountChanged(betItem, betItem.BSId, $event.target)">
                          <!--/ko-->
                        </div>
                        <div class="currency">€</div>
                      </div>
                      <div class="betslip-selection-groups" *ngIf="betItem.ShowGroupSelect">
                        <ng-template ngFor let-group [ngForOf]="betSlipService.groupsList">
                          <div class="betslip-group" [ngClass]="group.name" (click)="userPickGroup(betItem, group)">{{group.name}}</div>
                        </ng-template>
                        <div class="betslip-no-group" (click)="userPickGroup(betItem, {name: ''})">NO GROUP</div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>

            <div class="betslip-check-row">
              <div class="betslip-check-groups clickable" >
                <div class="checkBox" [ngClass]="{checked: show.groups}" (click)="showGroups()" title="Groups"
                     id="groups_chakbox"  *ngIf="settings.sportOptions.AllowGroups && settings.sportOptions.AllowSystem"></div>
                <div class="betslip-txt" (click)="showGroups()" *ngIf="settings.sportOptions.AllowGroups && settings.sportOptions.AllowSystem">{{'Groups' | translate}}</div>
              </div>
              <div class="betslip-check-system clickable" (click)="showSystem()" *ngIf="settings.sportOptions.AllowSystem">
                <div class="checkBox" [ngClass]="{checked: show.system}"  title="Systems" id="sytem_chakbox"></div>
                <div class="betslip-txt" >Systems</div>
              </div>
              <div class="permutations" *ngIf="show.groups">
                <div class="btn btn-shop-primary-color btn-auto">
                  <span class="txt">G</span> <span class="txt2">...</span>
                </div>
              </div>
            </div>

            <div >
              <ng-template let-variantGroup ngFor [ngForOf]="betSlipService.VariantsList" let-vIndex="index">
                <div class="betslip_system  {{vIndex}}" [ngClass]="{betslip_system_expanded: variantGroup.opened}" id="betslip_system  {{vIndex}}" *ngIf="!variantGroup.hidden">
                  <div class="betslip-header">
                    <div class="betslip-row" (click)="variantGroup.opened = !variantGroup.opened">
                      <div class="delete" (click)="hideGroup($event, variantGroup)">
                        <i class="fa fa-remove"></i>
                      </div>
                      <a class="btn btn-betslip-plus" aria-expanded="true">
                        <i class="fa fa-minus" [ngClass]="{'fa-plus': !variantGroup.opened, 'fa-minus': variantGroup.opened}"></i>
                      </a>
                      <div class="betslip-txt" title="{{variantGroup.variantName}}">{{variantGroup.variantName}}</div>

                    </div>
                    <div class="betslip-row">
                      <div class="bet-info betslip-accumulator">
                        <div class="clickable">{{variantGroup.variantCount}} {{'LINES' | translate}} <span class="betslip_systems_multiplier_button">=</span></div>
                      </div>
                      <div class="custom-input pull-right">
                        <div class="input-group col-xs-input">
                          <input type="text" class="form-control input-md" placeholder="0.00" [value]="variantGroup.betAmount ? variantGroup.betAmount : ''" (change)="VGBAmountChanged($event.target.value, variantGroup)">
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="betslip_system_details" style="">
                    <!--ko if:IsExpanded-->
                    <div class="betslip-txt" *ngIf="variantGroup.count > 50">
                      {{'Too many lines generated. The lines cannot be shown but are calculated in the total amount.' | translate}}
                    </div>
                    <div id="betslipAccumulatorCollapse" aria-expanded="true" style="" *ngIf="variantGroup.opened && variantGroup.count < 50">
                      <!--ko foreach: Items() -->
                      <ng-template let-variant ngFor [ngForOf]="variantGroup.Variants">
                        <div class="betslip-market-selection">
                          <div class="betslip-row">
                            <div class="betslip-txt-number" title="{{variant.variant}}">{{variant.variant}}</div>
                          </div>
                          <div class="betslip-row">
                            <div class="bet-info betslip-accumulator">
                              <div class="clickable" style="position: relative;width: 135px;">
                                <div class="checkBox checked" [ngClass]="{checked: !variant.hidden}" (click)="hideVariant($event, variant, variantGroup)"></div>
                              </div>
                              <div class="betslip-txt" style="width: 67px;">@{{variant.totalOdd}}</div>
                            </div>
                            <div class="custom-input pull-right">
                              <div class="input-group col-xs-input">
                                <input type="text" class="form-control input-md" placeholder="0.00" [value]="variant.betAmount ? variant.betAmount : ''" (change)="VBAmountChanged($event.target.value, variant, variantGroup)" >
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>

                </div>
              </ng-template>
            </div>

          </div>
          <!--Betslip Summary - place bets-->
          <div class="betslip-summary" >
            <div class="betslip-error-notification bottom" *ngIf="error.code">
              <div class="betslip-error-hq"  (click)="clearError()">
                <div class="error_message error-effect-jelly">
                  <div class="error_message_icon_close clickable">
                    <i class="fa fa-times"></i>
                  </div>
                  <div class="error_message_text clickable">
                    <span class="highlighted" title="{{error.title | translate}}">{{error.title | translate}}</span>
                    <span title="{{error.description | translate}}">{{error.description | translate}}</span>
                    <i class="fa fa-exclamation"></i>
                  </div>
                  <div class="error-success-progress"></div>
                </div>
              </div>
            </div>
            <div class="betslip-error-notification bottom" *ngIf="IsBetApprovalReq">
              <div class="betslip-error-hq">
                <div class="error_message error-effect-jelly warning_message"  style="">
                <div class="error_message_icon_close clickable">
                  <i class="fa fa-times"></i>
                </div>
                <div class="error_message_text clickable">
                  <span class="highlighted">{{'Confirmation required' | translate}}</span>
                  <span><br>{{'Please Wait For Authorization Do you want to ask for HQ confirmation?' | translate}}</span>
                  <i class="fa fa-exclamation"></i>
                </div>
                <div class="error-success-progress"></div>
              </div>
              <div class="btns-hq">
                <div class="btn btn-shop-primary-color hq-confirmation btn-auto clickable" (click)="ASKForBetApproval(1)">
                  <i class="fa fa-check-square-o"></i>{{'ASK' | translate}}
                </div>
                <div class="btn btn-shop-primary-color btn-auto clickable"  (click)="ASKForBetApproval(0)">
                  <i class="fa fa-ban"></i>{{'Cancel' | translate}}
                </div>
              </div>
              </div>
            </div>
            <div class="betslip-notification-error" *ngIf="betSlipService.notification.BetslipComputeError.length > 0 || betSlipService.notification.InvalidCombinationsMessage.length > 0 || betSlipService.notification.OddsHaveBeenChanged">
              <div class=" betslip_compute_error clickable" *ngIf="betSlipService.notification.BetslipComputeError.length > 0">
                <span>{{betSlipService.notification.BetslipComputeError[0].description | translate}}</span>
              </div>
              <div class="betslip_compute_error clickable" *ngIf="betSlipService.notification.InvalidCombinationsMessage.length > 0">
                <span>{{betSlipService.notification.InvalidCombinationsMessage[0].description | translate}}</span>
              </div>
              <div class="betslip_compute_error clickable" *ngIf="betSlipService.notification.OddsHaveBeenChanged">
                <span class="clickable">{{betSlipService.notification.OddsHaveBeenChanged | translate}}</span>
              </div>
            </div>
            <div class="betslip-row">
              <div class="betslip-txt">{{'TOTAL STAKE' | translate}}</div>
              <div class="custom-input">
                <div class="input-group col-xs-input">
                  <input type="text" placeholder="0" class="form-control second-color input-md"
                         [value]="betSlipService.TotalStake ? betSlipService.TotalStake : ''"
                         (change)="TotalStakeChange($event.target.value)" id="total_stake_input">
                </div>
              </div>
            </div>

            <div class="betslip-row">
              <div class="betslip-txt" >{{'NUMBER OF BETS' | translate}}:</div>
              <div class="custom-input">
                <div class="currency-number primary-color">{{betSlipService.NumberOfBets}}</div>
              </div>
            </div>
            <div class="betslip-row">
              <div class="betslip-txt">{{'Maximum possible payout' | translate}}:</div>
              <div class="custom-input">
                <div class="currency-number primary-color" style="">{{betSlipService.MaxPosPayout}}</div>
                <div class="currency">€</div>
              </div>
            </div>

            <div class="betslip-row" *ngIf="!show.system">
              <div class="betslip-txt" title="TOTAL ODDS">
                <span >{{'TOTAL ODDS' | translate}}</span>
              </div>
              <div class="custom-input" style="max-width: 132px;">
                <div class="currency-number primary-color" style="width: 108px;" >{{betSlipService.MaxOdds.toFixed(2)}}</div>
              </div>
            </div>

            <div class="accept-odds-options">
              <div class="betslip-check-row accept-odds">
                <div class="checkBox " id="acceptHigherOdds" [ngClass]="{checked: AcceptHigherOdds}" (click)="AcceptHigherOdds = !AcceptHigherOdds" title="{{'Accept higher odds' | translate}}">
                  <label class="betslip-txt" for="acceptHigherOdds" title="Accept higher odds">{{'Accept higher odds' | translate}}</label>
                </div>
              </div>

              <div class="betslip-check-row accept-odds">
                <div class="checkBox" id="acceptAnyOdds" [ngClass]="{checked: AcceptOddsChanges}" (click)="AcceptOddsChanges = !AcceptOddsChanges"title="{{'Accept any odds changes' | translate}}">
                  <label class="betslip-txt" for="acceptAnyOdds">{{'Accept any odds changes' | translate}}</label>
                </div>

                <div class="betslip-alert-notification">
                </div>
              </div>
            </div>

            <div class="btn-placebet">
              <div class="btn btn-shop-primary-color pull-left btn-auto" [ngClass]="{disabled: !betSlipService.TotalStake}" (click)="PlaceBet()">
                <i class="fa fa-check"></i>
                {{'PLACE BET' | translate}}
              </div>
              <div class="btn btn-shop-second-color pull-right btn-auto clickable" (click)="ClearAllBet()">
                <i class="fa fa-remove"></i>
                {{'CLEAR ALL' | translate}}
              </div>
            </div>
          </div>
          <div class="modal-backdrop fade" style="display: none;"></div>
        </div>
        <div class="full_is_busy" *ngIf="BetInPending">
          <div class="full_is_busy_background"></div>
          <div class="blurred_area"></div>
          <div class="full_is_busy_contents">
            <div class="full_is_busy_title">
              <span class="full_is_busy_title_icon"></span>
              <span class="full_is_busy_title_text">{{'Please wait...' | translate}}</span>
            </div>
            <div class="full_is_busy_message"></div>
          </div>
        </div>
        <div class="popular-bets">
          <!--<button type="button" class="btn btn-shop-primary-color btn-popular">
            <i class="fa fa-plus"></i>
            <span title="POPULAR BETS">POPULAR BETS</span>
          </button>-->
        </div>
      </div>

      <div class="error_message error-effect-jelly" style="display: none;">
        <div class="error_message_icon_close clickable">
          <i class="fa fa-times"></i>
        </div>
        <div class="error_message_text clickable">
          <span class="highlighted">{{'A BETSLIP ERROR OCCURED' | translate}}</span>
          <span>{{'Please wait or' | translate}} <a href="/">{{'refresh the page' | translate}}</a></span>
          <i class="fa fa-exclamation"></i>
        </div>
        <div class="error-success-progress"></div>
      </div>
    </div>
  </div>

</div>

<app-print  id="asdf"  [betData]="SuccessBetData" *ngIf="SuccessBetData" style="display: none"></app-print>

<popup-betslip [betslipData]="BetInfo" *ngIf="BetInfo" (closeEvent)="RemoveBetslipData()"></popup-betslip>
