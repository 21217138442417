import {Pipe, PipeTransform} from '@angular/core';

import * as _ from 'lodash';

@Pipe({
  name: 'filterByObj'
})
export class FilterPipe implements PipeTransform {
  // passing another argument
  transform(items: any[], obj) {

    if (obj && items && items.length) {
      return _.filter(items, (item) => {
        if (item.Name.search(new RegExp(obj.Name, 'i')) > -1) {
          return true;
        }
        return false;
      });
    }
    return items;
  }
}


@Pipe({
  name: 'group',
  pure: false
})
export class FilterGroupPipe implements PipeTransform {
  // passing another argument
  transform(items: any[], groupId) {

    if (!groupId) {
      return items;
    }

    if (items && items.length) {
      return _.filter(items, (item) => {
        if (item.b.indexOf(groupId) > -1) {
          return true;
        }
        return false;
      });
    }
    return items;
  }
}


@Pipe({
  name: 'order',
  pure: false
})
export class OrderPipe implements PipeTransform {
  // passing another argument
  transform(items: any[], order, type?) {

    if (order && items && items.length) {
      return _.orderBy(items, [order], [type]);
    }
    return items;
  }
}


@Pipe({
  name: 'betType',
  pure: true
})
export class BetTypePipe implements PipeTransform {
  // passing another argument
  transform(items: any[], search) {
    search = search.toLowerCase()
    if (search && items && items.length) {
      const item = _.filter(items, (item) => {
        if (item.Name.search(search) > -1) {
          return true;
        }
        if (item.Selections) {
          const a = _.filter(item.Selections, (sel) => {
            if (sel.Name.search(search) > -1) {
              return true;
            }
            return false;
          });

          if (a.length) {
            return true;
          } else {
            return false;
          }
        }

        return false;
      });
      return item;
    }
    return items;
  }
}
