import {Injectable} from '@angular/core';

declare var moment: any;

@Injectable({
  providedIn: 'root'
})

export class CoreService {
  constructor() {
  }

  printElementById(id, styleUrl?) {
    const newIframe = document.createElement('iframe');
    newIframe.width = '1px';
    newIframe.height = '1px';
    newIframe.src = 'about:blank';

    newIframe.onload = function () {
      const script_tag = newIframe.contentWindow.document.createElement('script');
      script_tag.type = 'text/javascript';
      const script = newIframe.contentWindow.document.createTextNode('function Print(){ window.focus(); window.print(); }');
      script_tag.appendChild(script);

      newIframe.contentWindow.document.body.innerHTML = document.getElementById(id).innerHTML;
      newIframe.contentWindow.document.body.appendChild(script_tag);
      if (styleUrl) {
        const csslink = document.createElement('link');
        csslink.setAttribute('rel', 'stylesheet');
        csslink.setAttribute('type', 'text/css');
        csslink.setAttribute('href', styleUrl);
        newIframe.contentWindow.document.head.appendChild(csslink);
      }
      setTimeout(function () {
        const contentWindow = newIframe.contentWindow;
        newIframe.contentWindow.print();
        newIframe.contentWindow.document.body.removeChild(script_tag);
        newIframe.parentElement.removeChild(newIframe);
      }, 200);
    };
    document.body.appendChild(newIframe);
  }

  public DateFromNow(date) {
    if (!date)
      return '';

    const testDateUtc = moment.utc(date);
    const localDate = moment(testDateUtc).local();
    return localDate.format('DD/MM/YYYY HH:mm:ss');
  }


  setTimeoutToSelection(selection, type, value) {
    if (selection.timeOutId) {
      window.clearTimeout(selection.timeOutId);
    }

    if (type === 'Up') {
      selection.Up = true;
      selection.Down = false;
    }

    if (type === 'Down') {
      selection.Up = false;
      selection.Down = true;
    }

    setTimeout(() => {
      selection[type] = false;
    }, 4 * 1000);
    selection.Odd = value;
  }

  getMinuteFromUpdate(game) {
    // const time =  moment().local()
    if (game.ag) {
      return game.ah;
    }
    const testDateUtc = moment.utc(game.q);
    const localDate = moment(testDateUtc).local();
    const startTime = new Date(localDate);
    const endTime = new Date();
    const difference = endTime.getTime() - startTime.getTime();
    const resultInMinutes = Math.round(difference / 60000);
    return game.ah + resultInMinutes;
  }

  dateUtc(date) {
    return moment.utc(date).toDate();
  }
}
