export const tymeZones = [
  {Id: '1', Name: 'International Date Line West', GMT: '-12:00'},
  {Id: '2', Name: 'Coordinated Universal Time', GMT: '-11:00'},
  {Id: '3', Name: 'Hawaii', GMT: '-10:00'},
  {Id: '4', Name: 'Alaska', GMT: '-09:00'},
  {Id: '5', Name: 'Baja California', GMT: '-08:00'},
  {Id: '6', Name: 'Pacific Time(US &amp; Canada)', GMT: '-08:00'},
  {Id: '7', Name: 'Mountain Time(US &amp; Canada)', GMT: '-07:00'},
  {Id: '8', Name: 'Chihuahua, La Paz, Mazatlan', GMT: '-07:00'},
  {Id: '9', Name: 'Arizona', GMT: '-07:00'},
  {Id: '10', Name: 'Guadalajara, Mexico City, Monterrey', GMT: '-06:00'},
  {Id: '11', Name: 'Central America', GMT: '-06:00'},
  {Id: '12', Name: 'Saskatchewan', GMT: '-06:00'},
  {Id: '13', Name: 'Central Time(US &amp; Canada)', GMT: '-06:00'},
  {Id: '14', Name: 'Indiana(East)', GMT: '-05:00'},
  {Id: '15', Name: 'Eastern Time(US &amp; Canada)', GMT: '-05:00'},
  {Id: '16', Name: 'Bogota, Lima, Quito', GMT: '-05:00'},
  {Id: '17', Name: 'Caracas', GMT: '-04:30'},
  {Id: '18', Name: 'Asuncion', GMT: '-04:00'},
  {Id: '19', Name: 'Georgetown, La Paz, Manaus, San Juan', GMT: '-04:00'},
  {Id: '20', Name: 'Atlantic Time(Canada)', GMT: '-04:00'},
  {Id: '21', Name: 'Cuiaba', GMT: '-04:00'},
  {Id: '22', Name: 'Santiago', GMT: '-04:00'},
  {Id: '23', Name: 'Newfoundland', GMT: '-03:30'},
  {Id: '24', Name: 'Montevideo', GMT: '-03:00'},
  {Id: '25', Name: 'Buenos Aires', GMT: '-03:00'},
  {Id: '26', Name: 'Greenland', GMT: '-03:00'},
  {Id: '27', Name: 'Brasilia', GMT: '-03:00'},
  {Id: '28', Name: 'Cayenne, Fortaleza', GMT: '-03:00'},
  {Id: '29', Name: 'Coordinated Universal Time', GMT: '-02:00'},
  {Id: '30', Name: 'Mid - Atlantic', GMT: '-02:00'},
  {Id: '31', Name: 'Cape Verde Is.', GMT: '-01:00'},
  {Id: '32', Name: 'Azores', GMT: '-01:00'},
  {Id: '33', Name: 'Dublin, Edinburgh, Lisbon, London ', GMT: ''},
  {Id: '34', Name: 'Monrovia, Reykjavik', GMT: ''},
  {Id: '35', Name: 'Casablanca', GMT: ''},
  {Id: '36', Name: 'Coordinated Universal Time', GMT: ''},
  {Id: '37', Name: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna', GMT: '+01:00'},
  {Id: '38', Name: 'Brussels, Copenhagen, Madrid, Paris', GMT: '+01:00'},
  {Id: '39', Name: 'Sarajevo, Skopje, Warsaw, Zagreb', GMT: '+01:00'},
  {Id: '40', Name: 'West Central Africa', GMT: '+01:00'},
  {Id: '41', Name: 'Windhoek', GMT: '+01:00'},
  {Id: '42', Name: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague', GMT: '+01:00'},
  {Id: '43', Name: 'Jerusalem', GMT: '+02:00'},
  {Id: '44', Name: 'Amman', GMT: '+02:00'},
  {Id: '45', Name: 'Cairo', GMT: '+02:00'},
  {Id: '46', Name: 'Damascus', GMT: '+02:00'},
  {Id: '47', Name: 'Harare, Pretoria, Lusaka', GMT: '+02:00'},
  {Id: '48', Name: 'Nicosia', GMT: '+02:00'},
  {Id: '49', Name: 'Athens, Bucharest', GMT: '+02:00'},
  {Id: '50', Name: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius', GMT: '+02:00'},
  {Id: '51', Name: 'Beirut', GMT: '+02:00'},
  {Id: '52', Name: 'Nairobi', GMT: '+03:00'},
  {Id: '53', Name: 'Kuwait, Riyadh', GMT: '+03:00'},
  {Id: '54', Name: 'Baghdad', GMT: '+03:00'},
  {Id: '55', Name: 'Tehran', GMT: '+03:00'},
  {Id: '56', Name: 'Port Louis  Mauritius', GMT: '+04:00'},
  {Id: '57', Name: 'Baku', GMT: '+04:00'},
  {Id: '58', Name: 'Tbilisi', GMT: '+04:00'},
  {Id: '59', Name: 'Moscow, St.Petersburg, Volgograd', GMT: '+04:00'},
  {Id: '60', Name: 'Yerevan', GMT: '+04:00'},
  {Id: '61', Name: 'Abu Dhabi, Muscat', GMT: '+04:00'},
  {Id: '62', Name: 'Kabul', GMT: '+04:30'},
  {Id: '63', Name: 'Tashkent', GMT: '+05:00'},
  {Id: '64', Name: 'Islamabad, Karachi', GMT: '+05:00'},
  {Id: '65', Name: 'Chennai, Kolkata, Mumbai, New Delhi', GMT: '+05:30'},
  {Id: '66', Name: 'Sri Jayawardenepura', GMT: '+05:30'},
  {Id: '67', Name: 'Kathmandu', GMT: '+05:45'},
  {Id: '68', Name: 'Astana', GMT: '+06:00'},
  {Id: '69', Name: 'Ekaterinburg', GMT: '+06:00'},
  {Id: '70', Name: 'Dhaka', GMT: '+06:00'},
  {Id: '71', Name: 'Yangon(Rangoon)', GMT: '+06:30'},
  {Id: '72', Name: 'Bangkok, Hanoi, Jakarta', GMT: '+07:00'},
  {Id: '73', Name: 'Novosibirsk', GMT: '+07:00'},
  {Id: '74', Name: 'Ulaanbaatar', GMT: '+08:00'},
  {Id: '75', Name: 'Krasnoyarsk', GMT: '+08:00'},
  {Id: '76', Name: 'Beijing, Chongqing, Hong Kong, Urumqi', GMT: '+08:00'},
  {Id: '77', Name: 'Taipei', GMT: '+08:00'},
  {Id: '78', Name: 'Kuala Lumpur, Singapore', GMT: '+08:00'},
  {Id: '79', Name: 'Perth', GMT: '+08:00'},
  {Id: '80', Name: 'Osaka, Sapporo, Tokyo', GMT: '+09:00'},
  {Id: '81', Name: 'Irkutsk', GMT: '+09:00'},
  {Id: '82', Name: 'Seoul', GMT: '+09:00'},
  {Id: '83', Name: 'Darwin', GMT: '+09:30'},
  {Id: '84', Name: 'Adelaide', GMT: '+09:30'},
  {Id: '85', Name: 'Brisbane', GMT: '+10:00'},
  {Id: '86', Name: 'Guam, Port Moresby', GMT: '+10:00'},
  {Id: '87', Name: 'Canberra, Melbourne, Sydney', GMT: '+10:00'},
  {Id: '88', Name: 'Yakutsk', GMT: '+10:00'},
  {Id: '89', Name: 'Hobart', GMT: '+10:00'},
  {Id: '90', Name: 'Solomon Is., New Caledonia', GMT: '+11:00'},
  {Id: '91', Name: 'Vladivostok', GMT: '+11:00'},
  {Id: '92', Name: 'Coordinated Universal Time', GMT: '+12:00'},
  {Id: '93', Name: 'Fiji', GMT: '+12:00'},
  {Id: '94', Name: 'Petropavlovsk - Kamchatsky - Old', GMT: '+12:00'},
  {Id: '95', Name: 'Magadan', GMT: '+12:00'},
  {Id: '96', Name: 'Auckland, Wellington', GMT: '+12:00'},
  {Id: '97', Name: 'Nuku\'alofa', GMT: '+13:00'},
  {Id: '98', Name: 'Samoa', GMT: '+13:00'},
];
