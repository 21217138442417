import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {AppSportsComponent} from './app-sports.component';
import {AllEventsComponent} from './all/allEvents.component';
import {GameComponent} from './game/game.component';
import {RouterModule} from '@angular/router';
import {TreeComponent} from './components/tree/tree.component';
import {BetSlipComponent} from './components/betSpip/betSlip.component';
import {CommonModule} from '@angular/common';
import {CoreModule} from '../../core/core.module';
import {EventItemComponent} from './components/event-item/event-item.component';
import {FormsModule} from '@angular/forms';
@NgModule({
  exports: [

  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    CoreModule
  ],
  declarations: [
    AppSportsComponent,
    AllEventsComponent,
    GameComponent,
    TreeComponent,
    BetSlipComponent,
    EventItemComponent
  ],
 // schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})

export class SportsModule {
  constructor() {

  }
}
