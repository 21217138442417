import {EventEmitter, Injectable, NgZone} from '@angular/core';
// import * as _ from 'lodash';
import {RequestsService} from '../requests/requests.service';
import {Settings} from '../settings';
import * as error from '../../enums/errors';
import {CoreService} from '../core.service';
import {closeSportTypes} from '../../enums/consts';
import {MarketsModel} from '../../models/match.model';

declare var _: any;

declare var localStorage: any;

@Injectable({
  providedIn: 'root',
})
export class BetSlipService {
  public BetSlipList = [];
  public BetslipLines = [];
  public VariantsList = [];
  public matchIds = [];
  public ShowSystem = false;
  private notificationList = error.NotificationList;
  public notification = {
    BetslipComputeError: [],
    InvalidCombinationsMessage: [],
    OddsHaveBeenChanged: ''
  };
  public groupsList = [
    {name: 'G1'},
    {name: 'G2'},
    {name: 'G3'},
    {name: 'G4'},
    {name: 'G5'},
    {name: 'G6'},
    {name: 'G7'},
    {name: 'G8'},
    {name: 'G9'},
    {name: 'G10'},
    {name: 'G11'},
    {name: 'G12'},
    {name: 'G13'},
    {name: 'G14'},
    {name: 'G15'},
    {name: 'G16'},
  ];
  private matchIdGroup = [];
  public AddToBSlipEvent: EventEmitter<any>;
  public RemoveSelection: EventEmitter<any>;
  public SelectSelection: EventEmitter<any>;
  public MaxOdds = 1;
  public TotalStake = 0;
  public MaxPosPayout = 0;
  public NumberOfBets = 0;

  constructor(
    private zone: NgZone,
    private core: CoreService,
    private http: RequestsService,
    private settings: Settings
  ) {
    this.RemoveSelection = new EventEmitter();
    this.SelectSelection = new EventEmitter();
    this.AddToBSlipEvent = new EventEmitter();


    this.settings.signalREvent.subscribe((e1) => {
      this.settings.sportConn.hubProxy.on('OddChange', (e) => {
        this.oddChanged(e);
      });

      this.settings.sportConn.hubProxy.on('closeSportEntity', (e) => {

        if (e.Change.Type === closeSportTypes.Match) {
          this.gameEnd(e.EventId);
        }

      });

      this.settings.sportConn.hubProxy.on('SuspendMatch', (e) => {
        this.gameEnd(e.EventId);
      });
      if (localStorage.getItem('BetSlipList')) {

        this.checkBetSlip(JSON.parse(localStorage.getItem('BetSlipList')));
        /*this.groupMatches();
        this.getMaxOds();*/
      }
    });
  }

  private gameEnd(EventId) {
    _.filter(this.BetSlipList, (betItem) => {
      if (Number(betItem.matchId) === Number(EventId)) {
        betItem.blockedMarket = true;
        this.zone.run(() => {
          //  this.remove(betItem.matchId, betItem.marketId, betItem.selectionId);
        });
      }
    });
  }

  oddChanged(evnt) {
    if (this.BetSlipList.length) {
      _.find(this.BetSlipList, (betItem, index) => {
        if (betItem.matchId == evnt.EventId && betItem.marketId == evnt.Change.f) {
          this.zone.run(() => {
            if (evnt.Change.MarketChanges && evnt.Change.MarketChanges.length) {
              evnt.Change.MarketChanges.forEach((item, key) => {
                if (item.Key === 'Show') {
                  betItem.blockedMarket = item.Value == '1' ? false : true;
                  if (betItem.blockedMarket) {
                    betItem.isBanker = false;
                    betItem.group = '';
                    this.groupMatches();
                    /// @TODO
                    // this.remove(betItem.matchId, betItem.marketId, betItem.selectionId);
                  }
                }
              });
            }
            evnt.Change.SelectionChanges.forEach((s, sI) => {
              if (s.Change) {
                if (betItem.selection.c === s.Id) {
                  s.Change.forEach((sIt, sInd) => {
                    if (sIt.Key === 'Odd' && sIt.Value !== betItem.selection.Odd) {
                      if (sIt.Value > betItem.selection.Odd) {
                        this.core.setTimeoutToSelection(betItem.selection, 'Up', sIt.Value);
                      }
                      if (sIt.Value < betItem.selection.Odd) {
                        this.core.setTimeoutToSelection(betItem.selection, 'Down', sIt.Value);
                      }
                    }

                    if (sIt.Key === 'e') {
                      betItem.selection.e = Number(sIt.Value);
                      if (sIt.value !== '0') {
                        betItem.isBanker = false;
                        betItem.group = '';
                        this.groupMatches();
                        /// @TODO
                        // this.remove(betItem.matchId, betItem.marketId, betItem.selectionId);
                      }
                    }
                  });
                }
              }
            });
            localStorage.setItem('BetSlipList', JSON.stringify(this.BetSlipList));
          });
        }
      });
    }
  }

  addToBetSlip(data) {
    data.sportId = Number(localStorage.getItem('activeSportId'));
    const index = _.findLastIndex(this.BetSlipList, {matchId: data.matchId, marketId: data.marketId, selectionId: data.selectionId});
    if (index < 0) {
      data.isBanker = false;
      data.group = '';
      if (this.matchIds.indexOf(data.matchId) < 0) {
        this.matchIds.push(data.matchId);
      }
      this.BetSlipList.push(data);
      localStorage.setItem('BetSlipList', JSON.stringify(this.BetSlipList));
      this.groupMatches();
      /*this.addToVariants(this.BetSlipList);*/
      this.AddToBSlipEvent.emit(data);
      data.selection.selected = true;
      this.getMaxOds();
    } else {
      this.BetSlipList.splice(index, 1);
      localStorage.setItem('BetSlipList', JSON.stringify(this.BetSlipList));
      this.groupMatches();
      this.RemoveSelection.emit({matchId: data.matchId, marketId: data.marketId, selectionId: data.selectionId});
      data.selection.selected = false;
      this.getMaxOds();
    }
  }

  checkBetSlip(z?) {
    const list = JSON.parse(localStorage.getItem('BetSlipList'));

    if (list.length) {
      list.forEach((item, i) => {
        item.BSId = i + 1;
      });
      this.matchIdGroup = _.groupBy(list, (match: any) => {
        return match.sportId + ':' + match.matchId;
      });

      if (this.checkValidBetSlip()) {
        this.http.httpPost(this.settings.apiUrls.checkbetsleep, this.matchIdGroup).subscribe((e: any) => {
          let count = 0;
          for (const key in e) {
            if (e[key].length) {
              e[key].forEach((item, index) => {
                count++;
                if (item.blockedMarket || item.selection.e == 1){
                  item.isBanker = false;
                  item.group = '';
                }
                this.BetSlipList.push(item);
                this.AddToBSlipEvent.emit(item);
              });
            }
          }
          this.groupMatches();
          this.getMaxOds();
        });
      }


      /*  this.groupMatches();
        this.getMaxOds();*/
    }
  }

  copyLastBets(list) {
    if (this.BetSlipList.length) {
      return false;
    }
    if (list.length) {
      list.forEach((item, i) => {
        item.BSId = i + 1;
      });
      this.matchIdGroup = _.groupBy(list, (match: any) => {
        return match.sportId + ':' + match.matchId;
      });

      if (this.checkValidBetSlip()) {
        this.http.httpPost(this.settings.apiUrls.checkbetsleep, this.matchIdGroup).subscribe((e: any) => {
          let count = 0;
          for (const key in e) {
            if (e[key].length) {
              e[key].forEach((item, index) => {
                count++;
                this.BetSlipList.push(item);
                this.AddToBSlipEvent.emit(item);
              });
            }
          }
          if (!count) {
            this.settings.lastBetObj.betObj = {};
            localStorage.removeItem('lastBetObj');
          }
          this.groupMatches();
          this.getMaxOds();
        });
      }


      /*  this.groupMatches();
        this.getMaxOds();*/
    }
  }

  public getMaxOds() {
    this.MaxOdds = 1;
    this.BetSlipList.forEach((item, ind) => {
      this.MaxOdds *= item.selection.Odd;
    });
  }

  remove(matchId, marketId, selectionId) {
    const index = _.findLastIndex(this.BetSlipList, {matchId, marketId, selectionId});
    this.TotalStake = 0;
    this.BetSlipList.splice(index, 1);
    localStorage.setItem('BetSlipList', JSON.stringify(this.BetSlipList));
    this.RemoveSelection.emit({matchId, marketId, selectionId});
    this.groupMatches();
  }

  groupMatches() {
    this.BetSlipList.forEach((item, i) => {
      item.BSId = i + 1;
    });

    const activeOdds = _.filter(this.BetSlipList, (item: any) => {
    //  if (!item.blockedMarket) {
        return item;
    //  }
    });
    console.log(activeOdds);
    this.matchIdGroup = _.groupBy(activeOdds, (match: any) => {
      return match.matchId;
    });

    if (this.checkValidBetSlip()) {
      /* if (Object.keys(this.matchIdGroup).length == this.BetSlipList.length) {
         return this.VariantsList = [];
       }*/
      this.http.httpPost(this.settings.apiUrls.Getvariants, this.matchIdGroup).subscribe((e: any) => {
        this.VariantsList = e;
        this.TotalStake = 0;
        if (!this.ShowSystem) {
          this.VariantsList.forEach((item, ind) => {
            if (item.count === 1) {
              item.hidden = true;
              this.BetSlipList.forEach((bsItem, bsI) => {
                if (bsItem.amount) {
                  item.Variants.forEach((variantItem, variantIndex) => {
                    if (variantItem.variant == bsItem.BSId) {
                      variantItem.betAmount = bsItem.amount;
                    }
                  });
                }
              });
            }
            if (ind + 1 < this.VariantsList.length) {
              item.hidden = true;
            }
          });
        }
        this.betsCount();
      });
    }
  }

  checkValidBetSlip(): boolean {
    this.VariantsList = [];
    if (!this.bankerValidation()) {
      return false;
    }
    if (!this.groupValidation()) {
      return false;
    }
    return true;
  }

  groupValidation(): boolean {
    let hasError_986: boolean;
    let hasError_981: boolean;
    let hasGroup = false;
    this.VariantsList = [];
    this.BetSlipList.forEach((bet, bInd) => {
      if (!bet.group && !bet.isBanker && hasGroup) {
        return hasError_986 = true;
      }
      if (bet.group) {
        if (bInd > 0 && bet.group && !hasGroup && !hasError_986) {
          return hasError_986 = true;
        }
        _.find(this.BetSlipList, (item) => {
          if (item.matchId === bet.matchId && item.selection.Id !== bet.selection.Id && bet.group && bet.group === item.group) {
            return hasError_981 = true;
          }
        });
        hasGroup = true;
      }

    });

    const err1 = this.addDeleteError(hasError_986, 986, 'BetslipComputeError');

    const err2 = this.addDeleteError(hasError_981, 981, 'BetslipComputeError');
    if (err1 && err2) {
      return true;
    }

    return false;
  }

  bankerValidation(): boolean {
    let hasError: boolean;
    this.BetSlipList.forEach((bet, bInd) => {
      _.find(this.BetSlipList, (item) => {
        if (item.matchId === bet.matchId && item.selection.Id !== bet.selection.Id && bet.isBanker && item.isBanker) {
          return hasError = true;
        }
      });
    });

    return this.addDeleteError(hasError, 984, 'BetslipComputeError');
  }

  addDeleteError(hasError, code, cateName): boolean {
    if (hasError) {
      const errorIndex = _.findLastIndex(this.notification[cateName], {code});
      if (errorIndex < 0) {
        this.notification[cateName].push(this.notificationList[code]);
      }

      return false;
    } else {
      const errorIndex = _.findLastIndex(this.notification[cateName], {code});
      if (errorIndex > -1) {
        this.notification[cateName].splice(errorIndex, 1);
      }

      return true;

    }
  }

  betsCount(type?: string) {
    let totaltWin = 0;
    let betCount = 0;
    this.TotalStake = 0;
    this.VariantsList.forEach((vGroup, vGInd) => {
      if (!vGroup.betAmount) {
        vGroup.Variants.forEach((variant, vInd) => {
          if (/*vGroup.count === 1 &&*/ variant.betAmount) {
            vGroup.betAmount = (vGroup.betAmount ? vGroup.betAmount : 0) + Number(variant.betAmount);
          }
        });
      }
      if (vGroup.betAmount) {
        vGroup.Variants.forEach((variant, vInd) => {
          if (variant.betAmount) {
            betCount += 1;
            totaltWin += Number(Number(Number(variant.totalOdd) * Number(variant.betAmount)).toFixed(2));
          }
        });
        if (vGroup.count === 1) {
          vGroup.betAmount = this.toFix2(vGroup.betAmount);
        }
        this.TotalStake += Number(vGroup.betAmount);
      }

    });
    this.TotalStake = this.toFix2(this.TotalStake);
    this.NumberOfBets = this.toFix2(betCount);
    this.MaxPosPayout = this.toFix2(totaltWin);
  }

  toFix2(val) {
    if (!isNaN(val)) {
      return Number(Number(val).toFixed(2));
    } else {
      return 0;
    }
  }


}

