import {Component, OnInit} from '@angular/core';
import {Settings} from '../../services/settings';
import {RequestsService} from '../../services/requests/requests.service';
import {CoreService} from '../../services/core.service';

@Component({
  selector: 'app-todays-print',
  templateUrl: './todays-print.component.html',
/*  encapsulation: 1*/
})

export class TodaysPrintComponent implements OnInit {
  public printData: any;

  constructor(
    public settings: Settings,
    public req: RequestsService,
    public core: CoreService
  ) {

  }

  ngOnInit() {
  }

  getPrintData(fiter) {
    this.printData = {};
    this.req.httpPost(this.settings.apiUrls.reports.ExportTodaysCash, fiter).subscribe((e: any) => {
      if (!e.HasError) {
        this.printData = e.Data;
        setTimeout(() => {
          this.core.printElementById('asdfg', '/assets/styles/print/todays-print.css');
        }, 1000);
      }
    });
  }
}
