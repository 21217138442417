
<div class="mainmenu" id="menu" [ngClass]="{'in': settings.isMainMenuOpened}">
  <ul class="menu-list" style="display: block;">
    <li>
      <img class="menu-logo" src="/assets/images/shopLogo.png">
    </li>
    <li>
      <div class="user">
        <div class="user-image">
          <i class="fa fa-user-secret"></i>
        </div>
        <div class="user-id">
          <div class="txt">
            {{settings.cashierData.CashierName}}
          </div>

          <div class="reload-site" onclick="location.reload()">
            <i class="fa fa-refresh"></i> <span>{{'Reload shop' | translate}}</span>
          </div>

          <!-- TODO: Implement-->
          <div class="shift" style="display:none">
            <div class="fa fa-clock-o second-color" title="Shift duration:">
              <span class="contrast">03:15</span>
            </div>
            <a href="/login" class="btn btn-shift" title="End shift">
              <i class="fa fa-power-off primary-color"></i> {{'End shift' | translate}}
            </a>
          </div>
          <!-- /TODO-->
        </div>
        <div class="clearfix"></div>
      </div>
    </li>
    <li class="item_all_events selected" style="cursor: pointer">
      <a class="menu-link" href="/sport" title="{{'All events' | translate}}">{{'All events' | translate}}</a>
    </li>

    <li class="item_bet_approvals" style="cursor: pointer"  *ngIf="!settings.guestMode">
      <a class="menu-link" title="{{'Bet approvals' | translate}}" routerLink="/management/bet-approval">{{'Bet approvals' | translate}}</a>
    </li>
    <li class="item_bet_approvals" style="cursor: pointer" *ngIf="!settings.guestMode">
      <a class="menu-link" title="{{'Reports' | translate}}" routerLink="/management/reports">{{'Reports' | translate}}</a>
    </li>

    <li class="item_bet_approvals" style="cursor: pointer"  *ngIf="!settings.guestMode">
      <a class="menu-link" title="{{'Announcements.' | translate}}" [routerLink]="['/management/announcements']">{{'Announcements.' | translate}}</a>
    </li>

    <li class="item_bet_approvals" style="cursor: pointer" *ngIf="!settings.guestMode" >
      <a class="menu-link" title="{{'Bet Codes' | translate}}" [routerLink]="['/management/bet-codes']">{{'Bet Codes' | translate}}</a>
    </li>

    <li class="item_bet_approvals" style="cursor: pointer"  *ngIf="!settings.guestMode">
      <a class="menu-link" title="{{'Settings' | translate}}" [routerLink]="['/management/settings/general']">{{'Settings' | translate}}</a>
    </li>

    <li class="hr"></li>
    <li  *ngIf="!settings.guestMode">
      <div class="txt" style="display: block;">{{'Amount of Last Slips' | translate}} ({{settings.betsInfo.BetsCount}}):</div>
      <div class="txt">
        <div class="amount">
          <span class="primary-color" style="text-align: right;">€{{settings.betsInfo.BetsAmount | number:'1.2'}}</span>
        </div>
        <div class="text-right">
          <a class="btn btn-shop-second-color" title="{{'Reset' | translate}}" (click)="resetBetsInfo()">{{'Reset' | translate}}</a>
        </div>
      </div>
    </li>
    <li class="hr"></li>
    <li class="text-center">
      <div class="btn-logout">
        <a class="btn btn-shop-second-color fullwidth btn-large-custom" (click)="logout()">
          <i class="fa fa-power-off"></i>&nbsp; {{'LOGOUT' | translate}}
        </a>
      </div>
    </li>
    <li class="clearfix"></li>
  </ul>
</div>
