import {Component, OnInit} from '@angular/core';
import * as tymeZones from '../../../../core/enums/country';
import * as consts from '../../../../core/enums/consts';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Settings} from '../../../../core/services/settings';
import {RequestsService} from '../../../../core/services/requests/requests.service';

@Component({
  selector: 'general-settings',
  templateUrl: './general.component.html'
})

export class GeneralComponent implements OnInit {
  public isSuccess = false;
  public timeZone = tymeZones.tymeZones;
  public languages = consts.Languages;
  public valForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private settings: Settings,
    private http: RequestsService
  ) {
    this.valForm = this.fb.group({
      TimeZone: [null],
      Language: [null],
    });
  }

  ngOnInit() {
    this.http.httpGet(this.settings.apiUrls.GetGeneralSettings)
      .subscribe((data: any) => {
        if (!data.HasError) {
          this.valForm.patchValue({
            TimeZone: String(data.Data.TimeZone),
            Language: String(data.Data.Language),
          });
        }
      });
  }


  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (const c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      const obj = JSON.parse(JSON.stringify(value));
      this.isSuccess = false;
      this.http.httpPost(this.settings.apiUrls.UpdateGeneralSettings, value)
        .subscribe((e: any) => {
          if (!e.HasError) {
            this.settings.cashierData.TimeZoneId = value.TimeZone;
            this.settings.cashierData.LanguageId = value.Language;
            localStorage.setItem('timeZone', value.TimeZone);
            localStorage.setItem('cashierData', JSON.stringify(this.settings.cashierData));

            localStorage.setItem('lang', value.Language);
            this.isSuccess = true;
            setTimeout(() => {
              location.reload();
            }, 300);
          }
        });
    }
  }

  removeAll() {
    localStorage.clear();
    location.reload();
  }
}
