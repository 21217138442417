import {NgModule} from '@angular/core';
import {LoginComponent} from './pages/login/login.component';
import {RouterModule} from '@angular/router';
import {routes} from './routes';
import {SportsModule} from './sports/sports.module';
import {CommonModule} from '@angular/common';
import {RouteComponent} from './route.component';
import {CoreModule} from '../core/core.module';
import {ManagementModule} from './management/management.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BetInfoComponent} from './betInfo/betInfo.component';
import {TranslatorService} from '../core/services/translator/translator.service';

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    SportsModule,
    CommonModule,
    CoreModule,
    ManagementModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    LoginComponent,
    RouteComponent,
    BetInfoComponent
  ]
})

export class RoutesModule {
  constructor(tr: TranslatorService) {

  }
}
