import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {Settings} from '../../core/services/settings';
import {RequestsService} from '../../core/services/requests/requests.service';
import * as enums from '../../core/enums/consts';
import {Options} from 'ng5-slider';
import {ActivatedRoute} from '@angular/router';
import {ChannelService} from '../../core/services/signalr/signalr.service';

declare var _: any;

@Component({
  selector: 'app-betinfo',
  templateUrl: './betInfo.component.html',
  styleUrls: ['./bet-info.css']
})

export class BetInfoComponent implements OnInit {
  public betslipData: any;
  public showMatchesResults = false;
  public cashoutAmount = 0;
  public b: any;
  public betId: any;
  public hash: any;
  public pinOfQS: any;
  public t: any;
  public signalR = new ChannelService();
  public options: Options = {
    floor: 0,
    disabled: true,
    ceil: 0
  };
  public showPayInput = false;
  public pin = null;
  public BetStatesEnum = enums.BetStatesEnum;
  public notify = {
    type: '',
    code: 0
  };
  public objectKeys = Object.keys;
  public matchIds = [];

  constructor(
    public settings: Settings,
    public route: ActivatedRoute,
    private http: RequestsService,
    private zone: NgZone
  ) {
    this.route.queryParams.subscribe((qs) => {
      this.b = qs.b;
      this.t = qs.t;
      this.betId = qs.betId;
      this.hash = qs.hash;
      this.pinOfQS = qs.pin;
      this.http.httpGet(`${this.settings.sigRUrl}api/betsleep/get?betId=${qs.betId}&pin=${qs.pin}&b=${qs.b}&t=${qs.t}&hash=${qs.hash}`)
        .subscribe((res: any) => {
          if (!res.HasError) {
            this.betslipData = res.Data;
            this.betslipData.SystemSelections.forEach((item, ind) => {
              item.SelectionStake = JSON.parse(item.SelectionStake);
            });
            if (this.betslipData.CashOutAmount > 0) {
              this.options.ceil = this.betslipData.CashOutAmount;
              this.cashoutAmount = this.betslipData.HasPartialCashout ? Number(this.betslipData.CashOutAmount / 2) : this.betslipData.CashOutAmount;
              this.options.disabled = this.betslipData.HasPartialCashout ? false : true;
            }
            this.betslipData.Selections.forEach((item, key) => {
              if (this.matchIds.indexOf(item.EventId) < 0) {
                this.matchIds.push(item.EventId);
              }
            });

            this.signalR.init(this.settings.sigRUrl + 'signalr', 'RetailOddsHub', this.b, this.t);
            this.signalR.starting$.subscribe((e1) => {
              this.signalR.hubProxy.invoke('SubscribersubscribeMathces', {EventIds: this.matchIds});
            });
            this.signalR.hubProxy.on('OddChange', (ev) => {
              this.oddChanged(ev);
            });
            this.signalR.start();
            this.getBetslip();
          }
        });
    });

    this.signalR.starting$.subscribe((ev1) => {
      this.signalR.hubProxy.on('CashoutResult', (ev) => {
        if (ev.Data && !ev.HasError) {
          this.betslipData.State = 12;
          this.betslipData.CashOutAmount = 0;
          this.betslipData.Payout = this.cashoutAmount;
        }
      });
    });
  }

  getBetslip() {
    setInterval(() => {
      this.http.httpGet(`${this.settings.sigRUrl}api/betsleep/get?betId=${this.betId}&pin=${this.pinOfQS}&b=${this.b}&t=${this.t}&hash=${this.hash}`)
        .subscribe((res: any) => {
          if (res.Data) {
            this.betslipData.CashOutAmount = res.Data.CashOutAmount;
            this.betslipData.HasCashout = res.Data.HasCashout;
            this.betslipData.HasPartialCashout = res.Data.HasPartialCashout;
            if (this.betslipData.CashOutAmount > 0) {
              this.options.ceil = this.betslipData.CashOutAmount;
              this.cashoutAmount = this.betslipData.HasPartialCashout ? Number(this.betslipData.CashOutAmount / 2) : this.betslipData.CashOutAmount;
              this.options.disabled = this.betslipData.HasPartialCashout ? false : true;
            }
          }
        });
    }, 1000);

  }

  ngOnInit() {
    this.pin = '';
    this.showPayInput = false;
    this.notify = {
      type: '',
      code: 0
    };


  }

  clearError() {
    this.notify = {
      type: '',
      code: 0
    };
  }

  setSuccess() {

  }

  toCashout() {
    if (!this.showPayInput) {
      return this.showPayInput = true;
    }

    this.signalR.hubProxy.invoke('cashout', {
      BetSleepId: this.betslipData.Id,
      ShopId: localStorage.getItem('shopId'),
      ShopToken: localStorage.getItem('shoptoken'),
      Pin: this.pin,
      Amount: this.options.ceil === this.cashoutAmount ? this.options.ceil : null,
      PartialAmount: this.options.ceil !== this.cashoutAmount ? this.cashoutAmount : null,
    });

    /* if (this.options.ceil > this.cashoutAmount && this.cashoutAmount > 0) {
       this.http.httpPost(this.settings.apiUrls.PartialCashout, {
         BetSleepId: this.betslipData.Id,
         Pin: this.pin,
         Amount: this.options.ceil,
         PartialAmount: this.cashoutAmount
       }).subscribe((res: any) => {
         if (!res.Data && !res.HasError) {
           this.betslipData.State = 12;
           this.betslipData.CashOutAmount = 0;
           this.betslipData.Payout = this.cashoutAmount;
         }
       });
     }*/

  }

  private oddChanged(evnt) {
    if (this.betslipData.Selections.length) {
      _.find(this.betslipData.Selections, (match) => {
        if (match.EventId == evnt.EventId) {
          if (match.f == evnt.Change.f) {
            if (evnt.Change.MarketChanges.length) {
              evnt.Change.MarketChanges.forEach((item, key) => {
                match[item.Key] = item.Value;
              });
            }
            evnt.Change.SelectionChanges.forEach((s, sI) => {
              if (s.Change) {
                if (match.SelectionId === s.SelectionId) {
                  s.Change.forEach((sIt, sInd) => {
                    if (sIt.Key === 'Odd' && sIt.Value !== match.Odd) {
                      match.NewOdd = sIt.Value;
                    }

                    if (sIt.Key === 'State') {
                      match.State = sIt.Value;
                    }
                  });
                }
              }
            });
          }
        }
      });
    }
  }
}
