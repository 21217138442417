<div class="tab-content">
  <div class="row tab-pane active">
    <div class="col-xs-12">
      <div class="box">
        <div class="box-header">
          <h2>
            <i class="fa fa-cog"></i>
            <span class="break"></span>
          </h2>
        </div>

        <div class="box-content">
          <div class="dashsett">
            <div class="row">
              <form [formGroup]="valForm" (submit)="submitForm($event, valForm.value)" novalidate="">
                <!-- START panel-->
                    <div class="form-group ">
                      <div class="col-lg-3 offset-bottom10">
                        <div  class="input-group col-xs-12 custom-input">
                          <div class=" col-form-label text-lg-right">{{'Timezone' | translate}}</div>
                          <select class="form-control fullwidth input-md" formControlName="TimeZone" >
                            <option [value]="country.Id" *ngFor="let country of timeZone">{{country.Name}} (UTC {{country.GMT}})</option>
                          </select>
                          <div class="text-danger" *ngIf="valForm.controls['TimeZone'].hasError('required') && (valForm.controls['TimeZone'].dirty || valForm.controls['TimeZone'].touched)">This field is required</div>
                        </div>

                      </div>
                      <div class="col-lg-3 offset-bottom10">
                        <div  class="input-group col-xs-12 custom-input">
                          <div class=" col-form-label text-lg-right">{{'Language' | translate}}</div>
                          <select class="form-control fullwidth input-md" formControlName="Language" >
                            <option [value]="lang.code" *ngFor="let lang of languages">{{lang.text}}</option>
                          </select>
                          <div class="text-danger" *ngIf="valForm.controls['Language'].hasError('required') && (valForm.controls['Language'].dirty || valForm.controls['Language'].touched)">
                            {{'This field is required' | translate}}
                          </div>
                        </div>

                      </div>
                    </div>
                <div class="">
                  <div class="col-xs-12">
                    <button class="btn btn-shop-primary-color btn-auto" type="submit">{{'SAVE OPTIONS' | translate}}</button>
                    <div class="btn pull-right btn-shop-second-color btn-auto" (click)="removeAll()">
                      <i class="fa fa-remove"></i>{{'Clear settings (reloads page)' | translate}}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="error_message error-effect-jelly confirmation_message" style="display: none">
              <div class="error_message_icon_close clickable">
                <i class="fa fa-times"></i>
              </div>
              <div class="error_message_text clickable">
                <span class="highlighted">{{'Success' | translate}}!</span>
                <span>{{'Settings Saved' | translate}}</span>
                <i class="fa fa-exclamation"></i>
              </div>
              <div class="error-success-progress"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>


<div class="success_message">
  <div class="error_message error-effect-jelly confirmation_message"  [ngStyle]="{display: isSuccess ? 'block': 'none'}">
    <div class="error_message_icon_close clickable">
      <i class="fa fa-times"></i>
    </div>
    <div class="error_message_text clickable">
      <span class="highlighted">{{'Success' | translate}}!</span>
      <span>{{'Settings Saved' | translate}}</span>
      <i class="fa fa-exclamation"></i>
    </div>
    <div class="error-success-progress"></div>
  </div>
</div>
