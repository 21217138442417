<div id="modalWindow" tabindex="-1" role="dialog" aria-labelledby="modal-windowLabel" style="display: block;"
     class="modal betslip-modal">
  <div class="modal-dialog" role="document">

    <div class="modal-content">
      <button type="button" class="close" (click)="closeModal()"><i class="fa fa-times"></i></button>

      <div class="full_window betslip-modal not_in_print" style="position:relative; width:100%; height:100%;">
        <div id="modal_header" class="modal-header">
          <div class="modal-title">
            <span>{{'Bar Code' | translate}}: {{betslipData.BarCode}}</span>
            <span>{{'Placed on' | translate}}: </span>
            <span
              title="{{betslipData.Date | date: 'dd MMM yyyy HH:MM'}}"> {{betslipData.Date | date: 'dd MMM yyyy HH:MM'}}</span>
          </div>
        </div>
        <div class="body myaccount_betslip">
          <div class="betslip_status Won" *ngIf="betslipData.State == BetStatesEnum.Won || betslipData.State == BetStatesEnum.Cashout || betslipData.State == BetStatesEnum.Paid">
            <div class="betslip_status_notification">
              <div *ngIf="betslipData.State == BetStatesEnum.Won">{{'Bet Won' | translate}} ({{betslipData.Payout}})</div>
              <div *ngIf="betslipData.State == BetStatesEnum.Cashout">{{'Cashouted' | translate}} ({{betslipData.Payout}})</div>
              <div *ngIf="betslipData.State == BetStatesEnum.Paid">{{'Paid' | translate}} ({{betslipData.Payout}})</div>
            </div>
          </div>
          <div class="betslip_status Lost" *ngIf="betslipData.State == BetStatesEnum.Lost">
            <div class="betslip_status_notification">
              <div>{{'Bet Lost' | translate}}</div>
            </div>
          </div>
          <div class="betslip_status Lost" *ngIf="betslipData.State == BetStatesEnum.Cancelled">
            <div class="betslip_status_notification">
              <div>{{'Canceled' | translate}}</div>
            </div>
          </div>
          <!--<div class="betslip_header">
            <div class="betslip_header_item_foreground z1 ztop">
              <span class="betslip_header_item_text">Betslip</span>
            </div>
          </div>-->

          <div id="betslip_body" class="betslip-body">
            <div class="betslip_tab_body height100">
              <div class="height100 boxed">
                <div class="betslip_box height100">
                  <div class="betslip_selection_header">
                    <div class="left">
                      <div>{{'Selections' | translate}}:</div>
                    </div>
                    <div class="right">
                      <div class="your_odds">{{'Odds' | translate}}</div>
                      <div class="your_stake">{{'Stake' | translate}}</div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="modal_window betslip_scrollbar_container accumulator-odds-enabled bonus-winnings-enabled">
                    <ul id="betslip_selections" class="Lost">
                      <li class="betslip_selection" *ngFor="let selection of betslipData.Selections">
                        <div class="betslip_selection_first_line">
                          <div class="betslip_selection_first_line_number">{{selection.Index}}.</div>
                          <div class="betslip_selection_first_line_market">
                            <div class="betslip_selection_first_line_market_row1">
                              <div class="betslip_selection_first_line_category"
                                   title="{{selection.Region}} - {{selection.Competition}}">
                                {{selection.Region}} - {{selection.Competition}}
                              </div>
                              <div class="betslip_selection_first_line_" style="float: left;"> -</div>
                              <div
                                class="betslip_selection_first_line_date">{{selection.StartDate | date:'dd/mm HH:MM'}}
                              </div>
                            </div>
                            <div class="betslip_selection_first_line_market_row2">
                              <div class="betslip_selection_first_line_market_row2_marketName"
                                   style="width: calc(100% - -7px);">
                                {{selection.EventId}} <span *ngIf="selection.HomeName">{{selection.HomeName}} v {{selection.AwayName}}</span>
                                <span *ngIf="selection.EventName && !selection.HomeName">{{selection.EventName}}</span>
                              </div>

                            </div>
                          </div>
                          <div class="clearboth"></div>
                        </div>

                        <div class="betslip_selection_second_line">
                          <div>
                            <div class="disabled betslip_selection_second_line_banker" *ngIf="selection.IsBanker">
                              B
                            </div>
                          </div>
                          <div class="betslip_selection_second_line_status" style="display: flex;width: 240px">
                            <div class="betslip_selection_second_line_status won"
                                 *ngIf="selection.SelectionState == SelectionStatesEnum.Won">
                              WON
                            </div>
                            <div class="betslip_selection_second_line_status lost"
                                 *ngIf="selection.SelectionState == SelectionStatesEnum.Lost">
                              Lost
                            </div>
                            <div class="betslip_selection_second_line_status won"
                                 *ngIf="selection.VoidFactor == 0.5 || selection.VoidFactor == 1">
                              {{selection.VoidFactor == 0.5 ? 'Partial Refund' : 'Refund'}}
                            </div>
                          </div>
                          <div
                            class="betslip_selection_second_line_your_odds pull-left"> {{showMatchesResults ? selection.HFName + ' ' + selection.Time + '\'' + ' ' + selection.Score : ''}} </div>
                          <div class="betslip_selection_second_line_your_stake" style="display: none;"></div>
                          <div class="betslip_selection_second_line_your_odds"
                               style="margin-right: 46px;">{{selection.Odds}}</div>
                          <div class="clearboth"></div>
                        </div>

                        <div class="betslip_selection_third_line">
                          <div class="betslip_selection_second_line_group_empty"></div>
                          <div class="betslip_selection_second_line_event padded">
                            <span title="{{selection.MarketName}}: {{selection.SelectionName}}">{{selection.MarketName}}
                              : {{selection.SelectionName}}</span>
                          </div>
                          <div class="clearboth"></div>

                        </div>

                        <div class="right" style="display: none;">
                          <div class="your_odds">1.39</div>
                          <div class="your_stake" style="display: none;"></div>
                          <div class="max_payout" style="display: none;"></div>
                        </div>
                      </li>

                    </ul>
                    <ul class="betslip_systems">
                      <ng-template let-variant ngFor [ngForOf]="betslipData.SystemSelections">
                        <li class="betslip_system 1" (click)="variant.IsOpen = !variant.IsOpen">

                          <div class="betslip_system_row1">
                            <div class="clickable expander" style="position: absolute;"></div>
                            <div class="betslip_system_name clickable">{{variant.VariantName}}</div>
                          </div>
                          <div class="betslip_system_row2">
                            <div class="float_left betslip_system_details_lines">
                              {{variant.VariantSelectionCount}} {{'LINES' | translate}}
                              <span
                                class="betslip_systems_multiplier_button">X</span></div>
                            <div class="betslip_system_details_odds">{{variant.TotalOdds}}</div>
                            <div class="float_right betslip_system_yourstake">€{{variant.TotalStake}}</div>
                          </div>
                          <div class="betslip_system_details" [ngStyle]="{display: variant.IsOpen ? 'block' : 'none'}">
                            <ul class="betslip_system_expanded_body">
                              <ng-template let-line ngFor [ngForOf]="objectKeys(variant.SelectionStake)">
                                <li class="betslip_system_expanded_item">
                                  <div class="betslip_system_expanded_item_selections checkbox checked"
                                       title="{{line}}">{{line}}
                                  </div>
                                  <div class="betslip_system_expanded_item_yourodds"></div>
                                  <div class="betslip_system_expanded_item_yourstake">
                                    €{{variant.SelectionStake[line]}}
                                  </div>
                                </li>
                              </ng-template>
                            </ul>
                          </div>
                        </li>
                      </ng-template>
                    </ul>
                  </div>
                  <div class="betslip_summary">
                    <div class="betslip_summary_row1 large_text">
                      <div class="clearboth"></div>
                      <div class="betslip_summary_text" style="float:left;">{{'TOTAL STAKE' | translate}}</div>
                      <div class="betslip_summary_value">{{betslipData.BetAmount}}</div>
                      <div class="clearboth"></div>
                    </div>
                    <div class="betslip_summary_row2">
                      <div>
                        <div class="betslip_summary_row1">
                          <div class="betslip_summary_text">
                            <span>{{'BETS' | translate}}:</span> &nbsp;<span>{{betslipData.BetsCount}}</span>
                          </div>
                        </div>
                        <div class="clearboth"></div>
                      </div>
                      <div class="betslip_summary_row1">
                        <div class="betslip_summary_text">{{'MAX. PAYOUT' | translate}}</div>
                        <div class="betslip_summary_value">€{{betslipData.MaxPayout}}</div>
                      </div>
                      <div class="clearboth"></div>
                      <div class="clearboth"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="full-error-message"></div>
          </div>

        </div>

        <!--<div class="modal_footer betslip-summary">

          <div class="footer">

            <div class="btn btn-shop-primary-color btn-betslip">Print Betslip Copy</div>
            <div class="btn btn-shop-primary-color btn-betslip">Betslip Match Results</div>
            &lt;!&ndash;/ko&ndash;&gt;
          </div>
        </div>-->

        <div class="modal_footer betslip-summary">

          <div class="betslip-row insert-pin" style="display: flex;" *ngIf="showPayInput">
            <div class="betslip-txt">{{'Pin' | translate}}</div>
            <div class="pin_error">
              <div class="error_message error-effect-jelly"
                   [ngStyle]="{display: notify.type == 'error' ? 'block' : 'none'}"
                   [ngClass]="{confirmation_message: notify.type == 'success'}">
                <div class="error_message_icon_close clickable" (click)="clearError()">
                  <i class="fa fa-times"></i>
                </div>
                <div class="error_message_text clickable">
                  <span class="highlighted" *ngIf="notify.type == 'error' && notify.code">
                    <!--{{notify.msg ? notify.msg : ('Error: Incorrect Pin' | translate)}}-->
                    {{errorList[notify.code].title | translate}}
                  </span>
                  <span class="highlighted" *ngIf="notify.type == 'success'">{{'Success' | translate}}</span>
                  <i class="fa fa-exclamation"></i>
                </div>
                <div class="error-success-progress"></div>
              </div>
            </div>
            <div class="input_box pin_box">
              <div class="button close small" style="display: none;"></div>
              <div class="custom-input">
                <div class="input-group col-xs-input">
                  <input class="form-control input-md" type="text" placeholder="BetSleep Pin" (change)="pin = $event.target.value">
                </div>
              </div>
            </div>
          </div>

          <div class="footer">
            <div class="btn btn-shop-primary-color btn-betslip" (click)="pay()"
                 *ngIf="!settings.guestMode &&  (betslipData.State == BetStatesEnum.Won || betslipData.State == BetStatesEnum.Cashout )">
              {{'PAY' | translate}}
            </div>
            <div *ngIf="betslipData.CashOutAmount >0">
              <div style="width: calc(100% - 120px); float: left;display: flex;     align-items: center;">
                <div *ngIf="betslipData.HasPartialCashout &&  betslipData.State == BetStatesEnum.Pending">
                  <!--<ng5-slider [(value)]="cashoutAmount" [options]="options"></ng5-slider>-->
                  <div class="form-group">
                    <label>Max Cashout amount - {{options.ceil}} </label>
                    <input type="number" min="0" [max]="options.ceil" [value]="cashoutAmount" class="form-control" (change)="cashoutAmount = $event.target.value"
                           [attr.disabled]="options.disabled ? '': null">
                    <p class="error-effect-jelly" style="color: red" *ngIf="cashoutAmountError">
                      Wrong cashout amount
                    </p>
                  </div>
                </div>
                <div style="width: 120px; float: right">
                <div class="btn btn-shop-primary-color " [ngClass]="{'disabled' : activeCashoutRequest}"
                     *ngIf="betslipData.HasCashout && betslipData.State == BetStatesEnum.Pending" (click)="toCashout()" >
                  {{'Cashout' | translate}}
                </div>
              </div>
              </div>

            </div>
            <div class="btn btn-shop-primary-color btn-betslip" (click)="showMatchesResults = true">
              {{'Betslip Match Results' | translate}}
            </div>

            <!--<div class="cancel-bet input-group" *ngIf="betslipData.State == BetStatesEnum.Pending && showCancelBtn">

              <button type="button" (click)="cancelBet()" class="btn btn-danger">Cancel</button>
            </div>-->
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

