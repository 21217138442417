<div class="tab-content">
  <div class="row">
    <div class="col-xs-12">
      <!-- Tab panes -->
      <form [formGroup]="valForm"  class="form-validate form-horizontal" >

        <div class="row tab-content">
          <div class="col-xs-12">
            <div class="box">
              <div class="box-header">
                <h2>
                  <i class="fa fa-lock"></i>
                  <span class="break"></span>
                  {{'Change Password' | translate}}
                </h2>
              </div>
              <div class="box-content">
                <div class="dashsett">
                  <div class="row">
                    <div class="col-xs-12">
                      <div class="title">
                        {{'Change current password' | translate}}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-6 offset-top10 col-xs-pull-6 col-xs-offset-6">
                      <div class="custom-input primary-color">
                        <div class="input-group col-xs-12">
                          <div class="title" >{{'Current Password' | translate}}: </div>
                          <input type="password" class="form-control input-md" formControlName="OldPassword" placeholder="{{'Current Password' | translate}}">
                          <span class="text-danger" *ngIf="valForm.controls['OldPassword'].hasError('required') && (valForm.controls['OldPassword'].dirty || valForm.controls['OldPassword'].touched)">
                            {{'This field is required' | translate}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-6 offset-top10 col-xs-pull-6 col-xs-offset-6">
                      <div class="custom-input primary-color">
                        <div class="input-group col-xs-12">
                          <div class="title">{{'New Password' | translate}}: </div>
                          <input type="password" class="form-control input-md"  formControlName="NewPassword" placeholder="{{'New Password' | translate}}">
                          <span class="text-danger" *ngIf="valForm.controls['NewPassword'].hasError('required') && (valForm.controls['NewPassword'].dirty || valForm.controls['NewPassword'].touched)">
                            {{'This field is required' | translate}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-6 offset-top10 col-xs-pull-6 col-xs-offset-6">
                      <div class="custom-input primary-color">
                        <div class="input-group col-xs-12">
                          <div class="title">{{'Confirm New Password' | translate}}: </div>
                          <input type="password" class="form-control input-md"  formControlName="ConfirmPassword" placeholder="{{'Confirm New Password' | translate}}">
                          <span class="text-danger" *ngIf="valForm.controls['ConfirmPassword'].hasError('required') && (valForm.controls['ConfirmPassword'].dirty || valForm.controls['ConfirmPassword'].touched)">
                            {{'This field is required' | translate}}
                          </span>
                          <span class="text-danger" *ngIf="valForm.controls['ConfirmPassword'].value && valForm.controls['NewPassword'].value && valForm.controls['ConfirmPassword'].value  !== valForm.controls['NewPassword'].value">
                            {{'Password Mismatch' | translate}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-12">
                      <div class="footer-buttons">
                        <div (click)="changePass($event, valForm.value)" class="btn btn-shop-primary-color btn-auto" >
                          {{'SAVE CHANGES' | translate}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="devices_error">
      <div class="error_message error-effect-jelly"  [ngStyle]="{display: isError ? 'block': 'none'}">
      <div class="error_message_icon_close clickable">
        <i class="fa fa-times"></i>
      </div>
      <div class="error_message_text clickable">
        <i class="fa fa-exclamation"></i>
        <ng-template let-errlist ngFor [ngForOf]="ErrorList">
          <div *ngFor="let err of errlist">
            {{err | translate}}
          </div>
        </ng-template>

      </div>
      <div class="error-success-progress"></div>
      <!--<div class="error_message_close_button" data-bind="click: CloseButtonClick, clickBubble: false"></div>-->
    </div>
    </div>
  </div>
</div>


<div class="success_message">
  <div class="error_message error-effect-jelly confirmation_message"  [ngStyle]="{display: isSuccess ? 'block': 'none'}">
    <div class="error_message_icon_close clickable">
      <i class="fa fa-times"></i>
    </div>
    <div class="error_message_text clickable">
      <span class="highlighted">{{'Success' | translate}}!</span>
      <span>{{'Settings Saved' | translate}}</span>
      <i class="fa fa-exclamation"></i>
    </div>
    <div class="error-success-progress"></div>
  </div>
</div>
