import {Component, OnInit} from '@angular/core';
import {SportService} from '../../../../core/services/sport/sport.service';
import {CoreService} from '../../../../core/services/core.service';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'tree-component',
  templateUrl: './tree.component.html'
})

export class TreeComponent implements OnInit {
  public date = new Date();

  public gameFilter = '';
  public searchUpdate = new Subject<string>();

  constructor(
    public sportService: SportService,
    public core: CoreService,
  ) {

    this.searchUpdate.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(value => {
        this.getGameFilter(value);
      });

  }


  ngOnInit() {
    setInterval(() => {
      this.date = new Date();
    }, 1000);
  }

  changePage(page) {
    this.sportService.PageIndex = page;
    localStorage.setItem('PageIndex', String(this.sportService.PageIndex));
    this.sportService.GetMatchWithOdd();
  }

  nextPage() {
    if (this.sportService.PagesCount === this.sportService.PageIndex) {
      return false;
    }
    this.sportService.PageIndex++;
    localStorage.setItem('PageIndex', String(this.sportService.PageIndex));
    this.sportService.GetMatchWithOdd();
  }

  backPage() {
    if (this.sportService.PageIndex === 1) {
      return false;
    }
    this.sportService.PageIndex--;
    localStorage.setItem('PageIndex', String(this.sportService.PageIndex));
    this.sportService.GetMatchWithOdd();
  }

  endPage() {
    if (this.sportService.PagesCount === this.sportService.PageIndex) {
      return false;
    }
    this.sportService.PageIndex = this.sportService.PagesCount;
    localStorage.setItem('PageIndex', String(this.sportService.PageIndex));
    this.sportService.GetMatchWithOdd();
  }

  starPage() {
    if (this.sportService.PageIndex === 1) {
      return false;
    }
    this.sportService.PageIndex = 1;
    localStorage.setItem('PageIndex', String(this.sportService.PageIndex));
    this.sportService.GetMatchWithOdd();
  }

  getGameFilter( val ) {
    this.gameFilter = val;
    this.sportService.GetMatchWithOdd(val);
  }
}
