import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {catchError, map, tap,} from 'rxjs/operators';
import {of, Observable, throwError} from 'rxjs';
import {Settings} from '../settings';
import {ErrorList} from '../../enums/errors';

declare var localStorage: any;
@Injectable({
  providedIn: 'root',
})

export class RequestsService {
  public errorList = ErrorList;

  constructor(
    private http: HttpClient,
    private settings: Settings,
  ) {
  }

  httpGet(url) {
    return this.http.get(url, this.userActiveHeaders()).pipe(
      catchError(this.handleError)
    );

  }

  httpPost(url, body) {
    return this.http.post(url, body, this.userActiveHeaders())
      .pipe(map((resp: Response) => {
          if (resp['HasError']) {
            if (this.errorList[resp['ErrorCode']]) {
              this.settings.hasError.hasError = false;
              setTimeout(() => {
                this.settings.hasError.hasError = true;
                this.settings.hasError.mgs = this.errorList[resp['ErrorCode']].title;
              }, 200);
            }
          }
          return resp;
        }
        ),

        catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status == 401) {
      localStorage.removeItem('cashirToken');
      localStorage.removeItem('cashierData');
      localStorage.removeItem('cashirId');
      location.reload();
    }
    // return an ErrorObservable with a user-facing error message
    return throwError(error);
    /* return of(
       'Something bad happened; please try again later.');*/
  }

  userActiveHeaders() {
    let headers;
    headers = new HttpHeaders({
      'shop-id': localStorage.getItem('shopId') || '',
      lang: localStorage.getItem('lang') || '',
      con: this.settings.sportConn && this.settings.sportConn.connectionId ? this.settings.sportConn.connectionId : '',
      'shop-token': localStorage.getItem('shoptoken') || '',
      'cashier-id': localStorage.getItem('cashirId') || '',
      'cashier-token': !this.settings.guestMode ? localStorage.getItem('cashirToken') || '' : ''
    });
    return {headers};
  }
}
