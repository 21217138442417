<div class="tab-content">
  <div class="tab-pane active">
    <div>
      <div class="col-xs-left">
        <div class="box">
          <div class="box-header">
            <h2>
              <i class="fa fa-angle-right"></i>
              <span class="break">{{'Account Statement' | translate}}</span>
            </h2>

            <div style="display:none" class="box-icon">
              <a href="#" class="btn-minimize"><i class="fa fa-chevron-up"></i></a>
            </div>
          </div>
          <div class="box-content collapse in">
            <div class="filter">
              <div class="filter-header" (click)="openedFilter = !openedFilter" [ngClass]="{'open': openedFilter}">
                <i class="fa fa-filter"></i> {{'Filter' | translate}}
                <span class="fa "  [ngClass]="openedFilter ? 'fa-toggle-on' : 'fa-toggle-off'"></span>
              </div>
              <div class="filter-body collapse in" [ngStyle]="{display: openedFilter? 'block' : 'none'}">

                <div class="custom-input primary-color">
                  <div class='input-group date' id='datetimeFrom'>
                    <input type='text' class="form-control" />
                    <span class="input-group-addon">
                        <span class="glyphicon glyphicon-calendar"></span>
                    </span>
                  </div>
                </div>
                <div class="custom-input primary-color">
                  <div class="input-group date" id="datetimeTo">
                    <input type="text" class="form-control">
                    <span class="input-group-addon">
                      <span class="fa fa-calendar"></span>
                  </span>
                  </div>
                </div>
                <div class="amount-reset">
                  <a  class="btn btn-shop-primary-color"  (click)="getData()" title="{{'Filter' | translate}}">{{'Filter' | translate}}</a>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>

            <div class="reportsRecordsArea">
              <table class="table table-striped table-bordered">
                <thead>
                <tr>
                  <th>{{'Date' | translate}}</th>
                  <th>{{'S/N' | translate}}</th>
                  <th>{{'Transaction Details' | translate}}</th>
                  <th>{{'Amount' | translate}}</th>
                  <th>{{'BALANCE' | translate}}</th>
                </tr>
                </thead>
                <tbody *ngIf="Data">
                  <ng-template let-item ngFor [ngForOf]="Data.AccountStatement">
                    <tr>
                      <td >{{_core.DateFromNow(item.Time)}}</td>
                      <td >{{item.BarCode}}</td>
                      <td>{{TransactionTypes[item.Type]}}</td>
                      <td class="red_text">{{item.Amount}}</td>
                      <td>{{item.Balance}}</td>
                    </tr>
                  </ng-template>

                </tbody>

              </table>
            </div>
          </div>
        </div>

      </div>
      <div class="col-xs-right">
        <div class="box offset-bottom0">
          <div class="box-header">
            <h2>Statement</h2>
          </div>
          <div class="box-content">
            <div class="summary">
              <div class="betslip-row">
                <div class="title padd-v5">
                  <div class="text-center blue">{{'TRANSACTION ANALYSIS' | translate}}</div>
                </div>
                <div class="statement-row" *ngIf="Data && Data.Stake">
                  <div class="pull-left">{{'Stake' | translate}}</div>
                  <div class="pull-right">{{Data.Stake}}</div>
                </div>

                <div class="statement-row"  *ngIf="Data && Data.Winnings">
                  <div class="pull-left">{{'Winnings' | translate}}</div>
                  <div class="pull-right">{{Data.Winnings}}</div>
                </div>

                <div class="statement-row" *ngIf="Data && Data.Commission">
                  <div class="pull-left">{{'Commission' | translate}}</div>
                  <div class="pull-right">{{Data.Commission}}</div>
                </div>

              </div>
              <div class="betslip-row">
                <div class="title padd-v5" >
                  <div class="text-center green">{{'TOTALS' | translate}}</div>
                </div>
                <div class="statement-row" *ngIf="Data && Data.OpeningBalance">
                  <div class="pull-left">{{'Opening Balance' | translate}}</div>
                  <div class="pull-right">{{Data.OpeningBalance}}</div>
                </div>

                <div class="statement-row" *ngIf="Data && Data.Debits">
                  <div class="pull-left">{{'Debits' | translate}}</div>
                  <div class="pull-right">{{Data.Debits}}</div>
                </div>

                <div class="statement-row" *ngIf="Data && Data.Credits">
                  <div class="pull-left">{{'Credits' | translate}}</div>
                  <div class="pull-right">{{Data.Credits}}</div>
                </div>

                <div class="statement-row" *ngIf="Data && Data.ClosingBalance">
                  <div class="pull-left">{{'Closing Balance' | translate}}</div>
                  <div class="pull-right">{{Data.ClosingBalance}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="full_is_busy" *ngIf="isLoading">
      <div class="full_is_busy_background"></div>
      <div class="blurred_area"></div>
      <div class="full_is_busy_contents">
        <div class="full_is_busy_title">
          <span class="full_is_busy_title_icon"></span>
          <span class="full_is_busy_title_text">{{'loading report' | translate}}</span>
        </div>
        <div class="full_is_busy_message"></div>
      </div>
    </div>


      <div class="error_message error-effect-jelly" style="display: none;">
      <div class="error_message_icon_close clickable" >
        <i class="fa fa-times"></i>
      </div>
      <div class="error_message_text clickable" >
        <i class="fa fa-exclamation"></i>
      </div>
      <div class="error-success-progress"></div>
    </div>

    </div>
  </div>
</div>
