import {Component, NgZone, OnInit} from '@angular/core';
import {Settings} from '../../../core/services/settings';
import {RequestsService} from '../../../core/services/requests/requests.service';
import {HttpClient} from '@angular/common/http';

declare var moment: any;
declare var $: any;

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html'
})

export class AnnouncementsComponent implements OnInit {
  public Data: any;
  public loader = false;
  public openedFilter = true;
  public selectedItem: any;
  public FilterObj = {
    fromDate: moment().set({hour: 0, minute: 0, second: 0, millisecond: 0}),
    toDate: moment().set({hour: 23, minute: 59, second: 59, millisecond: 0}),
  };

  constructor(
    private settings: Settings,
    private http: RequestsService,
    private httpClient: HttpClient,
    private zone: NgZone
  ) {

  }

  ngOnInit() {
    $('select').selectric();
    $('#datetimeStatementFrom').datetimepicker({
      format: 'dddd, MMM Do YYYY, HH:mm',
      defaultDate: this.FilterObj.fromDate
    }).on('dp.change', (e) => {
      this.FilterObj.fromDate = e.date ? new Date(e.date.format()) : '';
    });
    $('#datetimeStatementTo').datetimepicker({
      format: 'dddd, MMM Do YYYY, HH:mm',
      defaultDate: this.FilterObj.toDate
    }).on('dp.change', (e) => {
      this.FilterObj.toDate = e.date ? new Date(e.date.format()) : new Date();
    });
  }


  getReport() {
    this.loader = true;
    this.http.httpPost(this.settings.apiUrls.GetAnnoucements, this.FilterObj).subscribe((e: any) => {
      if (!e.HasError)
        this.Data = e.Data;
      this.loader = false;
    });
  }

  viewLine(item) {
    this.selectedItem = item;
    this.httpClient.get(`${this.settings.apiUrls.Exportdetails}${item.Id}/exportdetails?shopid=${localStorage.getItem('shopId')}&shoptoken=${localStorage.getItem('shoptoken')}&cashierid=${localStorage.getItem('cashirId')}&cashiertoken=${localStorage.getItem('cashirToken')}`)
      .subscribe((res) => {
        console.log(res);
      });
  }
}
