<div id="modalWindow" tabindex="-1" role="dialog" aria-labelledby="modal-windowLabel" style="display: block;"
     *ngIf="betslipData"
     class="">
  <div class="" role="document">

    <div>

      <div class="full_window betslip-modal not_in_print bet-info-new-page"
           style="position:relative; width:100%; height:100%;">
        <div id="modal_header" class="modal-header">
          <div class="modal-title">
            <span>{{'Bar Code' | translate}}: {{betslipData.BarCode}}</span>
            <span>{{'Placed on' | translate}}: </span>
            <span title="{{betslipData.Date | date: 'dd MMM yyyy HH:MM'}}"> {{betslipData.Date | date: 'dd MMM yyyy HH:MM'}}</span>
          </div>
        </div>
        <div class="">
          <div class="betslip_status Won"
               *ngIf="betslipData.State == BetStatesEnum.Won || betslipData.State == BetStatesEnum.Cashout">
            <div class="betslip_status_notification">
              <div>{{'Bet Won' | translate}} ({{betslipData.Payout}})</div>
              <div class="button close"></div>
            </div>
            <div class="betslip_status_shadow"></div>
          </div>
          <div class="betslip_header">
            <div class="betslip_header_item_foreground z1 ztop">
              <span class="betslip_header_item_text">{{'Betslip' | translate}}</span>
            </div>
          </div>

          <div id="betslip_body" class="">
            <div class="betslip_tab_body height100">
              <div class="height100 boxed">
                <div class="betslip_box height100">
                  <div class="betslip_selection_header">
                    <div class="left">
                      <div>{{'Selections' | translate}}:</div>
                    </div>
                    <div class="right">
                      <div class="your_odds"><!--Odds--></div>
                      <div class="your_stake">{{'Odds' | translate}}</div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="modal_window betslip_scrollbar_container accumulator-odds-enabled bonus-winnings-enabled">
                    <ul id="betslip_selections" class="Lost">
                      <li class="betslip_selection" *ngFor="let selection of betslipData.Selections">
                        <div class="betslip_selection_first_line">
                          <div class="betslip_selection_first_line_number">{{selection.Index}}.</div>
                          <div class="betslip_selection_first_line_market">
                            <div class="betslip_selection_first_line_market_row1">
                              <div class="betslip_selection_first_line_category"
                                   title="{{selection.Region}} - {{selection.Competition}}">
                                {{selection.Region}} - {{selection.Competition}}
                              </div>
                              <div class="betslip_selection_first_line_" style="float: left;"> -</div>
                              <div class="betslip_selection_first_line_date">{{selection.af | date:'dd/mm HH:MM'}}
                              </div>
                            </div>
                            <div class="betslip_selection_first_line_market_row2">
                              <div class="betslip_selection_first_line_market_row2_marketName"
                                   style="width: calc(100% - -7px);">
                                {{selection.EventId}} {{selection.HomeName}} v {{selection.AwayName}}
                              </div>

                            </div>
                          </div>
                          <div class="clearboth"></div>
                        </div>

                        <div class="betslip_selection_second_line">
                          <div>
                            <div class="disabled betslip_selection_second_line_banker" *ngIf="selection.IsBanker">
                              B
                            </div>
                          </div>
                          <div class="betslip_selection_second_line_status">
                            <div
                              class="betslip_selection_second_line_status won betslip_selection_second_line_status_success"
                              *ngIf="selection.SelectionState == BetStatesEnum.Won">
                              WON
                            </div>
                          </div>
                          <div class="betslip_selection_second_line_your_odds pull-left"> {{showMatchesResults ?
                            selection.HF ==1? '1st Half' : '2nd Half' + ' ' + selection.Time+ '\'' + ' ' +
                            selection.Score : ''}}
                          </div>
                          <div class="betslip_selection_second_line_your_stake" style="display: none;"></div>
                          <div class="betslip_selection_second_line_your_odds">{{selection.Odds}} <br> <span
                            style="font-size: 11px; color: red; font-style: italic;">{{selection.NewOdd}}</span></div>
                          <div class="clearboth"></div>
                        </div>

                        <div class="betslip_selection_third_line">
                          <div class="betslip_selection_second_line_group_empty"></div>
                          <div class="betslip_selection_second_line_event padded">
                            <span title="{{selection.MarketName}}: {{selection.SelectionName}}">{{selection.MarketName}}: {{selection.SelectionName}}</span>
                          </div>
                          <div class="clearboth"></div>

                        </div>

                        <div class="right" style="display: none;">
                          <div class="your_odds">1.39</div>
                          <div class="your_stake" style="display: none;"></div>
                          <div class="max_payout" style="display: none;"></div>
                        </div>
                      </li>

                    </ul>
                    <ul class="betslip_systems">
                      <ng-template let-variant ngFor [ngForOf]="betslipData.SystemSelections">
                        <li class="betslip_system 1" (click)="variant.IsOpen = !variant.IsOpen">

                          <div class="betslip_system_row1">
                            <div class="clickable expander" style="position: absolute;"></div>
                            <div class="betslip_system_name clickable">{{variant.VariantName}}</div>
                          </div>
                          <div class="betslip_system_row2">
                            <div class="float_left betslip_system_details_lines">{{variant.VariantSelectionCount}} LINES
                              <span
                                class="betslip_systems_multiplier_button">X</span></div>
                            <div class="betslip_system_details_odds">{{variant.TotalOdds}}</div>
                            <div class="float_right betslip_system_yourstake">€{{variant.TotalStake}}</div>
                          </div>
                          <div class="betslip_system_details" [ngStyle]="{display: variant.IsOpen ? 'block' : 'none'}">
                            <ul class="betslip_system_expanded_body">
                              <ng-template let-line ngFor [ngForOf]="objectKeys(variant.SelectionStake)">
                                <li class="betslip_system_expanded_item">
                                  <div class="betslip_system_expanded_item_selections checkbox checked"
                                       title="{{line}}">{{line}}
                                  </div>
                                  <div class="betslip_system_expanded_item_yourodds"></div>
                                  <div class="betslip_system_expanded_item_yourstake">
                                    €{{variant.SelectionStake[line]}}
                                  </div>
                                </li>
                              </ng-template>
                            </ul>
                          </div>
                        </li>
                      </ng-template>
                    </ul>
                  </div>
                  <div class="betslip_summary">
                    <div class="betslip_summary_row1 large_text">
                      <div class="clearboth"></div>
                      <div class="betslip_summary_text" style="float:left;">{{'TOTAL STAKE' | translate}}</div>
                      <div class="betslip_summary_value">{{betslipData.BetAmount}}</div>
                      <div class="clearboth"></div>
                    </div>
                    <div class="betslip_summary_row2">
                      <div>
                        <div class="betslip_summary_row1">
                          <div class="betslip_summary_text">
                            <span>BETS:</span> &nbsp;<span>{{betslipData.BetsCount}}</span>
                          </div>
                        </div>
                        <div class="clearboth"></div>
                      </div>
                      <div class="betslip_summary_row1">
                        <div class="betslip_summary_text">{{'MAX. PAYOUT' | translate}}</div>
                        <div class="betslip_summary_value">€{{betslipData.MaxPayout}}</div>
                      </div>
                      <div class="clearboth"></div>
                      <div class="clearboth"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="full-error-message"></div>
          </div>

        </div>

        <div class="footer">
          <div class="btn btn-shop-primary-color btn-betslip" (click)="showMatchesResults = true"> Match Results</div>
        </div>
        <div class="modal_footer betslip-summary">

          <div class="betslip-row insert-pin" style="display: block;" *ngIf="showPayInput">
            <div class="betslip-txt">{{'Insert Betslip Pin' | translate}}</div>
            <div class="pin_error">
              <div class="error_message error-effect-jelly"
                   [ngStyle]="{display: notify.type == 'error' ? 'block' : 'none'}"
                   [ngClass]="{confirmation_message: notify.type == 'success'}">
                <div class="error_message_icon_close clickable" (click)="clearError()">
                  <i class="fa fa-times"></i>
                </div>
                <div class="error_message_text clickable">
                  <span class="highlighted" *ngIf="notify.type == 'error'">{{'Error: Incorrect Pin' | translate}}</span>
                  <span class="highlighted" *ngIf="notify.type == 'success'">{{'Success' | translate}}</span>
                  <i class="fa fa-exclamation"></i>
                </div>
                <div class="error-success-progress"></div>
              </div>
            </div>
            <div class="input_box pin_box">
              <div class="button close small" style="display: none;"></div>
              <div class="custom-input">
                <div class="input-group col-xs-input">
                  <input class="form-control input-md" type="text" placeholder="Betslip Pin"
                         (change)="pin = $event.target.value">
                </div>
              </div>
            </div>
          </div>


          <div class="footer">
            <div *ngIf="betslipData.CashOutAmount > 0">

              <div style="width: calc(100% - 120px); float: left;">
                <div *ngIf="betslipData.HasPartialCashout &&  betslipData.State == BetStatesEnum.Pending">
                  <ng5-slider [(value)]="cashoutAmount" [options]="options"></ng5-slider>
                </div>
              </div>
              <div style="width: 120px; float: right">
                <div class="btn btn-shop-primary-color "
                     *ngIf="betslipData.HasCashout && betslipData.State == BetStatesEnum.Pending" (click)="toCashout()">
                  {{'Cashout' | translate}}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

