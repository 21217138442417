<div class="row tab-pane active">
  <div class="col-xs-12">
    <div class="box">
      <!-- HEADER -->
      <div class="box-header">
        <h2>
          <i class="fa fa-cog"></i>
          <span class="break"></span>
          <span>{{'Device Settings' | translate}}</span>
        </h2>
      </div>
      <div class="box-content">
        <div class="dashsett">
          <!-- PRINTER -->
          <div class="row">
            <div class="form-group">
              <div class="col-xs-12 title">{{'Printer' | translate}}</div>
              <div class="col-xs-6 col-xs-pull-6 col-xs-offset-6 radioBox">
                <input name="printer_remote_radio" id="printer_windows_radio1" type="radio" value="aa"
                       [checked]="devices.defPrinter" (change)="devices.defPrinter = true">
                <label for="printer_windows_radio1" class="title">{{'Use Windows default' | translate}}</label>
              </div>
              <div class="col-xs-6 col-xs-pull-6 col-xs-offset-6 radioBox">
                <div class="custom-input primary-color">
                  <div class="input-group col-xs-12">
                    <input name="printer_remote_radio" id="printer_remote_radio2" type="radio" value="bb"
                           [checked]="!devices.defPrinter" (change)="devices.defPrinter = false">
                    <label for="printer_remote_radio2" class="title">{{'Use a remote url' | translate}}</label>
                    <input class="form-control float-none width60 input-md" placeholder="{{'Enter URL for printer' | translate}}"
                           [value]="devices.Printer" (change)="devices.Printer = $event.target.value">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- SCANNER -->
          <div class="row">
            <div class="form-group offset-top10">
              <div class="col-xs-12 title">{{'Scanner' | translate}}</div>
              <div class="col-xs-6 col-xs-pull-6 col-xs-offset-6 radioBox">
                <input name="scanner_remote_radio" id="scanner_windows_radio1" class="radio_input" type="radio"
                       value1="windows" value="true" [checked]="devices.defScaner" (change)="devices.defScaner = true">
                <label for="scanner_windows_radio1">{{'Use Windows default' | translate}}</label>
              </div>
              <div class="col-xs-6 col-xs-pull-6 col-xs-offset-6 radioBox">
                <div class="custom-input primary-color">
                  <div class="input-group col-xs-12">
                    <input name="scanner_remote_radio" id="scanner_remote_radio2" type="radio" value2="remote"
                           value="false" [checked]="!devices.defScaner" (change)="devices.defScaner = false">
                    <label for="scanner_remote_radio2" class="title">{{'Use a remote url' | translate}}</label>
                    <input class="form-control float-none width60 input-md" placeholder="{{'Enter URL for scanner' | translate}}"
                           value="{{devices.Scaner}}" (change)="devices.Scaner = $event.target.value">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- FISCAL -->
          <div class="row">
            <div class="form-group offset-top10">
              <div class="col-xs-12 title">{{'Fiscal' | translate}}</div>
              <div class="col-xs-6 col-xs-pull-6 col-xs-offset-6 radioBox">
                <input name="fiscal_remote_radio" id="fiscal_nofiscal_radio1" class="radio_input" type="radio"
                       value="true" [checked]="devices.defFiscal" (change)="devices.defFiscal = true">
                <label for="fiscal_nofiscal_radio1">{{'No fiscal printer' | translate}}</label>
              </div>
              <div class="col-xs-6 col-xs-pull-6 col-xs-offset-6 radioBox">
                <div class="custom-input primary-color">
                  <div class="input-group col-xs-12">
                    <input name="fiscal_remote_radio" id="fiscal_remote_radio2" type="radio" value="false"
                           [checked]="!devices.defFiscal" (change)="devices.defFiscal = false">
                    <label for="fiscal_remote_radio2" class="title">{{'Use a remote url' | translate}}</label>
                    <input class="form-control float-none input-md width60" placeholder="{{'Enter URL for fiscal' | translate}}"
                           value="{{devices.Fiscal}}" (change)="devices.Fiscal = $event.target.value">
                  </div>
                </div>
              </div>
            </div>
          </div>


          <!-- SAVE BUTTON -->
          <div class="row">
            <div class="col-xs-12 offset-top10 col-xs-pull-6 col-xs-offset-6">
              <div class="footer-buttons">
                <a href="javascript:;" class="btn btn-shop-primary-color btn-auto" (click)="saveOptions()">
                  {{'SAVE OPTIONS' | translate}}
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
</div>

<div class="success_message">
  <div class="error_message error-effect-jelly confirmation_message"  [ngStyle]="{display: isSuccess ? 'block': 'none'}">
    <div class="error_message_icon_close clickable">
      <i class="fa fa-times"></i>
    </div>
    <div class="error_message_text clickable">
      <span class="highlighted">{{'Success' | translate}}!</span>
      <span>{{'Settings Saved' | translate}}</span>
      <i class="fa fa-exclamation"></i>
    </div>
    <div class="error-success-progress"></div>
  </div>
</div>
