import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoreModule} from '../../core/core.module';
import {ManagementComponent} from './management.component';
import {BetApprovalsComponent} from './bet-approvals/bet-approvals.component';
import {SettingsComponent} from './settings/settings.component';
import {GeneralComponent} from './settings/general/general.component';
import {SportComponent} from './settings/sport/sport.component';
import {RouterModule} from '@angular/router';
import {PasswordComponent} from './settings/Password/Password.component';
import {PersonalInfoComponent} from './settings/personalInfo/PersonalInfo.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DevicesComponent} from './settings/devices/devices.component';
import {BetCodesComponent} from './bet-codes/bet-codes.component';
import {ReportsComponent} from './reports/reports.component';
import {AccountStatementComponent} from './reports/account-statement/account-statement.component';
import {BettingHistoryComponent} from './reports/betting-history/betting-history.component';
import {StatementTotalsComponent} from './reports/statement-totals/statement-totals.component';
import {TodayCashComponent} from './reports/today-cash/today-cash.component';
import {AnnouncementsComponent} from './Announcements/announcements.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    ManagementComponent,
    BetApprovalsComponent,
    SettingsComponent,
    ReportsComponent,
    GeneralComponent,
    SportComponent,
    PasswordComponent,
    PersonalInfoComponent,
    DevicesComponent,
    BetCodesComponent,
    AccountStatementComponent,
    BettingHistoryComponent,
    StatementTotalsComponent,
    TodayCashComponent,
    AnnouncementsComponent
  ]
})

export class ManagementModule {
  constructor() {

  }
}
