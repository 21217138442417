import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {ChannelService} from './signalr/signalr.service';

declare var localStorage: any;

@Injectable({
  providedIn: 'root',
})

export class Settings {
  public isShortcutsPopupOpened: boolean;
  public isMainMenuOpened: boolean;
  public betSlipClosed: boolean;
  public IsBetslipExpanded: boolean;
  public partnerId = environment.partnerId;
  public sigRUrl = environment.sigRUrl;
  public platformUrl = environment.platformUrl;
  public activeLang = 'en';
  public hasError = {
    hasError: false,
    mgs: '',
    clearError() {
      this.hasError = false;
      this.mgs = '';
    }
  };
  public successInfo = {
    success: false,
    mgs: '',
    clearError() {
      this.success = false;
      this.mgs = '';
    }
  };

  /*  public platformUrl = 'http://173.212.218.28:8888/';
    public sigRUrl = 'http://173.212.218.28:9999/';*/

  public ConnectionStarted = false;

  public sportConn = new ChannelService();
  public signalREvent = new Observable((observer) => {
    if (this.sportConn && this.ConnectionStarted) {
      observer.next(true);
      observer.complete();
    } else {
      this.sportConn.starting$.subscribe((e) => {
        this.ConnectionStarted = true;
        observer.next(this);
        observer.complete();
      });
    }

  });
  public smsConn: any;
  public apiUrls = {
    UpdateCashierPassword: this.platformUrl + 'api/cashier/UpdateCashierPassword?partnerId=' + this.partnerId,
    GetGeneralSettings: this.platformUrl + 'api/cashier/GetGeneralSettings',
    UpdateGeneralSettings: this.platformUrl + 'api/cashier/UpdateGeneralSettings',
    GetSportOptions: this.platformUrl + 'api/cashier/GetSportOptions',
    UpdateSportOptions: this.platformUrl + 'api/cashier/UpdateSportOptions',
    GetPersonalSettings: this.platformUrl + 'api/cashier/GetPersonalSettings',
    UpdatePersonalOptions: this.platformUrl + 'api/cashier/UpdatePersonalOptions',
    GetDeviceSettings: this.platformUrl + 'api/cashier/GetDeviceSettings',
    UpdateDeviceOptions: this.platformUrl + 'api/cashier/UpdateDeviceOptions',
    GetAnnoucements: this.platformUrl + 'api/Annoucements/GetAnnoucements',
    Exportdetails: this.platformUrl + 'api/Annoucements/',
    Pay: this.platformUrl + 'api/cashier/BetSleep/Pay',
    PartialCashout: this.platformUrl + 'api/cashier/BetSleep/PartialCashout',
    Cashout: this.platformUrl + 'api/cashier/BetSleep/Cashout',
    account: {
      GetShopTerminals: `${this.platformUrl}api/${this.partnerId}/account/GetShopTerminals`,
      GetShopCashiers: `${this.platformUrl}api/${this.partnerId}/account/GetShopCashiers`,
    },
    settings: {
      GetBetCodes: this.platformUrl + 'api/Settings/GetBetCodes?sportId=',
      GetBetApprovalBetSleep: this.platformUrl + 'api/Settings/GetBetApprovalBetSleep?',
    },
    Sport: {
      GetSports: this.platformUrl + 'api/Sport/GetSports?languageId=',
      GetBetApprovals: this.platformUrl + 'api/Settings/GetBetApprovals?state=',
    },
    reports: {
      StatementTotals: this.platformUrl + 'api/reports/StatementTotals',
      BettingHistory: this.platformUrl + 'api/reports/BettingHistory',
      TodaysCash: this.platformUrl + 'api/reports/TodaysCash',
      ExportTodaysCash: this.platformUrl + 'api/reports/ExportTodaysCash',
      CashierStatement: this.platformUrl + 'api/reports/CashierStatement',
      GetBetSleepDetails: this.platformUrl + 'api/cashier/GetBetSleepDetails?',
      GetBetSleepDetailsByBarCode: this.platformUrl + 'api/cashier/GetBetSleepDetailsByBarCode?',
    },
    book: {
      sports: `${this.platformUrl}api/cashier/sports?languageId=`,
      sportApi: `${this.platformUrl}api/cashier/sport/`,
      matchwithodds: `${this.platformUrl}api/cashier/eventwithodds`,
      singleMatchOdds: `${this.platformUrl}api/cashier/single/eventwithodds`,
      updmatchwithodds: `${this.platformUrl}api/cashier/eventwithodds`,
      // 'sports': `api/cashier/sports?languageId=${this.activeLang}`,

    },
    Getvariants: this.platformUrl + 'api/variant/getvariants',
    checkbetsleep: this.platformUrl + 'api/variant/checkbetsleep',
    GetCountries: this.platformUrl + 'api/Property/GetCountries',
    GetCurrencies: this.platformUrl + 'api/Property/GetCurrencies',


  };
  public lastBetObj = {
    time: new Date(),
    stake: 0,
    betObj: null,
    selectionCount: 0
  };
  public betsInfo = {
    BetsCount: 0,
    BetsAmount: 0
  };

  public cashier = {
    cashierId: '',
    cashierToken: ''
  };

  public cashierData = {
    CashierName: '',
    LanguageId: '',
    TimeZoneId: null
  };
  public sportOptions = {
    Id: 0,
    TerminalId: 0,
    AllowMultipleBetSlip: false,
    QuickBetSlip: true,
    AllowSystem: true,
    AllowBankers: true,
    AllowGroups: true,
    PermutationAnaliysis: true,
    AutoNavigateTVOdds: true,
    AutoNavigateLiveCenter: true
  };

  public SuccessBetData: any;
  public guestMode: boolean;

  constructor() {
    if (localStorage.getItem('sportOptions')) {
      this.sportOptions = JSON.parse(localStorage.getItem('sportOptions'));
    }

    if (localStorage.getItem('cashirToken')) {
      this.cashier.cashierToken = localStorage.getItem('cashirToken');
    }

    if (localStorage.getItem('cashirId')) {
      this.cashier.cashierId = localStorage.getItem('cashirId');
    }

    if (localStorage.getItem('cashierData')) {
      this.cashierData = JSON.parse(localStorage.getItem('cashierData'));
    }


    if (localStorage.getItem('betsInfo')) {
      this.betsInfo = JSON.parse(localStorage.getItem('betsInfo'));
    }

    if (localStorage.getItem('guestMode')) {
      this.guestMode = true;
    }

    try {

      if (localStorage.getItem('lastBetObj')) {
        const obj = JSON.parse(localStorage.getItem('lastBetObj'));
        this.lastBetObj = obj;
      }
    } catch (e) {
    }
  }


}
