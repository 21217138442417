import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Settings} from '../../../../core/services/settings';
import {SportService} from '../../../../core/services/sport/sport.service';
import {BetSlipService} from '../../../../core/services/bet-slip/bet-slip.service';
import * as error from '../../../../core/enums/errors';
import * as _ from 'lodash';
import {AccountReqService} from '../../../../core/services/requests/account-req.service';
import {RequestsService} from '../../../../core/services/requests/requests.service';
import {Router} from '@angular/router';

declare var $: any;

@Component({
  selector: 'betslip-component',
  templateUrl: './betSlip.component.html',
  styleUrls: ['./betSlip.component.css']
})

export class BetSlipComponent implements OnInit {
  @ViewChild('betCodeElem') BetCodeElem: ElementRef;
  @ViewChild('name') nameField: ElementRef;
  public betSlipList = [];
  private errorList = error.ErrorList;
  public showBetInput = false;
  public ShowGroupSelect: boolean;
  public TotalStake = 0;
  public MaxPosPayout = 0;
  public AcceptHigherOdds = false;
  public AcceptOddsChanges = false;
  public SuccessBetData: any;
  public BetInPending = false;
  public IsBetApprovalReq = false;
  private BetData: any;
  public BetInfo: any;
  public show = {
    groups: false,
    system: false,
  };
  public error = {
    code: 0,
    title: '',
    description: ''
  };

  public searchBetCode = {
    matchId: 0,
    betCode: 0,
    blockedCode: false
  };

  public searchBetResults: any;
  public BetApprovalErrorMsg: any;
  public searchBarCode = '';

  constructor(
    public settings: Settings,
    public router: Router,
    public sportService: SportService,
    public betSlipService: BetSlipService,
    public http: RequestsService,
    public accoutReq: AccountReqService
  ) {

  }

  showGroups() {
    if (this.show.groups) {
      this.show.groups = false;
      this.showBetInput = false;
      this.betSlipService.ShowSystem = true;
      this.betSlipService.groupMatches();
    } else {
      this.showBetInput = false;
      this.show.groups = true;
      this.show.system = true;
      this.betSlipService.ShowSystem = true;
      this.betSlipService.groupMatches();
    }
  }

  showSystem() {
    if (!this.show.system) {
      this.show.system = true;
      this.showBetInput = false;
      this.betSlipService.ShowSystem = true;
      this.betSlipService.groupMatches();
    } else {
      this.show.groups = false;
      this.show.system = false;
      this.showBetInput = false;
      this.betSlipService.ShowSystem = false;
      this.betSlipService.groupMatches();
    }
  }

  userPickGroup(betItem, group) {
    betItem.group = group.name;
    betItem.ShowGroupSelect = false;
    if (this.betSlipService.groupValidation()) {
      this.betSlipService.groupMatches();
    }
  }

  setToBanker(selection) {

    selection.isBanker = !selection.isBanker;


    if (this.betSlipService.bankerValidation()) {
      this.betSlipService.groupMatches();
    }
  }

  ngOnInit() {
    $(document).keydown((e) => {
      if (e.which === 78 && e.altKey) {
        this.ClearAllBet();
      } else if (e.which === 84 && e.altKey) {
        document.getElementById('total_stake_input').focus();
      } else if (e.which === 80 && e.altKey) {
        this.PlaceBet();
      } else if (e.which === 67 && e.altKey) {
        this.copyLasBet();
      } else if (e.which === 71 && e.altKey) {
        document.getElementById('groups_chakbox').click();
      } else if (e.which === 83 && e.altKey) {
        document.getElementById('sytem_chakbox').click();
      } else if (e.which === 70 && e.altKey) {
        this.router.navigate(['/management/bet-codes']);
      } else if (e.which === 65 && e.altKey) {
        this.router.navigate(['/management/reports/account-statement']);
      } else if (e.which === 66 && e.altKey) {
        this.router.navigate(['/management/bet-approval']);
      } else if (e.which === 72 && e.altKey) {
        this.router.navigate(['/management/reports/betting-history']);
      } else if (e.which === 77 && e.altKey) {
        document.getElementById('betslip_event_code_market_code').focus();
      }

    });
    this.settings.sportConn.hubProxy.on('codeSelections', (e: any) => {
      if (e) {
        this.searchBetResults = e;
      }
    });
    this.settings.sportConn.hubProxy.on('quickAdd', (e: any) => {
      if (e) {
        this.quickAdd(e);
      }
    });
    this.settings.sportConn.hubProxy.on('PlaceBetResult', (e: any) => {
      setTimeout(() => {
        this.BetInPending = false;
      }, 500);
      if (!e.HasError && e.Data && !e.Data.HasError) {
        this.SuccessBetData = e.Data;
        this.settings.lastBetObj.betObj = JSON.parse(JSON.stringify(this.betSlipService.BetSlipList));
        this.settings.lastBetObj.stake = this.betSlipService.TotalStake;
        this.settings.lastBetObj.time = new Date();
        this.settings.lastBetObj.selectionCount = this.betSlipService.BetSlipList.length;
        localStorage.setItem('lastBetObj', JSON.stringify(this.settings.lastBetObj));

        this.settings.betsInfo.BetsCount++;
        this.settings.betsInfo.BetsAmount += this.betSlipService.TotalStake;
        localStorage.setItem('betsInfo', JSON.stringify(this.settings.betsInfo));
        this.ClearAllBet();
      }
      if (e.HasError) {
        if (e.ErrorCode < 0) {
          return this.error = {
            code: e.ErrorCode,
            title: e.ErrorMessage,
            description: ''
          };
        }
        if (e.ErrorCode === 3) {
          return this.accoutReq.fullLogout();
        }
        if (e.ErrorCode !== 43) {
          this.error = this.errorList[e.ErrorCode];
        }
        if (e.ErrorCode === 43) {
          this.BetApprovalErrorMsg = e.ErrorMessage;
          this.IsBetApprovalReq = true;
        }
      }

      if (!e.HasError && e.Data && e.Data.HasError) {
        if (e.Data.ErrorCode === 28) {
          this.error = this.errorList[e.Data.ErrorCode];
          e.Data.Selections.forEach((item, key) => {
            this.betSlipService.BetSlipList.forEach((sel, i) => {
              if (sel.selection.c == item.c) {
                return sel.selection.Odd = item.Odds;
              }
            });
          });
        }
        if (e.Data.ErrorCode === 27) {
          this.error = this.errorList[e.Data.ErrorCode];

        }
      }
    });
  }

  betAmountChanged(betItem, selIndex, target) {
    if (!isNaN(target.value) && Number(target.value) >= 0) {
      const newValue = this.betSlipService.toFix2(target.value);
      betItem.amount = newValue;
      target.value = newValue;
      _.find(this.betSlipService.VariantsList, (vgItem) => {
        if (vgItem.count === 1) {
          _.find(vgItem.Variants, (vItem) => {
            if (vItem.variant == selIndex) {
              vgItem.betAmount = vgItem.betAmount ?
                this.betSlipService.toFix2(vgItem.betAmount) - this.betSlipService.toFix2(vItem.betAmount) + newValue : newValue;
              vItem.betAmount = newValue;
            }
          });
        }
      });
      this.betSlipService.TotalStake = this.betSlipService.toFix2(this.betSlipService.TotalStake) + newValue;
      this.betSlipService.betsCount();
      this.MaxPosPayout += betItem.selection.Odd * newValue;
    } else {
      target.value = 0;
    }

  }

  VGBAmountChanged(amount, group) {
    if (!isNaN(amount)) {
      this.betSlipService.TotalStake = Number(this.betSlipService.TotalStake) + Number(amount);
      this.MaxPosPayout += group.totalOdds * amount;
      let variantCount = 0;
      group.Variants.forEach((vItem, vIndex) => {
        if (!vItem.hidden) {
          variantCount++;
        }
      });
      if (variantCount) {
        this.VGBajanum(amount, group, variantCount);
      }

      this.betSlipService.betsCount();
    } else {
      group.betAmount = 0;
    }
  }

  VBAmountChanged(amount, variant, group) {
    if (!isNaN(amount)) {
      amount = Number(amount);
      variant.hidden = false;
      group.betAmount = variant.betAmount ? group.betAmount - variant.betAmount + amount : group.betAmount + amount;
      this.betSlipService.TotalStake = variant.betAmount ? this.betSlipService.TotalStake - variant.betAmount + amount : this.betSlipService.TotalStake + amount;
      this.MaxPosPayout = variant.betAmount ? this.MaxPosPayout - variant.totalOdds * variant.betAmount + variant.totalOdds * amount : this.MaxPosPayout + variant.totalOdds * amount;

      variant.betAmount = Number(amount);
      this.betSlipService.betsCount();
    } else {
      group.betAmount = 0;
    }
  }

  PlaceBet() {
    this.SuccessBetData = null;
    this.settings.SuccessBetData = null;
    const groupsList = {};
    const betObj = {
      BetShopId: localStorage.getItem('shopId'),
      BetShopSession: localStorage.getItem('shoptoken'),
      CashierSession: !this.settings.guestMode ? localStorage.getItem('cashirToken') : '',
      CashierId: !this.settings.guestMode ? localStorage.getItem('cashirId') : null,
      BetAmount: this.betSlipService.TotalStake.toFixed(2),
      BetsCount: 1,
      MaxPayout: 0,
      AcceptHigherOdds: this.AcceptHigherOdds,
      AcceptOddsChanges: this.AcceptOddsChanges,
      CurrencyId: 'EUR',
      LanguageId: this.settings.activeLang,
      Selections: [],
      SystemSelections: [],
      Groups: {}
    };
    this.betSlipService.BetSlipList.forEach((sel, i) => {
      this.clearError();
      if (sel.group) {
        if (!groupsList[sel.group]) {
          groupsList[sel.group] = [sel.BSId];
        } else {
          groupsList[sel.group].push(sel.BSId);
        }
      }
      betObj.Selections.push(
        {
          OddId: sel.selection.c,
          Odds: sel.selection.Odd,
          MarketId: sel.marketId,
          SelectionId: sel.selection.Id,
          IsBanker: sel.isBanker ? 1 : 0,
          SportId: sel.sportId,
          GroupId: sel.group,
          CategoryId: sel.categoryId,
          TournamentId: sel.tournamentId,
          EventId: sel.matchId,
          Index: sel.BSId
        }
      );
    });
    let betCount = 0;
    let totalBetAmount = 0;
    this.betSlipService.VariantsList.forEach((variantGroup, vgIndex) => {
      if (variantGroup.betAmount && !variantGroup.hidden || (!this.show.system && variantGroup.count === 1 && variantGroup.betAmount)) {
        const SelectionStake = {};
        let TotalStake = 0;
        variantGroup.Variants.forEach((variant, vIndex) => {
          if (variant.betAmount && !variant.hidden) {
            totalBetAmount = this.betSlipService.toFix2(totalBetAmount) + this.betSlipService.toFix2(variant.betAmount * variant.totalOdd);
            SelectionStake[variant.variant] = variant.betAmount;
            TotalStake += variant.betAmount * variant.totalOdd;
            betCount++;
          }
        });
        betObj.SystemSelections.push({
          SelectionStake,
          VariantName: variantGroup.variantName,
          VariantSelectionCount: variantGroup.count,
          CountOfLines: variantGroup.variantCount,
          TotalOdds: variantGroup.totalOdds,
          TotalStake: Number(variantGroup.betAmount).toFixed(2),
          NumberOfSelections: variantGroup.selectionCount
        });
      }

    });

    betObj.BetsCount = betCount;
    betObj.MaxPayout = totalBetAmount;
    for (const gItem in groupsList) {
      const a = groupsList[gItem];
      betObj.Groups[gItem] = a.toString();
    }
    this.BetData = betObj;
    if (totalBetAmount > 0) {
      this.BetInPending = true;
      this.settings.sportConn.hubProxy.invoke('PlaceBet', betObj);
    }
    // }
  }

  TotalStakeChange(amount) {
    if (!isNaN(amount)) {
      let varinatsCount = 0;
      this.betSlipService.TotalStake = Number(amount);
      this.betSlipService.VariantsList.forEach((vG, Vi) => {
        if (!vG.hidden) {
          vG.Variants.forEach((variat, variatIn) => {
            if (!variat.hidden) {
              varinatsCount++;
            }
          });
        }

      });
      if (varinatsCount) {
        this.gumarBajanum(this.betSlipService.TotalStake, varinatsCount);
      } else {
        this.gumarBajanumBetItems(this.betSlipService.TotalStake);
      }

    }
  }

  gumarBajanum(amount, variants, count?) {
    const result = [];
    const membersPerGroup = Math.floor(amount * 100 / variants) / 100;
    let leftover = Math.floor(amount * 100 % variants) / 100;
    this.betSlipService.VariantsList.forEach((vG, vI) => {
      if (!vG.hidden || vG.count === count) {
        vG.betAmount = 0;
        vG.Variants.forEach((variant, variantIndex) => {
          if (!variant.hidden) {
            variant.betAmount = membersPerGroup;
            vG.betAmount = Number(Number(vG.betAmount + variant.betAmount).toFixed(2));
          }
        });
      }

    });
    while (leftover > 0) {
      this.betSlipService.VariantsList.forEach((vG, vI) => {
        if (!vG.hidden || vG.count === count) {
          vG.Variants.forEach((variant, variantIndex) => {
            if (!variant.hidden) {
              if (leftover > 0) {
                vG.betAmount = Number(Number(vG.betAmount - variant.betAmount).toFixed(2));
                variant.betAmount = (Math.round((variant.betAmount + 0.01) * 100) / 100);
                vG.betAmount = Number(Number(vG.betAmount + variant.betAmount).toFixed(2));
                leftover = (Math.floor(leftover * 100) - 0.01 * 100) / 100;
              }
            }
          });
        }

      });
    }

    this.betSlipService.betsCount();
  }

  gumarBajanumBetItems(amount) {
    const result = [];
    const variants = this.betSlipService.BetSlipList.length;

    const membersPerGroup = Math.floor(amount * 100 / variants) / 100;
    let leftover = Math.floor(amount * 100 % variants) / 100;

    let betAmount = 0;
    this.betSlipService.BetSlipList.forEach((variant, variantIndex) => {
      //if (variant.hidden) {
      variant.amount = membersPerGroup;
      betAmount = Number(Number(betAmount + variant.amount).toFixed(2));
      //  }
    });
    //  this.betSlipService.VariantsList[0].betAmount = amount;
    while (leftover > 0) {
      this.betSlipService.BetSlipList.forEach((variant, variantIndex) => {
        if (!variant.hidden) {
          if (leftover > 0) {
            betAmount = Number(Number(betAmount - variant.amount).toFixed(2));
            variant.amount = (Math.round((variant.amount + 0.01) * 100) / 100);
            betAmount = Number(Number(betAmount + variant.amount).toFixed(2));
            leftover = (Math.floor(leftover * 100) - 0.01 * 100) / 100;
          }
        }
      });
    }
    this.gumarBajanum(this.betSlipService.TotalStake, this.betSlipService.VariantsList[0].Variants.length, 1);
  }

  VGBajanum(amount, variantGroup, variants) {
    const result = [];
    const membersPerGroup = Math.floor(amount * 100 / variants) / 100;
    let leftover = Math.floor(amount * 100 % variants) / 100;

    variantGroup.betAmount = 0;
    variantGroup.Variants.forEach((variant, variantIndex) => {
      if (!variant.hidden) {
        variant.betAmount = membersPerGroup;
        variantGroup.betAmount = Number(Number(variantGroup.betAmount + variant.betAmount).toFixed(2));
      }
    });

    while (leftover > 0) {
      variantGroup.Variants.forEach((variant, variantIndex) => {
        if (!variant.hidden) {
          if (leftover > 0) {
            variantGroup.betAmount = Number(Number(variantGroup.betAmount - variant.betAmount).toFixed(2));
            variant.betAmount = (Math.round((variant.betAmount + 0.01) * 100) / 100);
            variantGroup.betAmount = Number(Number(variantGroup.betAmount + variant.betAmount).toFixed(2));
            leftover = (Math.floor(leftover * 100) - 0.01 * 100) / 100;
          }
        }
      });
    }
  }


  clearError() {
    this.error = {
      code: 0,
      description: '',
      title: ''
    };
  }


  hideVariant($ev, variant, vGroup) {
    $ev.preventDefault();
    $ev.stopPropagation();
    variant.hidden = !variant.hidden;
    if (variant.hidden && variant.betAmount) {
      vGroup.betAmount -= variant.betAmount;
    }
    if (!variant.hidden && variant.betAmount) {
      vGroup.betAmount += variant.betAmount;
    }
    this.calculateTotalStake();
  }

  hideGroup($ev, vGroup) {
    $ev.preventDefault();
    $ev.stopPropagation();
    vGroup.hidden = !vGroup.hidden;
    vGroup.betAmount = 0;
    this.calculateTotalStake();
  }

  calculateTotalStake() {
    let amount = 0;
    this.betSlipService.VariantsList.forEach((vg, vgI) => {
      if (vg.betAmount) {
        amount += Number(vg.betAmount);
      }
    });

    this.betSlipService.TotalStake = amount;
  }


  ClearAllBet() {
    const betsList = JSON.parse(JSON.stringify(this.betSlipService.BetSlipList));
    betsList.forEach((item, ind) => {
      this.betSlipService.remove(item.matchId, item.marketId, item.selectionId);
    });

    this.betSlipService.TotalStake = 0;
    this.clearError();
  }

  ASKForBetApproval(state) {
    if (!state) {
      this.IsBetApprovalReq = false;
      return false;
    }

    if (state) {
      this.BetData.SendBetApproval = true;
      this.BetData.FwActionId = this.BetApprovalErrorMsg ? this.BetApprovalErrorMsg : 0;
      this.settings.sportConn.hubProxy.invoke('PlaceBet', this.BetData);
      this.IsBetApprovalReq = false;
      this.ClearAllBet();
    }

  }


  searchBetSlip() {
    this.http.httpGet(`${this.settings.apiUrls.reports.GetBetSleepDetailsByBarCode}barCode=${this.searchBarCode}&language=en`)
      .subscribe((data: any) => {
        if (!data.HasError) {
          this.BetInfo = data.Data;
          this.BetInfo.SystemSelections.forEach((item, ind) => {
            item.SelectionStake = JSON.parse(item.SelectionStake);
          });
        }
      });
  }

  RemoveBetslipData() {
    this.BetInfo = null;
  }

  copyLasBet() {
    try {
      /* this.betSlipService.BetSlipList = JSON.parse(JSON.stringify(this.settings.lastBetObj.betObj));
       this.sportService.chackselectedSelections();*/


      const obj = JSON.parse(JSON.stringify(this.settings.lastBetObj.betObj));
      this.betSlipService.copyLastBets(obj);
      // this.sportService.chackselectedSelections();
    } catch (e) {
    }

  }

  searchByBetCode() {
    this.settings.sportConn.hubProxy.invoke('GetOddsByCode', {
      LanguageId: this.settings.activeLang,
      ShopId: localStorage.getItem('shopId'),
      EventId: this.searchBetCode.matchId,
      BetCode: this.searchBetCode.betCode,
    });
  }

  selectBetCode(market, selection) {
    if (selection) {
      if (!this.searchBetCode.blockedCode) {
        this.searchBetCode.betCode = selection.BetCode;
      }
      /*  if (this.searchBetCode.blockedCode) {*/
      const data = {
        matchId: Number(this.searchBetCode.matchId),
        isLive: this.searchBetResults.o,
        home: this.searchBetResults.j,
        away: this.searchBetResults.a,
        eventName: this.searchBetResults.eventName,
        category: this.searchBetResults.f,
        categoryId: this.searchBetResults.d,
        tournament: this.searchBetResults.Competition,
        tournamentId: this.searchBetResults.t,
        marketId: Number(market.f),
        marketName: market.Name,
        minSelectionCount: market.h,
        selectionId: Number(selection.c),
        selection,
      };
      this.betSlipService.addToBetSlip(data);
      this.searchBetCode.matchId = 0;
      /*}*/

      this.searchBetResults = null;
    }


  }

  clearBetResults() {
    this.searchBetResults = null;
  }

  enterBetCode($ev, type?) {
    if (type === 'next') {
      return this.nameField.nativeElement.focus();
    }
    this.settings.sportConn.hubProxy.invoke('GetOddsByCode', {
      LanguageId: this.settings.activeLang,
      ShopId: localStorage.getItem('shopId'),
      EventId: this.searchBetCode.matchId,
      BetCode: this.searchBetCode.betCode,
      QuickAdd: true
    });
  }

  quickAdd(match) {
    /* match.Markets.forEach((market, key) => {
       market.selection.forEach((selection, sKey) => {

       });
     });*/
    if (match && match.Market && match.Market.Selection) {
      const data = {
        matchId: match.Id,
        isLive: match.o,
        home: match.j,
        away: match.a,
        eventName: match.eventName,
        category: match.f,
        categoryId: match.d,
        tournament: match.Competition,
        tournamentId: match.t,
        marketId: match.Market.f,
        marketName: match.Market.Name,
        minSelectionCount: match.Market.h,
        selectionId: match.Market.SingleSelection.Id,
        selection: match.Market.SingleSelection,
      };
      this.betSlipService.addToBetSlip(data);
      this.searchBetCode.matchId = 0;
    }
  }

}
