import {Component, OnInit} from '@angular/core';
import {Settings} from '../../../../core/services/settings';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RequestsService} from '../../../../core/services/requests/requests.service';

@Component({
  selector: 'sport-settings',
  templateUrl: './sport.component.html'
})

export class SportComponent implements OnInit {
  public isSuccess = false;
  public sS = {
    AllowMultipleBetSlip: false,
    QuickBetSlip: false,
    AllowSystem: false,
    AllowBankers: false,
    AllowGroups: false,
    PermutationAnaliysis: false,
    AutoNavigateTVOdds: false,
    AutoNavigateLiveCenter: false,
  };

  constructor(
    private fb: FormBuilder,
    private settings: Settings,
    private http: RequestsService
  ) {

  }

  ngOnInit() {
    this.http.httpGet(this.settings.apiUrls.GetSportOptions)
      .subscribe((e: any) => {
        if (!e.HasError) {
          this.sS = e.Data;
        }
      });
  }

  saveSettings() {
    this.isSuccess = false;
    this.http.httpPost(this.settings.apiUrls.UpdateSportOptions, this.sS)
      .subscribe((e: any) => {
        if (!e.HasError) {
          this.isSuccess = true;
          localStorage.setItem('sportOptions', JSON.stringify(this.sS));
          localStorage.removeItem('lastBetObj');
          localStorage.removeItem('BetSlipList');
        }
      });
  }
}
