import {Component} from '@angular/core';
import {Settings} from '../../../../core/services/settings';
import {RequestsService} from '../../../../core/services/requests/requests.service';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'device-comp',
  templateUrl: './devices.component.html'
})

export class DevicesComponent {
  public isSuccess = false;
  public devices = {
    Scaner: '',
    defScaner: true,
    Fiscal: '',
    defFiscal: true,
    Printer: '',
    defPrinter: true,
  };

  constructor(
    private fb: FormBuilder,
    private settings: Settings,
    private http: RequestsService
  ) {
    this.http.httpGet(this.settings.apiUrls.GetDeviceSettings)
      .subscribe((e: any) => {
        if (!e.HasError) {
          if (e.Data.Scaner) {
            this.devices.Scaner = e.Data.Scaner;
            this.devices.defScaner = false;
          }
          if (e.Data.Fiscal) {
            this.devices.Fiscal = e.Data.Fiscal;
            this.devices.defFiscal = false;
          }
          if (e.Data.Printer) {
            this.devices.Printer = e.Data.Printer;
            this.devices.defPrinter = false;
          }
        }
      });
  }

  saveOptions() {
    const obj = {
      Scaner: '',
      Fiscal: '',
      Printer: '',
    };
    if (!this.devices.defScaner) {
      obj.Scaner = this.devices.Scaner;
    }
    if (!this.devices.defFiscal) {
      obj.Fiscal = this.devices.Fiscal;
    }
    if (!this.devices.defPrinter) {
      obj.Printer = this.devices.Printer;
    }
    this.isSuccess = false;
    this.http.httpPost(this.settings.apiUrls.UpdateDeviceOptions, obj)
      .subscribe((res: any) => {
        if (!res.HasError) {
          this.isSuccess = true;
        }
      });
  }

  asd(val, it) {
    this.devices[it] = val.target.checked;
  }
}
