import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Settings} from '../../services/settings';
import {CoreService} from '../../services/core.service';

declare var moment: any;
declare var localStorage: any;

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  /*encapsulation: ViewEncapsulation.ShadowDom*/
})

export class PrintComponent implements OnInit {
  @Input() betData: any;
  public TerminalId: any;

  constructor(
    public settings: Settings,
    public core: CoreService,
    ) {

  }

  ngOnInit() {
    this.TerminalId = localStorage.getItem('terminalId');
    setTimeout(() => {
      this.printFn();
    }, 500);
  }

  printFn() {
    const newIframe1 = document.createElement('iframe');
    newIframe1.width = '1px';
    newIframe1.height = '1px';
    newIframe1.src = 'about:blank';

    // for IE wait for the IFrame to load so we can access contentWindow.document.body
    newIframe1.onload = function () {
      const script_tag1 = newIframe1.contentWindow.document.createElement('script');
      script_tag1.type = 'text/javascript';
      const script1 = newIframe1.contentWindow.document.createTextNode('function Print(){ window.focus(); window.print(); }');
      script_tag1.appendChild(script1);

      newIframe1.contentWindow.document.body.innerHTML = document.getElementById('asdf').innerHTML;
      newIframe1.contentWindow.document.body.appendChild(script_tag1);

      const csslink = document.createElement('link');
      csslink.setAttribute('rel', 'stylesheet');
      csslink.setAttribute('type', 'text/css');
      csslink.setAttribute('href', '/assets/styles/print/print_betslip.css');
      newIframe1.contentWindow.document.head.appendChild(csslink);
      // for chrome, a timeout for loading large amounts of content
      setTimeout(function () {
        const contentWindow = newIframe1.contentWindow;
        newIframe1.contentWindow.print();
        newIframe1.contentWindow.document.body.removeChild(script_tag1);
        newIframe1.parentElement.removeChild(newIframe1);
      }, 200);
    };
    document.body.appendChild(newIframe1);
  }



}
