import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

declare var localStorage: any;
@Injectable({
  providedIn: 'root',
})
export class LoginGuardService implements CanActivate {

  constructor( public router: Router) {}

  canActivate(): boolean {
    if (!localStorage.getItem('cashirToken')) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }

}
