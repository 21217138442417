<div class="tab-content">
  <div class="row">
    <div class="col-xs-12">
      <!-- Tab panes -->
      <div class="row tab-content">
        <div class="col-xs-12">
          <div class="box">
            <div class="box-header">
              <h2>
                <i class="fa fa-credit-card"></i>
                <span class="break"></span>
                {{'Personal Info' | translate}}
              </h2>
              <!-- display:none as not implemented yet -->
              <div style="display:none" class="box-icon">
                <a href="#" class="btn-minimize"><i class="fa fa-chevron-up"></i></a>
              </div>
            </div>
            <div class="box-content">
              <div class="dashsett">
                <div class="row">
                  <div class="col-xs-12">
                    <div class="title">
                      {{'Personal Details' | translate}}
                    </div>
                  </div>
                </div>
                <div>


                  <form [formGroup]="valForm" (submit)="submitForm($event, valForm.value)" novalidate="">
                    <!-- START panel-->
                    <div>
                      <div>
                        <div class="form-group ">
                          <div>
                            <div class="row">
                              <div class="col-lg-2 offset-bottom10">
                                <div class="input-group col-xs-12 custom-input">
                                  <div class="title">{{'Gender' | translate}}:</div>
                                  <select id="Gender" class="form-control" formControlName="Gender">
                                    <option value="" hidden></option>
                                    <option [value]="0">{{'Mr' | translate}}</option>
                                    <option [value]="1">{{'Miss' | translate}}</option>
                                    <option [value]="2">{{'Mrs' | translate}}</option>
                                    <option [value]="3">{{'Ms' | translate}}</option>
                                  </select>
                                  <div class="text-danger"
                                       *ngIf="valForm.controls['Gender'].hasError('required') && (valForm.controls['Gender'].dirty || valForm.controls['Gender'].touched)">
                                    {{'This field is required' | translate}}
                                  </div>
                                </div>
                              </div>
                              <div class="col-xs-2 offset-bottom10">
                                <div class="custom-input second-color">
                                  <div class="title">{{'Customer Id' | translate}}:</div>
                                  <div class="customerId">{{settings.cashier.cashierId}}</div>
                                </div>
                              </div>
                              <div class="col-lg-2 offset-bottom10">
                                <div class="input-group col-xs-12 custom-input">
                                  <div class="title">{{'First Name' | translate}}:</div>
                                  <input class="form-control fullwidth input-md" type="text"
                                         formControlName="Firstname"/>
                                </div>
                                <div class="text-danger"
                                     *ngIf="valForm.controls['Firstname'].hasError('required') && (valForm.controls['Firstname'].dirty || valForm.controls['Firstname'].touched)">
                                  {{'This field is required' | translate}}
                                </div>
                              </div>
                              <div class="col-lg-2 offset-bottom10">
                                <div class="input-group col-xs-12 custom-input">
                                  <div class="title">{{'Last Name' | translate}}:</div>
                                  <input class="form-control fullwidth input-md" type="text"
                                         formControlName="Lastname"/>
                                  <div class="text-danger"
                                       *ngIf="valForm.controls['Lastname'].hasError('required') && (valForm.controls['Lastname'].dirty || valForm.controls['Lastname'].touched)">
                                    {{'This field is required' | translate}}
                                  </div>
                                </div>
                              </div>

                              <div class="col-lg-2 offset-bottom10">
                                <div class="input-group col-xs-12 custom-input">
                                  <div class="title">{{'Date Of Birth' | translate}}:</div>
                                  <div class="custom-input datetime primary-color">
                                    <div class='input-group date' id='datetimeFrom'>
                                      <input type='text' class="form-control" disabled="true"/>
                                      <span class="input-group-addon">
                                          <span class="glyphicon glyphicon-calendar"></span>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="text-danger"
                                       *ngIf="valForm.controls['BirthDate'].hasError('required') && (valForm.controls['BirthDate'].dirty || valForm.controls['BirthDate'].touched)">
                                    {{'This field is required' | translate}}
                                  </div>
                                </div>

                              </div>

                              <div class="col-lg-2 offset-bottom10">
                                <div class="input-group col-xs-12 custom-input">
                                  <div class="title">{{'Email' | translate}}:</div>
                                  <input class="form-control fullwidth input-md" type="email" formControlName="Email"/>
                                  <div class="text-danger"
                                       *ngIf="valForm.controls['Email'].hasError('required') && (valForm.controls['Email'].dirty || valForm.controls['Email'].touched)">
                                    {{'This field is required' | translate}}
                                  </div>
                                  <div class="text-danger" *ngIf="valForm.controls['Email'].hasError('email')">
                                    {{'Enter a valid email' | translate}}
                                  </div>
                                </div>
                              </div>


                            </div>
                            <div class="row">


                              <div class="col-lg-2 offset-bottom10">
                                <div class="input-group col-xs-12 custom-input">
                                  <div class="title">{{'Phone Number' | translate}}:</div>
                                  <input class="form-control fullwidth input-md" type="text"
                                         formControlName="PhoneNumber"/>
                                  <div class="text-danger"
                                       *ngIf="valForm.controls['PhoneNumber'].hasError('required') && (valForm.controls['PhoneNumber'].dirty || valForm.controls['PhoneNumber'].touched)">
                                    {{'This field is required' | translate}}
                                  </div>
                                </div>
                              </div>
                              <!--<div class="col-lg-2 offset-bottom10">
                                <div class="input-group col-xs-12 custom-input">
                                  <div class="title">Country:</div>
                                  <input class="form-control fullwidth input-md" type="text" formControlName="Country"  />
                                  <div class="text-danger" *ngIf="valForm.controls['Country'].hasError('required') && (valForm.controls['Country'].dirty || valForm.controls['Country'].touched)">This field is required</div>
                                </div>
                              </div>-->
                              <div class="col-lg-2 offset-bottom10">
                                <div class="input-group col-xs-12 custom-input">
                                  <div class="title">{{'Country of residence' | translate}}:</div>
                                  <select id="ResidenceCountryId" class="form-control fullwidth input-md"
                                          formControlName="ResidenceCountryId">
                                    <option value=""></option>
                                    <option [value]="country.Id" *ngFor="let country of countryLIst">{{country.Name}}
                                    </option>
                                  </select>
                                  <div class="text-danger"
                                       *ngIf="valForm.controls['ResidenceCountryId'].hasError('required') && (valForm.controls['ResidenceCountryId'].dirty || valForm.controls['ResidenceCountryId'].touched)">
                                    {{'This field is required' | translate}}
                                  </div>
                                </div>

                              </div>
                              <div class="col-lg-2 offset-bottom10">
                                <div class="input-group col-xs-12 custom-input">
                                  <div class="title">{{'Town / City' | translate}}:</div>
                                  <input class="form-control fullwidth input-md" type="text" formControlName="City"/>
                                  <div class="text-danger"
                                       *ngIf="valForm.controls['City'].hasError('required') && (valForm.controls['City'].dirty || valForm.controls['City'].touched)">
                                    {{'This field is required' | translate}}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-2 offset-bottom10">
                                <div class="input-group col-xs-12 custom-input">
                                  <div class="title">{{'Address 1' | translate}}:</div>
                                  <input class="form-control fullwidth input-md" type="text"
                                         formControlName="Address1"/>
                                  <div class="text-danger"
                                       *ngIf="valForm.controls['Address1'].hasError('required') && (valForm.controls['Address1'].dirty || valForm.controls['Address1'].touched)">
                                    {{'This field is required' | translate}}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-2 offset-bottom10">
                                <div class="input-group col-xs-12 custom-input">
                                  <div class="title">{{'Address 2(optional)' | translate}}:</div>
                                  <input class="form-control fullwidth input-md" type="text"
                                         formControlName="Address2"/>
                                  <div class="text-danger"
                                       *ngIf="valForm.controls['Address2'].hasError('required') && (valForm.controls['Address2'].dirty || valForm.controls['Address2'].touched)">
                                    {{'This field is required' | translate}}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-2 offset-bottom10">
                                <div class="input-group col-xs-12 custom-input">
                                  <div class="title">{{'County(optional)' | translate}}:</div>
                                  <input class="form-control fullwidth input-md" type="text" formControlName="Country"/>
                                  <div class="text-danger"
                                       *ngIf="valForm.controls['Country'].hasError('required') && (valForm.controls['Country'].dirty || valForm.controls['Country'].touched)">
                                    {{'This field is required' | translate}}
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-2 offset-bottom10">
                                <div class="input-group col-xs-12 custom-input">
                                  <div class="title">{{'PostCode' | translate}}:</div>
                                  <input class="form-control fullwidth input-md" type="text"
                                         formControlName="PostCode"/>
                                  <div class="text-danger"
                                       *ngIf="valForm.controls['PostCode'].hasError('required') && (valForm.controls['PostCode'].dirty || valForm.controls['PostCode'].touched)">
                                    {{'This field is required' | translate}}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="row">
                      <div class="col-xs-12">
                        <div class="footer-buttons">
                          <button type="submit" class="btn btn-shop-primary-color  btn-auto" title="save changes">
                            {{'SAVE CHANGES' | translate}}
                          </button>

                          <div class="btn btn-shop-primary-color offset-left10 btn-auto" title="refresh">{{'REFRESH' | translate}}</div>
                        </div>
                      </div>
                    </div>
                    <!-- END panel-->
                  </form>


                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="full_is_busy" style="display: none;">
      <div class="full_is_busy_background"></div>
      <div class="blurred_area"></div>
      <div class="full_is_busy_contents">
        <div class="full_is_busy_title">
          <span class="full_is_busy_title_icon"></span>
          <span class="full_is_busy_title_text">{{'Please wait...' | translate}}</span>
        </div>
        <div class="full_is_busy_message" data-bind="html:Message"></div>
      </div>
    </div>
    <!--/ko-->
    <div class="devices_error">
      <div class="error_message error-effect-jelly" data-bind="visible: IsVisible, css: MessageTypeClass"
           style="display: none;">
        <div class="error_message_icon_close clickable" data-bind="click: CloseButtonClick, clickBubble: false">
          <i class="fa fa-times"></i>
        </div>
        <div class="error_message_text clickable" data-bind="click: CloseButtonClick, clickBubble: false">
          <i class="fa fa-exclamation"></i>
        </div>
        <div class="error-success-progress"></div>
      </div>
    </div>
  </div>
</div>


<div class="success_message">
  <div class="error_message error-effect-jelly confirmation_message" [ngStyle]="{display: isSuccess ? 'block': 'none'}">
    <div class="error_message_icon_close clickable">
      <i class="fa fa-times"></i>
    </div>
    <div class="error_message_text clickable">
      <span class="highlighted">{{'Success' | translate}}!</span>
      <span>{{'Settings Saved' | translate}}</span>
      <i class="fa fa-exclamation"></i>
    </div>
    <div class="error-success-progress"></div>
  </div>
</div>
