<div id="mainContents" style="">
  <div class="container-non-responsive">
    <div class="row height100">
      <div class="wrapper-header" [ngClass]="{'BetslipExpanded': settings.IsBetslipExpanded}">
        <div class="col-xs-left">
          <div class="square-section">
            <img class="menu-logo" src="/assets/images/shopLogo.png">
            <div class="row">
              <div class="col-xs-4">
                <div class="menu-bar" style="display: block;" >
                  <i class="fa fa-bars leftMenuButton" (click)="settings.isMainMenuOpened = !settings.isMainMenuOpened;legueListOpened = false"></i>
                </div>
              </div>

            </div>
          </div>
          <div class="round-section">
            <div class="row">
              <div class="col-xs-12">
                <div class="dropdown"  style="display: block;">
                  <div class="dropdown-toggle">
                    <div class="sport clickable active" *ngIf="sportService.SelcetedSportInfo">
                      <div>
                        <span class="sport-logo_{{sportService.SelcetedSportInfo.Id}}"></span></div>
                      <div class="info-sport-title">{{sportService.SelcetedSportInfo.Name}}</div>
                      <div class="info-sports">
                        <span class="info-prematch">
                            <span class="info-prematch">{{sportService.SelcetedSportInfo.f}}</span>
                            <i class="fa fa-dot-circle-o"></i>
                        </span>
                        <span class="info-inplay">
                            <span class="info-inplay">{{sportService.SelcetedSportInfo.c}}</span>
                            <i class="fa fa-play"></i>
                        </span>
                        <span class="info-outrights">
                            <span class="info-outrights">{{sportService.SelcetedSportInfo.e}}</span>
                            <i class="fa fa-forward"></i>
                        </span>
                      </div>
                    </div>
                    <!--/ko-->
                    <div class="sport-caret clickable" (click)="sportMenuOpened = !sportMenuOpened;legueListOpened = false">
                      <span *ngIf="sportService.SportsList.length > 1">+{{sportService.SportsList.length-1}}</span>
                      <i id="sport-selection-dropdown" class="fa" [ngClass]="{'fa-angle-down': !sportMenuOpened,'fa-angle-up': sportMenuOpened}"></i>
                    </div>
                  </div>
                  <ul class="dropdown-menu dropdown-sport-category dropdown-selections" *ngIf="sportMenuOpened"  (click)="$event.stopPropagation()">
                    <li class="dropdown-title">
                      {{'SPORTS' | translate}}
                    </li>
                    <li class="col-xs-3 clickable" *ngFor="let sport of sportService.SportsList | order: 'Order'">
                      <div class="sport clickable active" (click)="goToSport(sport)">
                        <div class="sport-name">
                          <span style="max-width: calc(100% - 34px);
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: inline-block;" title="{{sport.Name}}">{{sport.Name}}</span>
                          <span class="sport-logo_{{sport.Id}}"></span>
                        </div>
                        <div class=" info-sports">
                                <span class="info-prematch">
                                    <span class="info-prematch" >{{sport.f}}</span>
                                    <i class="fa fa-dot-circle-o"></i>
                                </span>
                          <span class="info-inplay">
                                    <span class="info-inplay">{{sport.c}}</span>
                                    <i class="fa fa-play"></i>
                                </span>
                          <span class="info-outrights">
                                    <span class="info-outrights" >{{sport.e}}</span>
                                    <i class="fa fa-forward"></i>
                                </span>
                        </div>
                      </div>
                    </li>
                    <!--/ko-->
                  </ul>
                </div>
                <!--/ko-->
              </div>
            </div>
          </div><!-- /ko -->

        </div>

        <div class="col-xs-middle" style="display: block;">
          <div class="col-middle market-filter">
            <div class="square-section">

              <div class="left">
                <div class="filter-btn">
                  <a class="btn btn-shop-grey fullwidth" [ngClass]="{active: sportService.OddType === 0}" title="{{'All' | translate}}"  (click)="changeOddType(0)">
                    <div  title="{{'All' | translate}}" class="title-match-type">{{'All' | translate}}
                    </div>
                    <i class="ifa-small ifa-custom fa fa-plus-square-o"></i>
                  </a>
                </div>
                <div class="filter-btn">
                  <a class="btn btn-shop-grey fullwidth"  [ngClass]="{active: sportService.OddType === 0 || sportService.OddType === 2}" title="{{'Pre-match' | translate}}"  (click)="changeOddType(2)">
                    <div class="title-match-type" title="{{'Pre-match' | translate}}">{{'Pre-match' | translate}} </div>
                    <i class="ifa-small ifa-custom fa fa-dot-circle-o"></i>
                  </a>
                </div>
                <div class="filter-btn">
                  <a class="btn btn-shop-grey fullwidth" title="{{'In-play' | translate}}" [ngClass]="{active: sportService.OddType === 0 || sportService.OddType === 1}" (click)="changeOddType(1)">
                    <div class="title-match-type">
                      {{'Live' | translate}}
                    </div>
                    <i class="ifa-small ifa-custom fa fa-play"></i>
                  </a>
                </div>
                <div class="filter-btn">
                  <a class="btn btn-shop-grey fullwidth" title="{{'Outrights' | translate}}" [ngClass]="{active: sportService.OddType === 3}" (click)="changeOddType(3)">
                    <div class="title-match-type" title="{{'Outrights' | translate}}">
                      {{'Outrights' | translate}}
                    </div>
                    <i class="ifa-small ifa-custom fa fa-forward"></i>
                  </a>
                </div>
              </div>


              <div class="calendar" style="height: 47px;">
                <div class="dropdown" [ngClass]="{'open':calendarFilterOpened}">
                  <a id="calendar" class="dropdown-toggle">
                        <span class="calendar-select" (click)="calendarFilterOpened = !calendarFilterOpened">
                            <i class="ifa-block fa fa-calendar"></i>
                            <i id="calendar-clock" class="switch-icon ifa-block fa fa-angle-down" [ngClass]="{'fa-angle-down':!calendarFilterOpened, 'fa-angle-up':calendarFilterOpened}" style="display: block"></i>
                        </span>
                  </a>
                  <ul class="dropdown-menu dropdown-calendar" aria-labelledby="calendar">
                    <ng-template let-item ngFor [ngForOf]="DateFilterList">
                      <li class="dropdown-title" (click)="getFiterMatches(item)">
                        <a title="javascript:;">{{item.Name | translate}}</a>
                      </li>
                    </ng-template>
                  </ul>
                </div>
                <div class="title-selection" >
                  {{(!selectedDateFilter ? 'All' : selectedDateFilter) | translate}}
                </div>
              </div>
            </div>
            <div class="round-section" style="display: block;">
              <div class="row">
                <div class="col-xs-12">
                  <div class="league">
                    <div class="league-selection clickable"  (click)="OpenLeguesList($event)"  id="league_list">
                      <div class="league-w100 league-tab-selected" >
                        <span >{{'All Leagues' | translate}}</span>
                        <a class="close-league" href="/">
                        </a>
                      </div>
                    </div>
                    <div class="clickable dropdown-toggle" [ngClass]="{open: legueListOpened}">
                    <span class="league-caret" (click)="OpenLeguesList($event)">
                        {{sportService.SelcetedSportInfo ? sportService.SelcetedSportInfo.b : 0}}
                        <i class="fa" [ngClass]="{'fa-angle-down': !legueListOpened,'fa-angle-up': legueListOpened}"></i>
                    </span>
                    </div>
                    <ul class="dropdown-league-category dropdown-menu" *ngIf="legueListOpened" (click)="$event.stopPropagation()">
                      <li class="dropdown-title">
                        <div class="col-xs-4">
                          <div class="btn btn-shop-primary-color btn-auto" title="{{'Select all' | translate}}" (click)="clearAllLeagues(false)">{{'Select all' | translate}}
                          </div> 
                          <div class="btn btn-shop-primary-color btn-auto" title="{{'CLEAR ALL' | translate}}"  (click)="clearAllLeagues(true)">{{'CLEAR ALL' | translate}}
                          </div>
                        </div>
                        <div class="col-xs-4">
                          <i class="fa fa-spinner fa-pulse"></i>
                          {{'Categories' | translate}}
                        </div>
                        <div class="col-xs-4">
                          <div class="btn btn-shop-primary-color btn-auto" title="{{'Expand All' | translate}}" (click)="expandAll(true)">{{'Expand All' | translate}}
                          </div> 
                          <div class="btn btn-shop-primary-color btn-auto" title="{{'Collapse All' | translate}}"  (click)="expandAll(false)">{{'Collapse All' | translate}}
                          </div>
                        </div>
                      </li>
                      <li class="dropdown-league-body">
                        <ul class="body-wrapper" *ngIf="sportService.SelcetedSportInfo">
                          <ng-template let-category ngFor [ngForOf]="CategoryList | order: 'd'">
                            <li class="col-xs-3 active" >
                              <div class="league">
                                <span (click)="category.active = !category.active">
                                  <i class="fa league-list-expand fa-caret-down" [ngClass]="!category.active ? 'fa-caret-down' : 'fa-caret-up'"></i>
                                  <img src="./assets/images/flags/{{category.a}}.png"
                                       alt="{{category.c}}" class="flag-32" title="{{category.c}}">
                                  <div class="txt" >
                                    {{category.c}}
                                  </div>
                                </span>
                                <a class="go-league" (click)="FilterByCategory(category)">
                                  GO
                                </a>
                                <ul class="league-list" [ngClass]="{'active':category.active}">
                                  <!-- ko foreach: Leagues -->
                                  <ng-template let-league ngFor [ngForOf]="category.b">
                                    <li (click)="league.noActive = !league.noActive">
                                      <div class="checkBox " [ngClass]="{checked: !league.noActive}"></div>
                                      <div class="txt" title="{{league.b}}">{{league.b}}
                                      </div>
                                    </li>
                                  </ng-template>
                                </ul>
                              </div>
                            </li>
                          </ng-template>

                        </ul>
                      </li>
                      <li class="col-xs-12 league-footer">
                        <div class="btn btn-shop-primary-color" title="{{'OK' | translate}}" (click)="FilterByLegues()">
                          {{'OK' | translate}}
                        </div>
                        <div class="btn btn-shop-primary-color" title="{{'Cancel' | translate}}" (click)="legueListOpened = false">
                          {{'Cancel' | translate}}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <menu-component></menu-component>


      <betslip-component></betslip-component>

      <div class="wrapper-body " [ngClass]="{'WrapperExpanded':!settings.betSlipClosed,'WrapperCollapsed': settings.betSlipClosed,'BetslipExpanded': settings.IsBetslipExpanded}" style="display: block;">
        <tree-component></tree-component>
        <router-outlet></router-outlet>
      </div>

    </div>
  </div>
</div>

