<div class="tab-content">
  <div class="tab-pane active">
  <div>
    <div class="col-xs-left">
      <div class="box">
        <div class="box-header">
          <h2>
            <i class="fa fa-angle-right"></i>
            <span class="break">{{'Todays cash' | translate}}</span>
          </h2>
          <!-- display:none as not implemented yet -->
          <div style="display:none" class="box-icon">
            <a href="#" class="btn-minimize"><i class="fa fa-chevron-up"></i></a>
          </div>
        </div>
        <div class="box-content">
          <div class="filter">
            <div class="filter-header" (click)="openedFilter = !openedFilter" [ngClass]="{'open': openedFilter}">
              <i class="fa fa-filter"></i> {{'Filter' | translate}}
              <span class="fa "  [ngClass]="openedFilter ? 'fa-toggle-on' : 'fa-toggle-off'"></span>
            </div>
            <div class="filter-body collapse in" [ngStyle]="{display: openedFilter? 'block' : 'none'}">

              <select  id="select1" tabindex="0" style="" [(ngModel)]="FilterObj.cashierId">
                <option value="0">{{'All Cashiers' | translate}}</option>
                <ng-template let-cashier ngFor [ngForOf]="CashiersList">
                  <option [value]="cashier.Id">{{cashier.Name}}</option>
                </ng-template>

              </select>
              <select  tabindex="0" style="" id="select2" [(ngModel)]="FilterObj.terminalId">
                <option  value="">{{'All Terminals' | translate}}</option>

                <ng-template let-item ngFor [ngForOf]="TerminalsList">
                  <option [value]="item.Id">{{item.Name}}</option>
                </ng-template>
              </select>
              <select  style="" id="select3" [(ngModel)]="FilterObj.betState">
                <option  value="">{{'All Bets' | translate}}</option>
                <ng-template let-item ngFor [ngForOf]="TransactionList">
                <option [value]="item.Id">{{item.Name | translate}}</option>
              </ng-template>
              </select>
              <!-- ko if: EnableMultiCurrency --><!--/ko-->
              <div class="clearfix"></div>
              <div class="custom-input primary-color">
                <div class='input-group date' id='datetimeFrom'>
                  <input type='text' class="form-control" />
                  <span class="input-group-addon">
                        <span class="glyphicon glyphicon-calendar"></span>
                    </span>
                </div>
              </div>
              <div class="custom-input primary-color">
                <div class="input-group date" id="datetimeTo">
                  <input type="text" class="form-control">
                  <span class="input-group-addon">
                      <span class="fa fa-calendar"></span>
                  </span>
                </div>
              </div>
              <div class="amount-reset">
                <a class="btn btn-shop-primary-color" title="Filter" (click)="getTodayCash()">{{'Filter' | translate}}</a>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="report-warning"></div>
          <div class="report-warning" *ngIf="Data">
            <div class="report_warning_container" *ngIf="!Data.TodaysCash.length">
              <div class="warning_text">
                <div class="title">
                  <i class="fa fa-warning"></i>
                  <span>{{'NO RESULTS FOUND' | translate}}</span>
                </div>
                <div class="description">{{'It seems that your search did not generate any results. Please change your query and then try again.' | translate}}</div>
              </div>
            </div>
            <!--/ko-->
          </div>
          <div class="announcement-msg" >
            <div class="full_is_busy" style="display: none;">
              <div class="full_is_busy_background"></div>
              <div class="blurred_area"></div>
              <div class="full_is_busy_contents">
                <div class="full_is_busy_title">
                  <span class="full_is_busy_title_icon"></span>
                  <span class="full_is_busy_title_text">{{'loading data' | translate}}</span>
                </div>
                <div class="full_is_busy_message"></div>
              </div>
            </div>
            <!--/ko-->
            <!-- ko template: {name: 'error-message', data: ErrorMessage}-->
            <div class="error_message error-effect-jelly"  style="display: none;">
            <div class="error_message_icon_close clickable">
              <i class="fa fa-times"></i>
            </div>
            <div class="error_message_text clickable">
              <i class="fa fa-exclamation"></i>
            </div>
            <div class="error-success-progress"></div>
          </div>
            <!-- /ko -->
          </div>
          <div class="reportsRecordsArea">
            <table class="table table-striped table-bordered" id="todaysTable">
              <thead>
              <tr>
                <th>{{'S/N' | translate}}</th>
                <th>{{'TIME' | translate}}</th>
                <th>{{'Type/Licence' | translate}}</th>
                <th>{{'Amount' | translate}}</th>
                <th>{{'Terminal/Agent' | translate}}</th>
              </tr>
              </thead>
              <tbody *ngIf="Data">
                <ng-template let-item ngFor [ngForOf]="Data.TodaysCash">
                  <tr (click)="selectRow(item)" [ngClass]="{selected: SelectedItem && SelectedItem.BarCode == item.BarCode}">
                    <td >{{item.BarCode}}</td>
                    <td>{{_core.DateFromNow(item.Time)}}</td>
                    <td>
                      <span class="lost">{{TransactionTypes[item.Type]}}</span>
                      <br>
                      <span></span>
                    </td>
                    <td style="text-align: right;">€{{item.Amount}}</td>
                    <td>
                      <span class=" lost">{{item.TerminalName}}</span>
                      <br>
                      <span>{{item.CashierName}}</span>
                    </td>
                  </tr>
                </ng-template>
              </tbody>

            </table>
          </div>
          <div class="footer-buttons-report">
            <div class="btn btn-shop-primary-color btn-auto" *ngIf="TransactionTypes[SelectedItem.Type] =='Bet'" (click)="getBetSlip()">{{'Display Bet Slip' | translate}}</div>
            <div class="btn btn-shop-primary-color btn-auto" (click)="print()" [ngClass]="{disabled: !Data || (Data && !Data.TodaysCash.length)}">{{'Print' | translate}}</div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-xs-right">
      <div class="box offset-bottom0">
        <div class="box-header">
          <h2>
            {{'Summary' | translate}}
          </h2>
        </div>
        <div class="box-content" id="fdsfsafasdfadsfasdfas">
          <!-- main contents -->
          <div class="todays_cash_main_content summary" data-bind="with: Summary">
            <div class="betslip-row" *ngIf="Data">
              <div class="hidden-print">
                <div class="title padd-v5 padd-h5">
                  <div class="pull-left">{{'# of Slips Issued' | translate}}:</div>
                  <div class="pull-right offset-right10">{{Data.SlipsIssued}}</div>
                </div>
                <div class="sum_container-slips">
                  <ul>
                    <!-- ko foreach:SummaryCounters-->
                    <li>
                      <div class="pull-left">{{'# of Stake Issued' | translate}}:</div>
                      <div class="pull-right">{{Data.StakeIssued}}</div>
                    </li>

                    <li>
                      <div class="pull-left">{{'# of Voucher Deposit Issued' | translate}}:</div>
                      <div class="pull-right">0</div>
                    </li>

                    <li>
                      <div class="pull-left">{{'# of Cancel Refund Issued' | translate}}:</div>
                      <div class="pull-right">{{Data.CancelRefundIssued}}</div>
                    </li>
                    <!--/ko-->
                  </ul>
                </div>
              </div>
            </div>
            <div class="betslip-row" *ngIf="Data">
              <div class="title padd-v5 padd-h5">
                <div class="pull-left" >{{'BALANCE' | translate}}:</div>
                <div class="pull-right">{{Data.Balance}}</div>
              </div>
              <div class="sum_container-slips">
                <ul>
                  <li>
                    <div class="pull-left">{{'Stake' | translate}}:</div>
                    <div class="pull-right">{{Data.Stake}}</div>
                  </li>

                  <li>
                    <div class="pull-left">{{'COMMISSION' | translate}}:</div>
                    <div class="pull-right">{{Data.Commission}}</div>
                  </li>

                 <!-- <li>
                    <div class="pull-left">Voucher Deposit:</div>
                    <div class="pull-right">171.00</div>
                  </li>-->

                  <li>
                    <div class="pull-left">{{'Commission Adjustment' | translate}}:</div>
                    <div class="pull-right">{{Data.ComAdjustment}}</div>
                  </li>

                  <li>
                    <div class="pull-left">{{'Cancel Refund' | translate}}:</div>
                    <div class="pull-right">{{Data.CancelRefund}}</div>
                  </li>
                  <!--/ko-->
                  <li>
                    <div class="pull-left">{{'Net Stake' | translate}}:</div>
                    <div class="pull-right">{{Data.NetStake}}</div>
                  </li>
                </ul>
              </div>
              <!--/ko-->
            </div>
            <!--ko if: Commission != '0.00'--><!--/ko-->
            <div class="betslip-row" *ngIf="Data">
              <div class="title padd-v5">
                <div class="text-center" >{{'Unpaid Slips' | translate}}</div>
              </div>
              <div class="sum_container-slips">
                <!-- ko ifnot: EnableMultiCurrency -->
                <ul>
                  <li>
                    <div class="pull-left">{{'Period Unpaid' | translate}}:</div>
                    <div class="pull-right">{{Data.PeriodUnpaid}}</div>
                  </li>
                  <li>
                    <div class="pull-left">{{'# of Won Slips Unpaid' | translate}}:</div>
                    <div class="pull-right">{{Data.PeriodWonSlips}}</div>
                  </li>
                  <li>
                    <div class="pull-left">{{'Total Unpaid' | translate}} *: *:</div>
                    <div class="pull-right">{{Data.WonSlips}}</div>
                  </li>
                  <li>
                    <div class="pull-left">{{'# of Won Slips Unpaid' | translate}}:</div>
                    <div class="pull-right">{{Data.Unpaid}}</div>
                  </li>
                </ul>
                <!--/ko-->
                <!-- ko if: EnableMultiCurrency --><!-- /ko -->
              </div>
            </div>
          </div>
         <!-- <div class="text-center offset-bottom10">
            <div class="btn btn-shop-primary-color btn-auto" data-bind="text: 'PRINT SUMMARY'.translate(), click: cmdPrintSummary, clickBubble: false">PRINT SUMMARY</div>
          </div>-->
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
  <div class="announcement-msg">
    <div class="error_message error-effect-jelly" style="display: none;">
    <div class="error_message_icon_close clickable">
      <i class="fa fa-times"></i>
    </div>
    <div class="error_message_text clickable">
      <i class="fa fa-exclamation"></i>
    </div>
    <div class="error-success-progress"></div>
  </div>
  <div class="full_is_busy" *ngIf="isLoading">
    <div class="full_is_busy_background"></div>
    <div class="blurred_area"></div>
    <div class="full_is_busy_contents">
      <div class="full_is_busy_title">
        <span class="full_is_busy_title_icon"></span>
        <span class="full_is_busy_title_text">{{'Please wait...' | translate}}</span>
      </div>
      <div class="full_is_busy_message"></div>
    </div>
  </div>
  </div>
</div>
</div>

<app-todays-print id="asdfg" #todayPrint style="display: none" ></app-todays-print>
<popup-betslip [betslipData]="BetInfo" *ngIf="BetInfo" (closeEvent)="RemoveBetslipData()"></popup-betslip>
