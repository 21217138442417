<html><head></head><body>
<div class="print_container" *ngIf="printData" id="asdfg">

  <div id="modal_header" class="header">
    <div class="float_left">
      {{'Todays Cash' | translate}}
    </div>
  </div>

  <div class="print_header">
    <div class="print_logo">
      <img id="receipt_logo" src="/receiptLogo" alt="">
    </div>
    <div class="print_title">{{'RECEIPTS ANALYSIS' | translate}}</div>
    <div class="date_printed">
      <div class="left">{{'Printed On' | translate}}</div>
      <div class="right">{{printData.PrintedOn}}</div>
    </div>
    <div class="date_from">
      <div class="left">{{'Date From' | translate}}</div>
      <div class="right">
        <span>{{printData.DateFrom | date: 'dd-MM-yyy'}}</span>
      </div>
    </div>
    <div class="date_to">
      <div class="left">{{'Date To' | translate}}</div>
      <div class="right">
        <span>{{printData.DateTo | date: 'dd-MM-yyy'}}</span>
      </div>
    </div>
  </div>
  <div class="print_body">
    <ng-template let-item ngFor [ngForOf]="printData.BetSlips">
      <div class="table">
        <div class="header total_stake">{{'COMMISSION' | translate}}</div>
        <div class="headers">
          <div class="row">
            <div class="column1">{{'Betslip Id' | translate}}</div>
            <div class="column2">{{'Date' | translate}}</div>
            <div class="column3">{{'Amount' | translate}}</div>
          </div>
        </div>
        <ng-template let-bodyItem ngFor [ngForOf]="item.Results">
          <div class="row">
            <div class="column1" >{{bodyItem.BarCode}}</div>
            <div class="column2">{{bodyItem.Date | date: 'dd-MM-yyyy'}}</div>
            <div class="column3">{{bodyItem.Amount}}</div>
          </div>
        </ng-template>
        <!--/ko-->
        <br>
        <br>
        <div class="totals">
          <div class="left">
          </div>
          <div class="right">
            <span>{{'Total Amount' | translate}}: </span>
            <span>{{item.TotalAmount}}</span>
          </div>
          <div class="fo"></div>
        </div>
      </div>
    </ng-template>

    <div class="todays_cash_summary">
      <div class="header total_stake">{{'Summary' | translate}}</div>
      <br>
      <br>
      <ul>
        <li class="header">
          <div class="left">{{'COMMISSION' | translate}}:</div>
          <div class="right">{{printData.Commission}}</div>
        </li>

        <li class="header">
          <div class="left">{{'Stake' | translate}}:</div>
          <div class="right">{{printData.Stake}}</div>
        </li>
        <!--/ko-->
        <li class="header">
          <div class="left">{{'Net Stake' | translate}}:</div>
          <div class="right">{{printData.NetStake}}</div>
        </li>
      </ul>
      <ul>
        <li class="header balance">
          <div class="left">{{'Cash Balance' | translate}}:</div>
          <div class="right">{{printData.DateFrom | date: 'dd-MM-yyy'}}</div>
        </li>
      </ul>
      <ul>
        <li class="header">
          <div class="left">{{'COMMISSION' | translate}}:</div>
          <div class="right">{{printData.Commission}}</div>
        </li>
      </ul>
      <ul>
        <li class="header">
          <div class="left">{{'Period Unpaid' | translate}}:</div>
          <div class="right">{{printData.PeriodUnpaid}}</div>
        </li>
        <li class="not_in_print">
          <div class="left">{{'# of Won Slips Unpaid' | translate}}:</div>
          <div class="right">{{printData.PeriodWonSlips}}</div>
        </li>
      </ul>
      <ul>
        <li class="header">
          <div class="left">{{'Total Unpaid' | translate}} *: *:</div>
          <div class="right">{{printData.WonSlips}}</div>
        </li>
        <li class="not_in_print">
          <div class="left">{{'# of Won Slips Unpaid' | translate}}:</div>
          <div class="right">{{printData.Unpaid}}</div>
        </li>
      </ul>
    </div>
  </div>
</div>
</body>
