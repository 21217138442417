import { Component } from '@angular/core';
import {Settings} from './core/services/settings';
import {TranslatorService} from './core/services/translator/translator.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'g';
  constructor(
    public settings: Settings,
    private Translator: TranslatorService,
  ) {

  }
}
