<div class="tab-content">
  <div class="tab-pane active">
  <div>
    <div class="col-xs-left">
      <div class="box">
        <div class="box-header">
          <h2>
            <i class="fa fa-angle-right"></i>
            <span class="break">{{'Statement Totals' | translate}}</span>
          </h2>
        </div>
        <div class="box-content collapse in">
          <div class="filter">
            <div class="filter-header" (click)="openedFilter = !openedFilter" [ngClass]="{'open': openedFilter}">
              <i class="fa fa-filter"></i> {{'Filter' | translate}}
              <span class="fa "  [ngClass]="openedFilter ? 'fa-toggle-on' : 'fa-toggle-off'"></span>
            </div>
            <div class="filter-body collapse in" [ngStyle]="{display: openedFilter? 'block' : 'none'}">
              <select id="select1" [(ngModel)]="FilterObj.cashierId" (change)="ssd($event)" >
                <option  value="">{{'All Users' | translate}}</option>
                <option *ngFor="let cashier of CashiersList"  [value]="cashier.Id">{{cashier.Name}}</option>
              </select>
              <select id="select2" [(ngModel)]="FilterObj.terminalId">
                <option value="">{{'All Terminals' | translate}}</option>
                <option *ngFor="let terminal of TerminalsList"  [value]="terminal.Id">{{terminal.Name}}</option>
              </select>
              <div class="clearfix"></div>
              <div class="custom-input primary-color">
                <div class="input-group date"  id="datetimeStatementFrom">
                  <input type="text" class="form-control" >
                  <span class="input-group-addon">
                      <span class="fa fa-calendar"></span>
                  </span>
                </div>
              </div>
              <div class="custom-input primary-color">
                <div class="input-group date"  id="datetimeStatementTo">
                  <input type="text" class="form-control">
                  <span class="input-group-addon">
                      <span class="fa fa-calendar"></span>
                  </span>
                </div>
              </div>
              <div class="amount-reset">
                <a class="btn btn-shop-primary-color" (click)="getReport()" title="Filter">{{'Filter' | translate}}</a>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>

          <div class="reportsRecordsArea">
            <table class="table table-striped table-bordered">
              <thead>
              <tr>
                <th>{{'Integration Type' | translate}}</th>
                <th>{{'Number of betslips' | translate}}</th>
                <th>{{'Net Stake' | translate}}</th>
                <th>{{'Winnings' | translate}}</th>
                <th>{{'COMMISSION' | translate}}</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngIf="Data.BetSleeps">
                <td title="{{Data.IntegretionType}}">{{Data.IntegretionType}}</td>
                <td title="{{Data.BetSleeps}}">{{Data.BetSleeps}}</td>
                <td style="text-align: right;">€{{Data.NetStake || 0 | number: '1.2'}}</td>
                <td  style="text-align: right;">€{{Data.Winnings || 0 | number: '1.2'}}</td>
                <td style="text-align: right;">€{{Data.Commission || 0 | number: '1.2'}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
    <div class="col-xs-right">
      <div class="box offset-bottom0">
        <div class="box-header">
          <h2>{{'Statement' | translate}}</h2>
          <div style="display:none" class="box-icon">
            <a href="#" class="btn-minimize"><i class="fa fa-chevron-up"></i></a>
            <a href="#" class="btn-close"><i class="fa fa-times"></i></a>
          </div>
        </div>
        <div class="box-content">
          <div class="statement_container summary">
            <div class="betslip-row">
              <div class="statement-row">
                <div class="pull-left">{{'Net Stake' | translate}}</div>
                <div class="pull-right">{{Data.NetStake | number: '1.2'}}</div>
              </div>
            </div>
            <div class="betslip-row">
              <div class="statement-row">
                <div class="pull-left">{{'Winnings' | translate}}</div>
                <div class="pull-right">{{Data.Winnings | number: '1.2'}}</div>
              </div>
            </div>
            <div class="betslip-row">
              <div class="statement-row">
                <div class="pull-left">{{'COMMISSION' | translate}}</div>
                <div class="pull-right">{{Data.Commission | number: '1.2'}}</div>
              </div>
            </div>
            <div class="betslip-row">
              <div class="statement-row">
                <div class="pull-left">{{'PROFIT' | translate}}</div>
                <div class="pull-right">{{Data.Profit | number: '1.2'}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="full_is_busy" *ngIf="isLoading">
      <div class="full_is_busy_background"></div>
      <div class="blurred_area"></div>
      <div class="full_is_busy_contents">
        <div class="full_is_busy_title">
          <span class="full_is_busy_title_icon"></span>
          <span class="full_is_busy_title_text">{{'loading data' | translate}}</span>
        </div>
        <div class="full_is_busy_message"></div>
      </div>
    </div>
    <div class="error_message error-effect-jelly" style="display: none;">
      <div class="error_message_icon_close clickable">
        <i class="fa fa-times"></i>
      </div>
      <div class="error_message_text clickable">
        <i class="fa fa-exclamation"></i>
      </div>
      <div class="error-success-progress"></div>
    </div>
  </div>
</div>
</div>
