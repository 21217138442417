<div class="dashboard-middleContent">
  <div class="row">
    <div class="col-xs-12">
      <div class="page-header">
        <div class="nav-tabs">
          <div class="active">
            <span>{{'BET CODES' | translate}}</span>
          </div>
        </div>
      </div>

      <!-- Tab panes -->
      <div class="row tab-content">
        <div class="col-xs-12">
          <div class="box">
            <div class="box-header">
              <h2>
                <i class="fa fa-qrcode"></i>
                <span class="break"></span>
                <span>{{'BET CODES' | translate}}</span>
              </h2>
            </div>
            <div class="box-content">
              <div class="omr-codes">
                <div class="description">{{'Sport' | translate}}:</div>
                <div class="selectric-wrapper">
                  <select class="form-control fullwidth input-md"  >
                    <option value="">{{'Select Sport' | translate}}</option>
                    <option [value]="item.Id" *ngFor="let item of SportList">{{item.Name}}</option>
                  </select>
                </div>
                <div class="btn-custom">
                  <a class="btn btn-shop-primary-color btn-auto" (click)="printTable()">{{'Print OMR Codes' | translate}}</a>
                </div>
                <div class="clearfix"></div>
              </div>
              <div class="reportsRecordsArea bet-codes">
                <div class="title-notification"  *ngIf="!selectedSport.Id">{{'Select a sport' | translate}}...</div>
                <table class="table table-striped table-bordered"  id="printTable" *ngIf="selectedSport.Id" cellpadding="3" border="1">
                  <thead>
                  <tr>
                    <th >{{'Event Name' | translate}}</th>
                    <th>{{'Event Item Name' | translate}}</th>
                    <th >{{'Bet code' | translate}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let betCode of BetCodesList">
                    <td >{{betCode.MarketName}}</td>
                    <td >{{betCode.SelectionName}}</td>
                    <td >{{betCode.Code}}</td>
                  </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<div class="full_is_busy" *ngIf="loading">
  <div class="full_is_busy_background"></div>
  <div class="blurred_area"></div>
  <div class="full_is_busy_contents">
    <div class="full_is_busy_title">
      <span class="full_is_busy_title_icon"></span>
      <span class="full_is_busy_title_text">{{'Please wait...' | translate}}</span>
    </div>
    <div class="full_is_busy_message"></div>
  </div>
</div>
  <!--/ko-->
</div>
