import {Component, OnInit} from '@angular/core';
import * as models from '../../../../core/models/models.model';
import * as enums from '../../../../core/enums/consts';
import {Settings} from '../../../../core/services/settings';
import {CoreService} from '../../../../core/services/core.service';
import {RequestsService} from '../../../../core/services/requests/requests.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'account-statement',
  templateUrl: './account-statement.component.html'
})

export class AccountStatementComponent implements OnInit {
  public TransactionTypes = enums.TransactionTypes;
  public SelectedRowId = 0;
  public openedFilter = true;
  public isLoading = false;
  public FilterObj = {
    fromDate: moment().set({hour: 0, minute: 0, second: 0, millisecond: 0}),
    toDate: moment().set({hour: 23, minute: 59, second: 59, millisecond: 0}),
    cashierId: '',
    terminalId: '',
    betState: '',
  };
  public Data: models.AccountStatmnetModel;
  public TerminalsList = [];
  public CashiersList = [];
  public BetInfo: any;
  public SelectedItem = {};

  constructor(
    private settings: Settings,
    private http: RequestsService,
    public _core: CoreService
  ) {
    this.http.httpGet(this.settings.apiUrls.account.GetShopTerminals).subscribe((e: any) => {
      if (!e.HasError) {
        this.TerminalsList = e.Data;
        setTimeout(() => {
          $('#select2').selectric();
        }, 100);
      }
    });
    this.http.httpGet(this.settings.apiUrls.account.GetShopCashiers).subscribe((e: any) => {
      if (!e.HasError) {
        this.CashiersList = e.Data;
        setTimeout(() => {
          $('#select1').selectric();
        }, 100);
      }
    });
    setTimeout(() => {
      $('#select3').selectric();
    }, 100);
  }

  ngOnInit() {
    $('select').selectric();
    $('#datetimeFrom').datetimepicker({format: 'dddd, MMM Do YYYY, HH:mm', defaultDate: this.FilterObj.fromDate}).on('dp.change', (e) => {
      this.FilterObj.fromDate = e.date ? new Date(e.date.format()) : '';
    });
    $('#datetimeTo').datetimepicker({format: 'dddd, MMM Do YYYY, HH:mm', defaultDate: this.FilterObj.toDate}).on('dp.change', (e) => {
      this.FilterObj.toDate = e.date ? new Date(e.date.format()) : new Date();
    });
  }

  getData() {
    this.isLoading = true;
    this.http.httpPost(this.settings.apiUrls.reports.CashierStatement, this.FilterObj).subscribe((e: any) => {
      if (!e.HasError) {
        this.Data = e.Data;
      }
      this.isLoading = false;
    });
  }


  selectRow(item) {
    this.SelectedItem = item;
  }

  RemoveBetslipData() {
    this.BetInfo = null;
  }
}
