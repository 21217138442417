import {EventEmitter, Injectable, NgZone, OnInit} from '@angular/core';
import {Settings} from '../settings';
import {closeSportTypes} from '../../enums/consts';
import {ChannelService} from '../signalr/signalr.service';
import {HttpClient} from '@angular/common/http';
// import * as _ from 'lodash';

import {BetSlipService} from '../bet-slip/bet-slip.service';
import {CoreService} from '../core.service';
import {RequestsService} from '../requests/requests.service';
import {Observable} from 'rxjs';
import {tymeZones} from '../../enums/country';
import {MarketsModel} from '../../models/match.model';
import {Route, Router} from '@angular/router';

declare var moment: any;
declare var _: any;

@Injectable({
  providedIn: 'root',
})
export class SportService {
  public Country = tymeZones;
  public timeZone = tymeZones[localStorage.getItem('timeZone') ? localStorage.getItem('timeZone') : 0].GMT;
  public SelcetedSportInfo: any;
  public ConnectionStarted: boolean;
  public SportsList = [];
  public GroupList = [];
  public CDEvent: EventEmitter<any>;
  public addedToBetSlip: EventEmitter<any>;
  public MatchesList = [];
  public activeMatchId = 0;
  public activeSportId = 0;
  public OddType = 0;
  public TotalCount = 0;
  public VisibleCount = Math.floor((window.innerHeight - 298) / 54);

  public PagesCount = 1;
  public PageIndex = 1;
  public PagingArray = [];
  private VisiblePages = 4;
  public StartPage = 3;
  public MoveLeftRight = true;
  public LeaguesList = [];
  public dateFilter = 0;
  public isActiveEventsLoader = true;
  public groupGetEvent = new EventEmitter();
  public groupSuccessEvent = new Observable((observer) => {
    if (this.GroupList.length) {
      observer.next(this.GroupList);
      observer.complete();
    }
  });

  constructor(
    public settings: Settings,
    public route: Router,
    public core: CoreService,
    private zone: NgZone,
    private http: RequestsService,
    private betSlipService: BetSlipService
  ) {
    if (localStorage.getItem('OddType')) {
      this.OddType = Number(localStorage.getItem('OddType')) || 0;
    }
    // this.groupSuccessEvent.j
    if (window.innerWidth < 1248) {
      this.VisibleCount = Math.floor((window.innerHeight - 375) / 54);
    }
    if (localStorage.getItem('activeSportId')) {
      this.activeSportId = Number(localStorage.getItem('activeSportId'));
    }
    if (localStorage.getItem('PageIndex')) {
      this.PageIndex = Number(localStorage.getItem('PageIndex'));
    }
    this.CDEvent = new EventEmitter();
    this.addedToBetSlip = new EventEmitter();
    /*this.settings.sportConn = new ChannelService();*/
    this.settings.sportConn.init(this.settings.sigRUrl + 'signalr', 'RetailOddsHub');
    this.settings.sportConn.start();


    this.settings.sportConn.starting$.subscribe((e1) => {
      this.ConnectionStarted = true;
      this.settings.ConnectionStarted = true;
      this.settings.sportConn.hubProxy.on('SuspendMatch', (e) => {
        this.SuspendMatch(e);
      });
      this.settings.sportConn.hubProxy.on('OddChange', (e) => {
        this.oddChanged(e);
      });
      this.settings.sportConn.hubProxy.on('liveInfo', (e) => {
        this.liveInfoChanged(e);
      });

      this.settings.sportConn.hubProxy.on('NewMarket', (e) => {
        this.newMarketEvent(e);
      });
      this.settings.sportConn.hubProxy.on('marketsCount', (e) => {
        this.zone.run(() => {
          this.MatchesList.forEach((item, index) => {
            if (item.Id === e.l) {
              item.y = e.y;
            }
          });
        });
      });


      this.settings.sportConn.hubProxy.on('SportInfo', (e) => {
        this.updateSportInfo(e);
      });

      this.settings.sportConn.hubProxy.on('closeSportEntity', (e) => {
        if (e.Change.Type === closeSportTypes.Match) {
          if (Number(this.activeSportId) === e.SportId) {
            return this.getMatchesAfterMatchClosed();
          }
        }

        if (e.Change.Type === closeSportTypes.Category || e.Change.Type === closeSportTypes.Tournament) {
          this.getSportsInfo();
        }

      });


      /* this.settings.sportConn.hubProxy.on('UpdMatchOdds', (e: any) => {
         const obj = e.Matches;
         for (let i = 0, j = obj.length; i < j; i++) {
           const ind = _.findLastIndex(this.MatchesList, {Id: obj[i].Id});
           if (ind === -1) {
             this.MatchesList.push(obj[i]);
           }
         }
         const matchesList = JSON.parse(JSON.stringify(this.MatchesList));
         for (let i = 0, j = this.MatchesList.length; i < j; i++) {
           const ind = _.findLastIndex(obj, {Id: matchesList[i].Id});
           const ind1 = _.findLastIndex(obj, {Id: matchesList[i].Id});
           if (ind === -1) {
             this.MatchesList.splice(i, 1);
           }
         }
         this.CDEvent.emit(true);
       });*/
      this.GetSports();

    });

    this.betSlipService.RemoveSelection.subscribe((res) => {
      const match = _.find(this.MatchesList, {Id: res.matchId});
      if (match) {
        const market = _.find(match.Markets, {f: res.marketId});
        if (market) {
          const selection = _.find(market.j, {c: res.selectionId});
          if (selection) {
            selection.selected = false;
          }
        }
      }
    });
    this.betSlipService.AddToBSlipEvent.subscribe((res) => {
      const match = _.find(this.MatchesList, {Id: res.matchId});
      if (match) {
        const market = _.find(match.Markets, {f: res.marketId});
        if (market) {
          const selection = _.find(market.j, {c: res.selectionId});
          if (selection) {
            selection.selected = true;
          }
        }
      }
    });
  }

  updateSportInfo(ev) {
    this.zone.run(() => {

      this.SportsList = ev;
      const sportObj = _.find(ev, {Id: Number(this.activeSportId)});
      if (sportObj) {
        this.SelcetedSportInfo = sportObj;
      } else {
        this.SelcetedSportInfo = ev[0].Id;
        this.route.navigate(['/sport', ev[0].Id]);
      }
    });
  }

  chackselectedSelections() {
    if (this.betSlipService.BetSlipList.length) {
      this.betSlipService.BetSlipList.forEach((item, index) => {
        const match = _.find(this.MatchesList, {Id: item.matchId});
        if (match) {
          const market = _.find(match.Markets, {f: item.marketId});
          if (market) {
            const selection = _.find(market.j, {c: item.selectionId});
            if (selection) {
              selection.selected = true;
            }
          }
        }
      });
    }
  }

  private newMarketEvent(ev) {
    if (!ev.Change.b.length) {
      return false;
    }
    _.find(this.MatchesList, (match) => {
      if (Number(match.Id) === Number(ev.EventId)) {
        this.zone.run(() => {
          match.Markets.push(ev.Change);
        });
      }
    });
  }

  private oddChanged(evnt) {
    if (this.MatchesList && this.MatchesList.length) {
      _.find(this.MatchesList, (match) => {
        if (match.Id == evnt.EventId) {
          _.find(match.Markets, (market) => {
            if (market.f == evnt.Change.f) {
              if (evnt.Change.MarketChanges && evnt.Change.MarketChanges.length) {
                evnt.Change.MarketChanges.forEach((item, key) => {
                  if (item.Key === 'Show') {
                    market.Show = item.Value == '0' ? false : true;
                  } else {
                    market[item.Key] = item.Value;
                  }
                });
              }
              evnt.Change.SelectionChanges.forEach((s, sI) => {
                if (s.IsNew) {
                  market.j.push({
                    c: s.Id,
                    Id: s.SelectionId,
                    Name: s.Name,
                    Odd: s.Odd,
                    Order: s.Order,
                  });
                }
                if (s.Change) {
                  _.find(market.j, (selection) => {
                    if (selection.c === s.Id) {
                      s.Change.forEach((sIt, sInd) => {
                        if (sIt.Key === 'Odd' && sIt.Value !== selection.Odd) {
                          if (sIt.Value > selection.Odd) {
                            this.core.setTimeoutToSelection(selection, 'Up', sIt.Value);
                          }
                          if (sIt.Value < selection.Odd) {
                            this.core.setTimeoutToSelection(selection, 'Down', sIt.Value);
                          }
                        }

                        if (sIt.Key === 'e') {
                          selection.e = Number(sIt.Value);
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  }

  private SuspendMatch(evnt) {
    if (this.MatchesList && this.MatchesList.length && Number(this.activeSportId) === evnt.SportId) {
      _.find(this.MatchesList, (match) => {
        if (Number(match.Id) === Number(evnt.EventId)) {
          match.Markets.forEach((market: MarketsModel, mKey) => {
            this.zone.run(() => {
              market.Show = false;
            });
          });
        }
      });
    }
  }


  private liveInfoChanged(evnt) {


    if (evnt) {
      // evnt.forEach((item, key) => {
      if (this.MatchesList.length && this.activeSportId == evnt.SportId) {

        this.zone.run(() => {

          _.find(this.MatchesList, (match) => {
            if (match.Id == evnt.EventId) {
              this.zone.run(() => {
                match.o = true;
                match.IsBreak = evnt.Change.IsBreak;
                match.ac = evnt.Change.ac;
                match.ai = evnt.Change.ai;
                match.HF = evnt.Change.HF;
                match.ah = evnt.Change.ah;
                match.IsBreack = evnt.Change.IsBreack;
                match.k = evnt.Change.k;
                match.b = evnt.Change.b;
                match.q = evnt.Change.q;
                match.i = evnt.Change.i;
                match.ad = evnt.Change.ad;
                match.n = evnt.Change.n;
                match.ag = evnt.Change.ag;
              });
            }
          });
          if (evnt.Change.IsSendToLive) {
            return this.getMatchesAfterMatchClosed();
          }
        });
        /* else {

          /!*_.find(this.MatchesList, (match) => {
            if (match.Id == evnt.EventId) {
              this.zone.run(() => {
                match.IsBreak = evnt.Change.IsBreak;
                match.ac = evnt.Change.ac;
                match.ai = evnt.Change.ai;
                match.HF = evnt.Change.HF;
                match.ah = evnt.Change.ah;
                match.IsBreack = evnt.Change.IsBreack;
                match.k = evnt.Change.k;
                match.b = evnt.Change.b;
                match.q = evnt.Change.q;
                match.i = evnt.Change.i;
                match.ad = evnt.Change.ad;
                match.n = evnt.Change.n;
                match.ag = evnt.Change.ag;
              });
            }
          });*!/
        }*/
      }
      // });
    }

  }

  getSportsInfo() {
    if (this.activeSportId) {
      this.settings.signalREvent.subscribe((e) => {
        this.http.httpGet(`${this.settings.apiUrls.book.sportApi}${this.activeSportId}/info?languageId=${this.settings.activeLang}`)
          .subscribe((res: any) => {
            if (!res.HasError && res.Data) {
              this.SelcetedSportInfo = res.Data;
            }
          });
        this.http.httpGet(`${this.settings.apiUrls.book.sportApi}${this.activeSportId}/marketgroups?languageId=${this.settings.activeLang}`)
          .subscribe((res: any) => {
            if (!res.HasError && res.Data) {
              if (res.Data.length && res.Data[0].SportId === Number(this.activeSportId) || !res.Data.length) {
                this.GroupList = res.Data;
                this.groupGetEvent.emit(res.Data);

              }
            }
          });

      });
      this.GetMatchWithOdd();

    }
  }

  GetSports() {
    /* this.http.get('assets/server/matches.json').subscribe((e: any) => {
       this.MatchesList = e;
     });*/
    this.http.httpGet(this.settings.apiUrls.book.sports + this.settings.activeLang).subscribe((e: any) => {
      if (!e.HasError && e.Data) {
        this.SportsList = e.Data;
        if (this.SportsList.length) {
          if (!this.activeSportId) {
            const spList = _.orderBy(this.SportsList, 'Order');
            this.activeSportId = this.SportsList[0].Id;
            localStorage.setItem('activeSportId', this.activeSportId.toString());
          }

          if (this.activeSportId && this.SportsList) {
            const sportObj = _.find(this.SportsList, {Id: Number(this.activeSportId)});
            if (!sportObj) {
              this.SelcetedSportInfo = this.SportsList[0].Id;
              this.route.navigate(['/sport', this.SportsList[0].Id]);
            }
          }
          this.getSportsInfo();
        }
      }
    });
  }

  GetMatchWithOdd(filter?) {
    if (filter) {
      this.PageIndex = 1;

      localStorage.setItem('PageIndex', '1');
      this.PagingArray.length = 0;
      this.MatchesList.length = 0;
    }
    const obj = {
      LanguageId: this.settings.activeLang,
      SportId: this.activeSportId,
      PageIndex: this.PageIndex,
      PageSize: this.VisibleCount,
      OddType: this.OddType,
      Leagues: this.LeaguesList,
      Filter: !filter ? '' : filter,
      DateFilter: this.dateFilter,
      EventId: 0,
      ConnectionId: ''
    };

    this.isActiveEventsLoader = true;
    if (this.settings.ConnectionStarted) {
      obj.ConnectionId = this.settings.sportConn.connectionId;

      this.http.httpPost(this.settings.apiUrls.book.matchwithodds, obj)
        .subscribe((res: any) => {
          this.isActiveEventsLoader = false;
          if (res && res.Data) {
            this.MatchesList = res.Data.Events;
            this.MatchesList.forEach((item, key) => {
              if (item.af.indexOf('Z') === -1) {
                item.af = item.af + 'Z';
              }
              item.af = moment(item.af).utcOffset(this.timeZone).format('YYYY-MM-DD HH:mm');
            });
            this.MakePaging(res.Data.TotalCount);
            this.chackselectedSelections();

            if (this.OddType === 3){
              this.route.navigate(['/game', this.MatchesList[0].Id]);
            }

          }
          // setTimeout(() => {


          //    }, 100);

        });
    }


  }

  getMatchesAfterMatchClosed(filter?) {
    if (filter) {
      this.PageIndex = 1;
      localStorage.setItem('PageIndex', '1');
      this.PagingArray.length = 0;
      this.MatchesList.length = 0;
    }
    const obj = {
      LanguageId: this.settings.activeLang,
      ConnectionId: '',
      SportId: this.activeSportId,
      PageIndex: this.PageIndex,
      PageSize: this.VisibleCount,
      OddType: this.OddType,
      Leagues: this.LeaguesList,
      Filter: !filter ? '' : filter,
      DateFilter: this.dateFilter,
      EventId: 0
    };

    if (this.settings.ConnectionStarted) {
      obj.ConnectionId = this.settings.sportConn.connectionId;

      this.http.httpPost(this.settings.apiUrls.book.updmatchwithodds, obj)
        .subscribe((res: any) => {
          if (res && res.Data) {
            this.MakePaging(res.Data.TotalCount);
            const obj = res.Data.Events;
            for (let i = 0, j = obj.length; i < j; i++) {
              const ind = _.findLastIndex(this.MatchesList, {Id: obj[i].Id});
              if (ind === -1) {
                this.MatchesList.push(obj[i]);
              }
            }
            const matchesList = JSON.parse(JSON.stringify(this.MatchesList));
            for (let i = 0, j = this.MatchesList.length; i < j; i++) {
              const ind = _.findLastIndex(obj, {Id: matchesList[i].Id});
              const ind1 = _.findLastIndex(obj, {Id: matchesList[i].Id});
              if (ind === -1) {
                this.MatchesList.splice(i, 1);
              }
            }

            this.zone.run(() => {
            });
          }

        });
    }


  }


  MakePaging(TotalCount) {
    this.PagingArray = [];
    this.TotalCount = TotalCount;
    this.PagesCount = Math.ceil(TotalCount / this.VisibleCount);
    this.VisiblePages = this.PagesCount < 4 ? this.PagesCount : 4;
    // for (let i = 0; i < 4; i++) {
    const start = this.PagesCount < 4 ? 1 : this.calculateStartPageNumber(this.MoveLeftRight);
    for (let s = 1, l = start; s <= this.VisiblePages && l <= this.PagesCount; s++, l++) {
      this.PagingArray.push(l);
    }
    //  }
  }

  calculateStartPageNumber(type?) {

    const e = this.VisiblePages;
    const r = this.PageIndex;
    let n = 1;
    const a = Math.ceil(e / 2);
    const o = a % 2 == 1;
    const i = this.TotalCount;
    return i > e && r > a && r < i ? n = o ? r - a - 1 : this.MoveLeftRight ? r - a : r - a + 1 : i > e && r > a && (n = r - (a + i - r)), n;
  }

  newArray(length) {
    return new Array(length);
  }
}
