<html>
<head></head>
<body>
<div class="full_window betslip-modal not_in_ui" style="position:relative; width:100%; height:100%;-webkit-print-color-adjust:exact;">

  <!-- ko if: typeof CefSharpApp === 'undefined' -->
  <style type="text/css">

  </style>
  <!--/ko-->
  <div class="body myaccount_betslip" *ngIf="betData" >
    <div class="betslip_header">
      <div class="betslip_header_print_logo">
        <img id="receipt_logo" src="/assets/images//printLogo.png" alt="">
      </div>
    </div>
    <div class="betslip_print_configurations">
      <div class="shopAddress">{{betData.ShopAddress1}}</div>
      <div class="shopEmail">{{betData.ShopEmail}}</div>
      <div class="shopTelephone">{{betData.ShopPhone}}</div>
    </div>
    <div class="copy_label not_in_print">COPY</div>
    <div class="not-valid_label" *ngIf="settings.guestMode">
      <div class="txt">
        <div >{{'ΑΚΥΡΟ' | translate}}</div>
        <div >{{'NOT VALID' | translate}}</div>
      </div>
      <div class="txt">
        <div >{{'ΑΚΥΡΟ' | translate}}</div>
        <div>{{'NOT VALID' | translate}}</div>
      </div>
      <div class="txt">
        <div>{{'ΑΚΥΡΟ' | translate}}</div>
        <div>{{'NOT VALID' | translate}}</div>
      </div>
    </div>
    <div id="betslip_body" class="betslip-body">
      <div class="myaccount_betslip_details">
        <br style="display: inline;">

        <div class="place-info">
          <div class="bet_placed_on">T{{TerminalId}}</div>
        </div>

        <br>

        <div class="barcode_container">
          <span class="bet_id">{{'Bet ID' | translate}}</span>
          <div class="barcode_short_id">{{betData.BarCode}}</div>
        </div>
        <div class="place-info">
          <div class="bet_placed_on">{{'Bet Placement Date' | translate}}</div>
          <div class="bet_placed_on_value value number">{{betData.CreateDate | date: 'dd/MM/yyyy HH:mm'}}</div>
        </div>
      </div>
      <div class="betslip_tab_body height100">
        <div class="height100">
          <div class="betslip_box height100">
            <div class="modal_window betslip_scrollbar_container accumulator-odds-enabled bonus-winnings-enabled">
              <div class="betslip_title events_selection">{{'Events Selections' | translate}} ({{betData.Selections.length}})</div>
              <ul id="betslip_selections" class="boxed Placed">
                <ng-template ngFor let-selection [ngForOf]="betData.Selections">
                  <li data-bind="css:BetItemStyle" class="betslip_selection">
                    <div class="betslip_selection_first_line">
                      <div class="betslip_selection_first_line_number">{{selection.Index}}. </div>
                      <div class="betslip_selection_first_line_market">
                        <div class="betslip_selection_first_line_market_row1">
                          <div class="betslip_selection_first_line_category" title="Hungary - NB I">{{selection.Region}} - {{selection.Competition}}</div>
                          <div class="betslip_selection_first_line_" style="float: left;"> - </div>
                          <div class="betslip_selection_first_line_date">{{core.dateUtc(selection.StartDate) | date: 'dd/MM HH:mm'}}</div>
                        </div>
                        <div class="betslip_selection_first_line_market_row2">
                          <div class="betslip_selection_first_line_market_row2_marketName" style="font-weight:700" title="{{selection.EventId}} {{selection.EventName}}">{{selection.EventId}} {{selection.EventName}}</div>

                        </div>
                      </div>
                      <div class="clearboth"></div>
                    </div>

                    <div class="betslip_selection_second_line">
                      <div class="banker_empty betslip_selection_second_line_banker"   *ngIf="selection.IsBanker"> B </div>
                      <div>
                        <div class=" betslip_selection_second_line_banker" *ngIf="selection.IsBanker"> B</div>
                      </div>
                      <div class="match-status">
                        <span class="status-match betslip_selection_second_line_status_normal"  *ngIf="!selection.IsLive"></span>
                        <span class="status-match won betslip_selection_second_line_status_success" *ngIf="selection.IsLive">{{'Live' | translate}} </span>
                        <span class="status-score" *ngIf="selection.Score">{{selection.HFName}} {{selection.Score}} </span>
                        <span class="status-time" *ngIf="selection.Time"> {{selection.Time}}'</span>
                      </div>
                      <div class="betslip_selection_second_line_your" style="width: 118px;"> </div>
                      <div class="betslip_selection_second_line_your_stake" style="display: none;"></div>
                      <div *ngIf="selection.NumberOfSelections > 1" class="betslip_selection_second_line_ns">{{selection.NumberOfSelections}}</div>
                      <div class="clearboth"> </div>
                    </div>

                    <div class="betslip_selection_third_line">
                      <div class="betslip_selection_second_line_group_empty" *ngIf="!selection.GroupId"> </div>
                      <!-- ko if: Group() != 0 --><!-- /ko -->
                      <div class="betslip_selection_second_line_group " *ngIf="selection.GroupId" [ngClass]="selection.GroupId ? selection.GroupId : ''">{{selection.GroupId}}</div>
                      <div class="betslip_selection_second_line_event padded">
                        <span title="{{selection.MarketName}}:{{selection.SelectionName}}">{{selection.MarketName}}:{{selection.SelectionName}}</span>
                        <span class="betslip_selection_second_line_your_odds">@ {{selection.Odds}}</span>

                      </div>
                      <div class="clearboth"></div>

                    </div>

                    <div class="right" style="display: none;">
                      <div class="your_odds">@ {{selection.Odds}}</div>
                      <div class="your_stake" style="display: none;"></div>
                      <div class="max_payout" style="display: none;"></div>
                    </div>
                  </li>
                </ng-template>
                <!-- /ko-->

              </ul>
              <div class="betslip_title">
                <span>Bets</span>
              </div>
              <div class="betslip_system_titles">
                <div class="betslip_system_title_permutations">{{'PERMUTATIONS' | translate}}</div>
                <div class="betslip_system_title_lines">{{'LINES' | translate}}</div>
                <div class="betslip_system_title_odds">{{'Odds' | translate}}</div>
                <div class="betslip_system_title_total">{{'TOTAL' | translate}}</div>
              </div>

              <ul class="betslip_systems">
                <ng-template let-variant ngFor [ngForOf]="betData.SystemSelections">
                  <li class="betslip_system 1">

                    <div class="betslip_system_row1">
                      <div class="betslip_system_name clickable">{{variant.VariantName}}</div>
                    </div>
                    <div class="betslip_system_row2">
                      <div class="float_left betslip_system_details_lines">{{variant.VariantSelectionCount}} {{'LINES' | translate}} <span class="betslip_systems_multiplier_button">X</span></div>
                      <div class="betslip_system_details_odds">{{variant.TotalOdds}}</div>
                      <div class="float_right betslip_system_yourstake">€{{variant.TotalStake}}</div>
                    </div>
                    <div class="betslip_system_details" style="display: none;">
                      <ul class="betslip_system_expanded_body" style="display: none;"></ul>
                    </div>
                  </li>
                </ng-template>


               <!-- <li data-bind="attr:{'class':IsExpandedStyle},visible:IsVisible" class="betslip_system 6">

                  <div class="betslip_system_row1">
                    <div class="betslip_system_name clickable" data-bind="text:Name,click:userExpandItem,clickBubble:false">ANY 6 of 6</div>
                  </div>
                  <div class="betslip_system_row2">
                    <div class="float_left betslip_system_details_lines" data-bind="html:NumberOfBetsUI,click:userExpandItem,clickBubble:false, css: { warning : HasHiddenSelection}">1 LINES <span class="betslip_systems_multiplier_button">X</span></div>
                    <div class="betslip_system_details_odds" data-bind="text:getOdds()">32.888</div>
                    &lt;!&ndash; ko if: typeof TaxOnStakePercentage !== "undefined" && parseFloat(TaxOnStakePercentage) > 0 &ndash;&gt;&lt;!&ndash;/ko&ndash;&gt;
                    &lt;!&ndash; ko ifnot: typeof TaxOnStakePercentage !== "undefined" && parseFloat(TaxOnStakePercentage) > 0 &ndash;&gt;
                    <div class="float_right betslip_system_yourstake" data-bind="text:$parent.CurrencySymbol() + StakePerLineUI()">€10.00</div>
                    &lt;!&ndash;/ko&ndash;&gt;
                  </div>
                  <div class="betslip_system_details" data-bind="visible:IsExpanded" style="display: none;">
                    &lt;!&ndash; ko if:IsExpanded&ndash;&gt;&lt;!&ndash;/ko&ndash;&gt;
                    &lt;!&ndash; selection expanded body &ndash;&gt;
                    <ul class="betslip_system_expanded_body" data-bind="if:IsExpanded,visible:IsExpandedWithItems" style="display: none;"></ul>
                  </div>
                </li>-->
                <!--/ko-->
              </ul>
            </div>
            <!-- ko if: PermutationAnalysisMessage().length > 0 --><!-- /ko-->
            <div class="betslip_summary">
              <div class="betslip_summary_row1 large_text">
                <div class="clearboth"></div>
              </div>
              <div class="betslip_summary_row1 large_text">
                <div class="clearboth"></div>
                <!-- ko foreach: BonusWinningsMsg --><!--/ko-->
                <!-- /ko -->
                <div class="betslip_summary_text" style="float:left;">{{'TOTAL STAKE' | translate}}</div>
                <div class="betslip_summary_value">€{{betData.BetAmount}}</div>
                <!-- /ko-->
                <div class="clearboth"></div>
              </div>
              <div class="betslip_summary_row2">
                <div>
                  <div class="betslip_summary_row1">
                    <div class="betslip_summary_text">
                      <span>{{'BETS' | translate}}:</span> &nbsp;<span>{{betData.BetsCount}}</span>
                    </div>
                  </div>
                  <div class="betslip_title">{{'Max. Winnings Amount' | translate}}</div>
                  <div class="box_height">
                    <div class="boxed">

                      <div class="outer double_boxed">
                        <div class="double_boxed">
                          <div class="double_boxed">
                            <div class="betslip_max_payout">€{{betData.MaxPayout}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /ko-->
                  <div class="clearboth"></div>
                </div>

                <div class="betslip_summary_on_behalf "  style="display: none;">
                  <div class="betslip_summary_on_behalf_close clickable"> </div>
                </div>
                <div class="clearboth"></div>

               <!-- <div class="betslip_summary" style="margin-top: 20px;">
                  <div class="betslip_summary_row1 smallerFont">
                    <span data-bind="text: 'Maximum payouts per league'.translate()">Maximum payouts per league</span>
                  </div>
                  <div class="betslip_summary_row1 smallerFont">
                    <span class="betslip_summary_text smallerFont">For all 3 way result only</span>
                    <span class="betslip_summary_value smallerFont">€{{betData.ShopMaxPayoutThreeWay}}</span>
                  </div>
                  <div class="betslip_summary_row1 smallerFont">
                    <span class="betslip_summary_text smallerFont">At least one Cyprus game</span>
                    <span class="betslip_summary_value smallerFont">€{{betData.ShopMaxPayoutCyprus}}</span>
                  </div>
                  <div class="betslip_summary_row1 smallerFont">
                    <span class="betslip_summary_text smallerFont">For all specialties</span>
                    <span class="betslip_summary_value smallerFont">€{{betData.ShopMaxSpecialities}}</span>
                  </div>
                  <div class="betslip_summary_row1 smallerFont">
                    <span class="betslip_summary_text smallerFont">Other sport</span>
                    <span class="betslip_summary_value smallerFont">€{{betData.ShopMaxOtherSport}}</span>
                  </div>
                </div>-->
                <div class="clearboth"></div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!--/ko-->
    </div>

  </div>

  <div class="modal_footer betslip-summary">
    <br>
    <div class="website">{{'Rules and regulations apply' | translate}}</div>
    <br>
    <div class="barcode_container" >
      <div class="barcode_details"  *ngIf="!settings.guestMode">
        <div class="barcode_short_id">{{betData.BarCode}}</div>
        <div class="barcode_pin">
          <span >{{'Pin' | translate}}: </span>
          <span >{{betData.Pin}}</span>
        </div>
      </div>
      <br>

      <div class="barcode"  style="padding: 0px; overflow: auto; width: 374px;">
        <ngx-barcode [bc-value]="betData.BarCode" [bc-height]="61" [bc-margin-left]="40"></ngx-barcode>
      </div>
      <div style="width: 374px;">
        <span style="display: inline-flex; font-size: 12px; text-align: justify" >
          <img [src]="'http://coupondemo.gameologic.com/'+ betData.BarCode+'.png'" alt="" style=" width:200px" class="qrcode-img">
          <!--<img [src]="'https://qrickit.com/api/qr.php?d='+ betData.QRCode" alt="" style="width: 200px;">-->
          <span style="padding-top: 50px; padding-left: 15px">
            {{'QR Code info' | translate}}
          </span>
        </span>
      </div>
    </div>

  </div>
</div>
<!-- /ko -->
</body></html>
