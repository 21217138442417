<div id="mainContents" style="">
  <app-shortcuts *ngIf="settings.isShortcutsPopupOpened"></app-shortcuts>
  <div class="error_message error-effect-jelly" *ngIf="settings.hasError.hasError">
    <div class="error_message_icon_close clickable" (click)="settings.hasError.clearError()">
      <i class="fa fa-times"></i>
    </div>
    <div class="error_message_text clickable">
      <span>{{settings.hasError.mgs}}</span>
      <i class="fa fa-exclamation"></i>
    </div>
    <div class="error-success-progress"></div>
  </div>
  <div class="success_message">
    <div class="error_message error-effect-jelly confirmation_message"  [ngStyle]="{display: settings.successInfo.success ? 'block': 'none'}">
      <div class="error_message_icon_close clickable">
        <i class="fa fa-times"></i>
      </div>
      <div class="error_message_text clickable">
        <span class="highlighted">{{'Success!' | translate}}</span>
        <span>{{'Settings Saved' | translate}}</span>
        <i class="fa fa-exclamation"></i>
      </div>
      <div class="error-success-progress"></div>
    </div>
  </div>
  <div class="container-non-responsive">
    <div class="row height100">
      <div class="dashboard">
        <div class="btn btn-aside">
          <a class="menu-bar" [ngClass]="{' menu-collapsed':  !settings.isMainMenuOpened}" style="display: block;">
            <i class="fa fa-bars leftMenuButton" (click)="settings.isMainMenuOpened = !settings.isMainMenuOpened"></i>
          </a>
        </div>
        <menu-component></menu-component>
        <div class="dashboard-body" [ngClass]="{'expanded': !settings.isMainMenuOpened , 'collapsed': settings.isMainMenuOpened }">
          <div class="square-section dashboard-header">
            <div class="row">
              <div class="col-xs-12 padd-h0">
                <div class="pull-left">
                  <div class="home" [ngClass]="{'dashFirstItem': !settings.isMainMenuOpened}">
                    <a href="/sport">
                      <div class="fa fa-home"></div>
                    </a>
                  </div>
                  <div class="text-left betApprovals" style="display: block;">
                    <a class="btn btn-shop-grey " title="{{'Bet approvals' | translate}}" routerLink="/management/bet-approval" routerLinkActive="active">
                      <i class="fa fa-repeat"></i> {{'Bet approvals' | translate}}&nbsp;
                    </a>
                  </div>
                </div>
                <div class="pull-right right-header">
                  <div class="custom-input primary-color BetslipSearch" style="display: block;">
                    <div class="input-group col-xs-12">
                      <input type="text" class="form-control primary-color input-md" placeholder="{{'Bar Code' | translate}}" (change)="searchBarCode = $event.target.value">
                      <span class="input-group-btn">
                      <button class="btn btn-input" type="button" (click)="searchBetSlip()">
                          <i class="fa fa-search"></i>
                      </button>
                  </span>
                    </div>
                  </div>
                  <div class="user dropdown" style="display: block;" [ngClass]="{open: openUserMenu}">
                    <a id="account-info" class="dropdown-toggle"
                       (click)="$event.stopPropagation();openUserMenu = !openUserMenu">
                      <div class="user-image img-circle">
                        <i class="fa fa-user-secret"></i>
                      </div>
                      <div class="user-id">
                        <div class="dark">{{settings.cashierData.CashierName}}</div>
                        <div class="second-color" title="{{'Customer Id' | translate}}"><em>{{settings.cashier.cashierId}}</em></div>
                        <div class="drop-caret">
                          <i class="fa fa-caret-down"></i>
                        </div>
                      </div>
                    </a>
                    <ul class="dropdown-menu dropdown-account" aria-labelledby="account-info">
                      <li>
                        <a [routerLink]="['/management/settings/personal-info']">
                          <i class="fa fa-user"></i>{{'Profile' | translate}}
                        </a>
                      </li>
                      <li>
                        <a (click)="settings.isShortcutsPopupOpened = true;">
                          <i class="fa fa-keyboard-o"></i>{{'Keyboard Shortcuts' | translate}}
                        </a>
                      </li>
                      <li>
                        <a [routerLink]="['/management/settings/general']">
                          <i class="fa fa-cog"></i>{{'Settings' | translate}}
                        </a>
                      </li>
                      <li class="divider"></li>
                      <li>
                        <a (click)="accountReqService.logAut();">
                          <i class="fa fa-power-off"></i>{{'Log out' | translate}}
                        </a>
                      </li>
                    </ul>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <router-outlet></router-outlet>

          <div class="dashboard-footer">
            <div class="info">
              <div class="txt">
                {{'Server Status' | translate}}:
                <span class="green">{{'Online' | translate}}</span>
              </div>
              <div class="time">
                <span style="display: inline;">{{userTime | date : 'HH:mm:ss'}} {{userTimInfo.Name}} ({{userTimInfo.GMT}})</span>
                <span>{{userDate | date : 'dd/MM/yyyy'}}</span>
              </div>
            </div>
            <div class="amount-reset" style="display: block;">
              <a (click)="resetBetsInfo()" class="btn btn-shop-primary-color" title="{{'Reset' | translate}}">{{'Reset' | translate}}</a>
            </div>
            <div class="amount">
              <div class="txt">{{'Amount of Last Slips' | translate}} ({{settings.betsInfo.BetsCount}}):</div>
              <div class="txt">
                <span class="second-color" style="text-align: right;">€{{settings.betsInfo.BetsAmount | number:'1.2'}}</span>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<popup-betslip [betslipData]="BetInfo" *ngIf="BetInfo" (closeEvent)="RemoveBetslipData()"></popup-betslip>
<app-print  id="asdf"  [betData]="settings.SuccessBetData" *ngIf="settings.SuccessBetData" style="display: none"></app-print>
