import {Injectable, Inject} from '@angular/core';
import {Subject, Observable} from 'rxjs';

// import {Settings} from '../settings';

declare var $: any;

export enum ConnectionState {
  Connecting = 1,
  Connected = 2,
  Reconnecting = 3,
  Disconnected = 4
}

export class ChannelConfig {
  url: string;
  hubName: string;
  channel: string;

  constructor(){

  }
}

export class ChannelEvent {
  Name: string;
  ChannelName: string;
  Timestamp: Date;
  Data: any;
  Json: string;

  constructor() {
    this.Timestamp = new Date();
  }
}

class ChannelSubject {
  channel: string;
  subject: Subject<ChannelEvent>;
}


@Injectable({
  providedIn: 'root',
})
export class ChannelService {



  connectionState$: Observable<ConnectionState>;

  error$: Observable<string>;

  private connectionStateSubject = new Subject<ConnectionState>();
  private startingSubject = new Subject<any>();
  private errorSubject = new Subject<any>();

  starting$ = this.startingSubject.asObservable();
  private hubConnection: any;
  public hubProxy: any;
  public connectionId;
 // private userData;
  private subjects = new Array<ChannelSubject>();
constructor( ) {
// this.userData = JSON.parse(localStorage.getItem('cashierData'));
}
  init(url, hubName, shId?, shToken? ) {
    if ($ === undefined || $.hubConnection === undefined) {
      throw new Error(`The variable '$' or the .hubConnection() function are not defined...please check the SignalR scripts have been loaded properly`);
    }

    const shopId = !shId ?  localStorage.getItem('shopId') : shId;
    const shopToken = !shToken ?  localStorage.getItem('shoptoken') : shToken;
    this.connectionState$ = this.connectionStateSubject.asObservable();
    this.error$ = this.errorSubject.asObservable();
    /*this.starting$ = this.startingSubject.asObservable();*/

    this.hubConnection = new $.hubConnection();

    this.hubConnection.qs = {'sid': shopId, 'st': shopToken};
    this.hubConnection.url = url;
    this.hubProxy = this.hubConnection.createHubProxy(hubName);


    this.hubConnection.stateChanged((state: any) => {
      let newState = ConnectionState.Connecting;

      switch (state.newState) {
        case $.signalR.connectionState.connecting:
          newState = ConnectionState.Connecting;
          break;
        case $.signalR.connectionState.connected:
          newState = ConnectionState.Connected;
          break;
        case $.signalR.connectionState.reconnecting:
          newState = ConnectionState.Reconnecting;
          break;
        case $.signalR.connectionState.disconnected:
          console.log(111111111111);
          this.start();
          newState = ConnectionState.Disconnected;
          break;
      }


      this.connectionStateSubject.next(newState);
    });


    this.hubConnection.error((error: any) => {
      this.errorSubject.next(error);
    });

    this.hubProxy.on('newMessage', (e) => {
      console.log(e);
    });

    this.hubProxy.on('onEvent', (channel: string, ev: ChannelEvent) => {
      let channelSub = this.subjects.find((x: ChannelSubject) => {
        return x.channel === channel;
      }) as ChannelSubject;

      if (channelSub !== undefined) {
        return channelSub.subject.next(ev);
      }
    });
  }

  start(): void {
    this.hubConnection.start()
      .done((e) => {
        console.log(e.id)
        this.connectionId = e.id;
        this.startingSubject.next();
      })
      .fail((error: any) => {
        this.startingSubject.error(error);
      });
  }

  sub(channel: string): Observable<ChannelEvent> {

    let channelSub = this.subjects.find((x: ChannelSubject) => {
      return x.channel === channel;
    }) as ChannelSubject;

    if (channelSub !== undefined) {
      console.log(`Found existing observable for ${channel} channel`)
      return channelSub.subject.asObservable();
    }

    channelSub = new ChannelSubject();
    channelSub.channel = channel;
    channelSub.subject = new Subject<ChannelEvent>();
    this.subjects.push(channelSub);

    this.starting$.subscribe(() => {
        this.hubProxy.invoke('Subscribe', channel)
          .done(() => {
            console.log(`Successfully subscribed to ${channel} channel`);
          })
          .fail((error: any) => {
            channelSub.subject.error(error);
          });
      },
      (error: any) => {
        channelSub.subject.error(error);
      });

    return channelSub.subject.asObservable();
  }


  publish(): void {
    this.hubProxy.invoke.apply(null, arguments);
  }

}
