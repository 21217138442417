import {Component, OnInit, ViewChild} from '@angular/core';
import {Settings} from '../../../../core/services/settings';
import {RequestsService} from '../../../../core/services/requests/requests.service';
import * as enums from '../../../../core/enums/consts';
import {CoreService} from '../../../../core/services/core.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'today-cash',
  templateUrl: './today-cash.component.html'
})

export class TodayCashComponent implements OnInit {
  @ViewChild('todayPrint') todayPrint;
  public TransactionTypes = enums.TransactionTypes;
  public TransactionList = enums.TransactionList;
  public openedFilter = true;
  public SelectedRowId = 0;
  public isLoading = false;
  public FilterObj = {
    fromDate: moment().set({hour: 0, minute: 0, second: 0, millisecond: 0}),
    toDate: moment().set({hour: 23, minute: 59, second: 59, millisecond: 0}),
    cashierId: '',
    terminalId: '',
    betState: '',
  };
  public Data = {
    Balance: 0,
    CancelRefund: 0,
    CancelRefundIssued: 0,
    ComAdjustment: 0,
    Commission: 0,
    NetStake: 0,
    Payments: 0,
    PaymentsIssued: 0,
    PeriodUnpaid: 0,
    PeriodWonSlips: 0,
    SlipsIssued: 0,
    Stake: 0,
    StakeIssued: 0,
    TodaysCash: [],
    Unpaid: 0,
    WonSlips: 0,
  };
  public TerminalsList = [];
  public CashiersList = [];
  public BetInfo: any;
  public SelectedItem = {
    Amount: '',
    BarCode: '',
    BetId: '',
    CashierName: '',
    TerminalName: '',
    Time: '',
    Type: ''
  };

  constructor(
    private settings: Settings,
    private http: RequestsService,
    public _core: CoreService
  ) {
    this.http.httpGet(this.settings.apiUrls.account.GetShopTerminals).subscribe((e: any) => {
      if (!e.HasError) {
        this.TerminalsList = e.Data;
        setTimeout(() => {
          $('#select2').selectric();
        }, 100);
      }
    });
    this.http.httpGet(this.settings.apiUrls.account.GetShopCashiers).subscribe((e: any) => {
      if (!e.HasError) {
        this.CashiersList = e.Data;
        setTimeout(() => {
          $('#select1').selectric();
        }, 100);
      }
    });
    setTimeout(() => {
      $('#select3').selectric();
    }, 100);
    /*this.getBetSlip();*/
  }

  ngOnInit() {
    $('select').selectric();
    $('#datetimeFrom').datetimepicker({format: 'dddd, MMM Do YYYY, HH:mm', defaultDate: this.FilterObj.fromDate}).on('dp.change', (e) => {
      this.FilterObj.fromDate = e.date ? new Date(e.date.format()) : '';
    });
    $('#datetimeTo').datetimepicker({format: 'dddd, MMM Do YYYY, HH:mm', defaultDate: this.FilterObj.toDate}).on('dp.change', (e) => {
      this.FilterObj.toDate = e.date ? new Date(e.date.format()) : new Date();
    });
    $('#select3').on('change', (e) => {
      this.FilterObj.betState = $('#select3').val();
    });
    $('#select1').on('change', (e) => {
      this.FilterObj.cashierId = $('#select1').val();
    });
    $('#select2').on('change', (e) => {
      this.FilterObj.terminalId = $('#select2').val();
    });
  }

  getTodayCash() {
    this.isLoading = true;
    this.http.httpPost(this.settings.apiUrls.reports.TodaysCash, this.FilterObj).subscribe((e: any) => {
      if (!e.HasError)
        this.Data = e.Data;
      this.isLoading = false;
    });
  }

  getBetSlip() {
    this.isLoading = true;
    this.http.httpGet(`${this.settings.apiUrls.reports.GetBetSleepDetails}BetSleepId=${this.SelectedItem.BetId}&language=en`)
      .subscribe((data: any) => {
        if (!data.HasError) {
          this.BetInfo = data.Data;
          this.BetInfo['SystemSelections'].forEach((item, ind) => {
            item.SelectionStake = JSON.parse(item.SelectionStake);
          });
        }
        this.isLoading = false;
      });
  }

  selectRow(item) {
    this.SelectedItem = item;
  }

  print() {
    if (!this.Data || (this.Data && !this.Data.TodaysCash.length)) {
      return false;
    }
    this.todayPrint.getPrintData(this.FilterObj);
  }

  RemoveBetslipData() {
    this.BetInfo = null;
  }
}
