import {Component, ElementRef, EventEmitter, HostListener, OnDestroy} from '@angular/core';
import {Settings} from '../../core/services/settings';
import {AccountReqService} from '../../core/services/requests/account-req.service';
import {ChannelService} from '../../core/services/signalr/signalr.service';
import {RequestsService} from '../../core/services/requests/requests.service';
import * as tymeZones from '../../core/enums/country';

declare var _: any;
declare var moment: any;

@Component({
  selector: 'management',
  templateUrl: './management.component.html'
})

export class ManagementComponent implements OnDestroy {
  public openUserMenu: boolean;
  public timeZones = tymeZones.tymeZones;
  public searchBarCode = '';
  public BetInfo: any;
  public userTimInfo: any;
  public userDate = new Date();
  public intervalTime: any;
  public Country = tymeZones.tymeZones;
  public timeZone = tymeZones.tymeZones[localStorage.getItem('timeZone') ? localStorage.getItem('timeZone') : 0].GMT;
  public _userTime = moment().utcOffset(this.timeZone).format('YYYY-MM-DD HH:mm');

  // public userTime = new Date();

  get userTime() {
    const a = new Date();
    /* setInterval(() => {
       a = new Date();
     });*/
    return this._userTime;
  }


  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      setTimeout(() => {
        this.openUserMenu = false;
      }, 50);
    }
  }

  constructor(
    public settings: Settings,
    private eRef: ElementRef,
    private http: RequestsService,
    public accountReqService: AccountReqService
  ) {
    this._userTime = moment().utcOffset(this.timeZone).format('YYYY-MM-DD HH:mm:ss');
    this.intervalTime = setInterval(() => {
      this.timeZone = tymeZones.tymeZones[localStorage.getItem('timeZone') ? localStorage.getItem('timeZone') : 0].GMT;
      this._userTime = moment().utcOffset(this.timeZone).format('YYYY-MM-DD HH:mm:ss');
    }, 1000);
    this.settings.sportConn = new ChannelService();
    this.settings.sportConn.init(this.settings.sigRUrl + 'signalr', 'RetailOddsHub');
    this.settings.sportConn.start();
    this.settings.sportConn.starting$.subscribe((e1) => {

    });
    if (this.settings.cashierData.TimeZoneId) {
      this.userTimInfo = this.timeZones[this.settings.cashierData.TimeZoneId - 1];
    }
  }

  searchBetSlip() {
    this.http.httpGet(`${this.settings.apiUrls.reports.GetBetSleepDetailsByBarCode}barCode=${this.searchBarCode}&language=en`)
      .subscribe((data: any) => {
        if (!data.HasError) {
          this.BetInfo = data.Data;
          this.BetInfo['SystemSelections'].forEach((item, ind) => {
            item.SelectionStake = JSON.parse(item.SelectionStake);
          });
        }
      });
  }

  RemoveBetslipData() {
    this.BetInfo = null;
  }

  resetBetsInfo() {
    this.settings.betsInfo.BetsAmount = 0;
    this.settings.betsInfo.BetsCount = 0;
    localStorage.removeItem('betsInfo');
  }

  ngOnDestroy() {
    clearInterval(this.intervalTime);
  }
}
