<div class="shopsetup-bg">
  <div class="error_message error-effect-jelly" *ngIf="hasError.hasError">
    <div class="error_message_icon_close clickable" (click)="hasError.hasError = false">
      <i class="fa fa-times"></i>
    </div>
    <div class="error_message_text clickable">
      <span >{{hasError.errMsg}}</span>
      <i class="fa fa-exclamation"></i>
    </div>
    <div class="error-success-progress"></div>
  </div>
  <div id="mainContents" style="">
    <div class="login_container" *ngIf="state == loginStates.shopId || state == loginStates.shopPin">
      <div class="pinlogin_container">
        <div class="shop_setup_container" style="height: 100%;">
          <div class="user_image">
            <i class="fa fa-shopping-cart"></i>
          </div>

          <div class="setup_notification" style="display: inline-block;" *ngIf="state == loginStates.shopId">
            {{'You are running the Betologic Shop App for the first time. Please enter your SHOP ID.' | translate}}
          </div>
          <div class="setup_notification" style="display: inline-block;" *ngIf="state == loginStates.shopPin">
            {{'Shop is not setup. Enter admin pin' | translate}}:
          </div>

          <div class="numpad"  style="display: block;">
            <div class="button" (click)="pushNumber(1)">1</div>
            <div class="button" (click)="pushNumber(2)">2</div>
            <div class="button" (click)="pushNumber(3)">3</div>
            <div class="button" (click)="pushNumber(4)">4</div>
            <div class="button" (click)="pushNumber(5)">5</div>
            <div class="button" (click)="pushNumber(6)">6</div>
            <div class="button" (click)="pushNumber(7)">7</div>
            <div class="button" (click)="pushNumber(8)">8</div>
            <div class="button" (click)="pushNumber(9)">9</div>
            <div class="button" (click)="pushNumber(0)">0</div>
          </div>
          <div class="password_field" *ngIf="pin">
            <div class="password_item" *ngFor="let item of pin.split('')"></div>
          </div>
          <div class="login_button btn btn-shop-primary-color" (click)="confirm()" *ngIf="state == loginStates.shopId || state == loginStates.shopPin">
            CONFIRM
          </div>

          <div class="clear-button">
            <span class="clickable" (click)="clear()">{{'Clear' | translate}}</span>
          </div>
          <div class="full_is_busy"style="display: none;">
            <div class="full_is_busy_background"></div>
            <div class="blurred_area"></div>
            <div class="full_is_busy_contents">
              <div class="full_is_busy_title">
                <span class="full_is_busy_title_icon"></span>
                <span class="full_is_busy_title_text">{{'Please wait...' | translate}}</span>
              </div>
              <div class="full_is_busy_message"></div>
            </div>
          </div>
        </div>
      </div>
    </div>




    <div class="login_container" *ngIf="state == loginStates.terminalList">
      <div class="pinlogin_container">
        <div class="shop_setup_container" style="height: 100%;">
          <div class="user_image">
            <i class="fa fa-shopping-cart"></i>
          </div>
          <div class="setup_notification">{{'Please choose Terminal Id' | translate}}:</div>
          <div class="setup_terminal_container">
            <div class="setup_terminal" *ngFor="let terminal of TerminalsList"  (click)="selectTerminal(terminal)">
              <div>
                <span>{{'Terminal Id' | translate}}: </span>
                <span class="yellow" >{{terminal.Id}}</span>
              </div>
              <div >{{'DESCRIPTION' | translate}}: {{terminal.Name}}</div>
              <div>
                <span >{{'Terminal Type' | translate}}: </span>
                <span >{{TerminalTypes[terminal.Type]}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="login_container"  *ngIf="state == loginStates.userType" >

      <div class="pinlogin_container" style="display: block;">

        <div class="users">
          <div class="shop_user clickable"  tabindex="-1" (click)="loginByUser()">
            <div class="user_image">
              <i class="fa fa-user"></i>
            </div>
            <div class="user_name">{{'Shop User' | translate}}</div>
          </div>
          <div class="guest_user clickable" id="guest_user"  tabindex="-1"  (click)="guestLogin($event)">
            <div class="user_image">
              <i class="fa fa-user-secret"></i>
            </div>
            <div class="user_name">{{'Guest User' | translate}}</div>
          </div>

        </div>

      </div>

    </div>


    <div class="login_container"  *ngIf="state == loginStates.login" >

      <div class="pinlogin_container" style="display: block;">
        <div class="users user_login">
          <div class="clickable selected" id="shop_user"  tabindex="-1">
            <div class="user_image">
              <i class="fa fa-user"></i>
            </div>
            <div class="user_name">{{'Shop User' | translate}}</div>
            <div class="row">
              <br/>
            </div>
          </div>
          <form [formGroup]="valForm" class="form-validate form-horizontal" (ngSubmit)="login($event, valForm.value)">
            <div class="input-group col-xs-12"  >
              <input class="input-lg" placeholder="{{'Username' | translate}}"  formControlName="Username" name="username">
              <p class="text-danger" *ngIf="valForm.controls['Username'].hasError('required') && (valForm.controls['Username'].dirty || valForm.controls['Username'].touched)">{{'This field is required' | translate}}</p>
            </div>
            <div class="input-group col-xs-12">
              <input  class="input-lg" type="password" placeholder="{{'Password' | translate}}"  formControlName="Password" name="password">
              <p class="text-danger" *ngIf="valForm.controls['Password'].hasError('required') && (valForm.controls['Password'].dirty || valForm.controls['Password'].touched)">{{'This field is required' | translate}}</p>
            </div>
            <div class="guest_user clickable" tabindex="-1">
              <button class="login_button btn btn-shop-primary-color" *ngIf="state == loginStates.login" (click)="login($event, valForm.value)">{{'Login to your account' | translate}}</button>
            </div>
          </form>
        </div>

      </div>

    </div>

    <div class="full_is_busy" data-bind="visible: IsVisible" *ngIf="requestLoading">
      <div class="full_is_busy_background"></div>
      <div class="blurred_area"></div>
      <div class="full_is_busy_contents">
        <div class="full_is_busy_title">
          <span class="full_is_busy_title_icon"></span>
          <span class="full_is_busy_title_text" >{{'Please wait...' | translate}}</span>
        </div>
        <div class="full_is_busy_message"></div>
      </div>
    </div>
  </div>
</div>
