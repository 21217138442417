export const ErrorList = {
  987: {
    code: 987,
    title: 'Single bets with no stake',
    description: 'Please add stake to accumulator or to all selections'
  },
  1: {
    code: 1,
    title: 'Wrong User Credentials',
    description: ''
  },
  2: {
    code: 2,
    title: 'Not Have Access To User',
    description: ''
  },
  3: {
    code: 3,
    title: 'SessionNotFound',
    description: ''
  },
  5: {
    code: 5,
    title: 'RoleNotFound',
    description: ''
  },
  6: {
    code: 6,
    title: 'PermissionNotFound',
    description: ''
  },
  7: {
    code: 7,
    title: 'NotHaveAccessToPartner',
    description: ''
  },
  8: {
    code: 8,
    title: 'WrongBetShopId',
    description: ''
  },
  9: {
    code: 9,
    title: 'WrongBetShopGroupId',
    description: ''
  },
  10: {
    code: 10,
    title: 'WrongTerminalId',
    description: ''
  },
  11: {
    code: 11,
    title: 'WrongCashierId',
    description: ''
  },
  12: {
    code: 27,
    title: 'WrongRequestParameter',
    description: ''
  },
  13: {
    code: 27,
    title: 'WrongComissionId',
    description: ''
  },
  15: {
    code: 27,
    title: 'WrongCountryId',
    description: ''
  },
  16: {
    code: 27,
    title: 'WrongSportId',
    description: ''
  },
  17: {
    code: 27,
    title: 'WrongCategoryId',
    description: ''
  },
  18: {
    code: 27,
    title: 'WrongTournamentId',
    description: ''
  },
  19: {
    code: 27,
    title: 'WrongMessageId',
    description: ''
  },
  20: {
    code: 27,
    title: 'MessageAssignedToAnotherUser',
    description: ''
  },
  21: {
    code: 21,
    title: '',
    description: ''
  },
  22: {
    code: 22,
    title: '',
    description: ''
  },
  23: {
    code: 23,
    title: '',
    description: ''
  },
  24: {
    code: 24,
    title: 'RequestAlreadyManaged',
    description: ''
  },
  25: {
    code: 25,
    title: 'WrongApprovalType',
    description: ''
  },
  26: {
    code: 26,
    title: 'WrongDocumentState',
    description: ''
  },
  27: {
    code: 27,
    title: 'Selection Odd In Active',
    description: ''
  },
  28: {
    code: 28,
    title: 'Selection Odd Changed',
    description: ''
  },
  29: {
    code: 29,
    title: 'Amount Sum Not Equal Total Stake',
    description: ''
  },
  30: {
    code: 30,
    title: 'Bankers From One Match',
    description: ''
  },
  31: {
    code: 31,
    title: 'Not All ContainsBanker',
    description: ''
  },
  32: {
    code: 32,
    title: 'SelectionOddChangedAndInActive',
    description: ''
  },
  33: {
    code: 31,
    title: 'InvalidSystemSelection',
    description: ''
  },
  34: {
    code: 31,
    title: 'Not All ContainsBanker',
    description: ''
  },
  35: {
    code: 35,
    title: 'Wrong',
    description: 'Bet Amount'
  },
  36: {
    code: 26,
    title: 'Wrong',
    description: 'Min Selection'
  },
  37: {
    code: 37,
    title: 'Only Bankers Can Left',
    description: ''
  },
  38: {
    code: 38,
    title: 'Duplicate In Variants',
    description: ''
  },
  39: {
    code: 39,
    title: 'Wrong Date Range',
    description: ''
  },
  40: {
    code: 40,
    title: 'Wrong Bets Count',
    description: ''
  },
  41: {
    code: 41,
    title: 'UpdateOddsError',
    description: ''
  },
  42: {
    code: 42,
    title: 'WrongEventId',
    description: ''
  },
  43: {
    code: 43,
    title: 'NeedBetApproval',
    description: ''
  },
  45: {
    code: 45,
    title: 'Not Enough Balance',
    description: ''
  },
  46: {
    code: 46,
    title: 'Max Bet Amount Limit',
    description: ''
  },
  47: {
    code: 47,
    title: 'Min Bet Amount Limit',
    description: ''
  },
  48: {
    code: 48,
    title: 'Max Win Amount Limit',
    description: ''
  },
  49: {
    code: 49,
    title: 'Max Cyprus Bet Amount Limit',
    description: ''
  },
  50: {
    code: 50,
    title: 'Max Three Way Bet Amount Limit',
    description: ''
  },
  51: {
    code: 51,
    title: 'Max Specialities Bet Amount Limit',
    description: ''
  },
  52: {
    code: 52,
    title: 'MaxOtherSportBetAmountLimit',
    description: ''
  },
  53: {
    code: 53,
    title: 'NotValidDateFilter',
    description: ''
  },
  54: {
    code: 54,
    title: 'WrongBetSleepId',
    description: ''
  },
  55: {
    code: 55,
    title: 'WrongApprovalAmount',
    description: ''
  },
  56: {
    code: 56,
    title: 'PartnerNotFound',
    description: ''
  },
  57: {
    code: 57,
    title: 'BetSlipNotFound',
    description: ''
  },
  58: {
    code: 58,
    title: 'WrongSelectionState',
    description: ''
  },
  59: {
    code: 59,
    title: 'FirewallVariableNotFound',
    description: ''
  },
  60: {
    code: 60,
    title: 'FirewallClassNotFound',
    description: ''
  },
  61: {
    code: 61,
    title: 'FirewallNotFound',
    description: ''
  },
  62: {
    code: 62,
    title: 'CouponMatchNotFound',
    description: ''
  },
  63: {
    code: 63,
    title: 'Coupon Not Found',
    description: ''
  },
  64: {
    code: 64,
    title: 'Firewall CIEntity Type Not Selected',
    description: ''
  },
  65: {
    code: 65,
    title: 'Firewall CIEntity Not Selected',
    description: ''
  },
  66: {
    code: 66,
    title: 'FirewallCIEntityNotFound',
    description: ''
  },
  67: {
    code: 67,
    title: 'FirewallCISportNotFound',
    description: ''
  },
  68: {
    code: 68,
    title: 'Firewall Entity Not Found',
    description: ''
  },
  69: {
    code: 69,
    title: 'Firewall Entity Already Exists',
    description: ''
  },
  70: {
    code: 70,
    title: 'Firewall Class Already Exist',
    description: ''
  },
  71: {
    code: 71,
    title: 'Firewall Class Item AlreadyExist',
    description: ''
  },
  72: {
    code: 72,
    title: 'Firewall Market Already Exist',
    description: ''
  },
  73: {
    code: 73,
    title: 'Market Not Found',
    description: ''
  },
  74: {
    code: 74,
    title: 'Selection Not Found',
    description: ''
  },
  75: {
    code: 75,
    title: 'Bet Rejected By Admin Reason',
    description: ''
  },
  76: {
    code: 76,
    title: 'Bet Not Canceled',
    description: ''
  },
  77: {
    code: 77,
    title: 'Wrong Cashout Amount',
    description: ''
  },
  78: {
    code: 78,
    title: 'Cashier Username Already Exist',
    description: ''
  },
  79: {
    code: 79,
    title: 'Not Possible To Book Match',
    description: ''
  },
  80: {
    code: 80,
    title: 'Wrong Bet Slip Pin',
    description: ''
  },
  81: {
    code: 81,
    title: 'Market Group Not Found',
    description: ''
  },
  82: {
    code: 82,
    title: 'Wrong Feed Market Id',
    description: ''
  },
  83: {
    code: 83,
    title: 'Feed Market Already Mapped',
    description: ''
  },
  84: {
    code: 84,
    title: 'Feed Market Already UnMapped',
    description: ''
  },
  85: {
    code: 85,
    title: 'Wrong Feed Sport Id',
    description: ''
  },
  86: {
    code: 86,
    title: 'Feed Sport Already Mapped',
    description: ''
  },
  87: {
    code: 87,
    title: 'Feed Sport Already UnMapped',
    description: ''
  },
  88: {
    code: 88,
    title: 'Wrong Feed CategoryId',
    description: ''
  },
  89: {
    code: 89,
    title: 'Feed Category Already Mapped',
    description: ''
  },
  90: {
    code: 90,
    title: 'Feed Category Already UnMapped',
    description: ''
  },
  91: {
    code: 91,
    title: 'Wrong Feed TournamentId',
    description: ''
  },
  92: {
    code: 92,
    title: 'Feed Tournament Already Mapped',
    description: ''
  },
  93: {
    code: 93,
    title: 'Feed Tournament Already UnMapped',
    description: ''
  },
  94: {
    code: 94,
    title: 'Wrong Feed CompetitorId',
    description: ''
  },
  95: {
    code: 95,
    title: 'Feed Competitor Already Mapped',
    description: ''
  },
  96: {
    code: 96,
    title: 'Feed Competitor Already Un Mapped',
    description: ''
  },
  97: {
    code: 97,
    title: 'Feed Category Not Mapped',
    description: ''
  },
  98: {
    code: 98,
    title: 'Wrong Entity Type',
    description: ''
  },
  99: {
    code: 99,
    title: 'CashOut Not Supported',
    description: ''
  },
  100: {
    code: 100,
    title: 'Partial CashOut Not Supported',
    description: ''
  },
  101: {
    code: 101,
    title: 'Bet Approvals Not Found',
    description: ''
  },
  999: {
    code: 999,
    title: 'Server is busy, please try later',
    description: ''
  },
  5555: {
    code: 5555,
    title: 'Payed!!!',
    description: ''
  },
  5556: {
    code: 5556,
    title: 'Cashout amount changed!!!',
    description: ''
  },
  5557: {
    code: 5557,
    title: 'Cashout canceled',
    description: ''
  }
};

export const NotificationList = {
  986: {
    code: 986,
      description: 'Only Bankers can be left outside of the groups!' /// erb  groupner@ amboghjutyamb @ntrvac chen
  },
  985: {
      code: 985,
      description: 'Odds have changed. Click to update betslip' /// erb  odd@ pocghvuma, errori vra click aneluc heto fixa linum
  },
  984: {
      code: 984,
      description: 'Invalid selections!' /// nuyn matchic erku hat bankir
  }
};
