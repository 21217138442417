<div class="dashboard-middleContent">
  <!--Dashboard Body - Left + Right column-->
  <div class="row">
    <div class="col-xs-12">
      <!-- Page header - tabs -->
      <div class="page-header">
        <div class="nav nav-tabs">
          <div class="active">
            <span title="Announcements">{{'Announcements' | translate}}</span>
          </div>
        </div>
      </div>

      <!-- Tab panes -->
      <div class="tab-content">
        <div class="tab-pane active">
          <div class="col-xs-left">
            <div class="box">
              <div class="box-header">
                <h2>
                  <i class="fa fa-bullhorn"></i>
                  <span class="break"></span>
                  <span title="{{'Announcements' | translate}}">{{'Announcements' | translate}}</span>
                </h2>
                <!--<div class="box-icon">-->
                <!--<a href="#" class="btn-minimize"><i class="fa fa-chevron-up"></i></a>-->
                <!--</div>-->
              </div>
              <div class="box-content">
                <div class="filter">
                  <div class="filter-header" (click)="openedFilter = !openedFilter" [ngClass]="{'open': openedFilter}">
                    <i class="fa fa-filter"></i> {{'Filter' | translate}}
                    <span class="fa "  [ngClass]="openedFilter ? 'fa-toggle-on' : 'fa-toggle-off'"></span>
                  </div>
                  <div class="filter-body collapse in" [ngStyle]="{display: openedFilter? 'block' : 'none'}">
                    <div class="custom-input primary-color">
                      <div class="input-group date"  id="datetimeStatementFrom">
                        <input type="text" class="form-control" >
                        <span class="input-group-addon">
                      <span class="fa fa-calendar"></span>
                  </span>
                      </div>
                    </div>
                    <div class="custom-input primary-color">
                      <div class="input-group date"  id="datetimeStatementTo">
                        <input type="text" class="form-control">
                        <span class="input-group-addon">
                      <span class="fa fa-calendar"></span>
                  </span>
                      </div>
                    </div>
                    <div class="amount-reset">
                      <a class="btn btn-shop-primary-color" (click)="getReport()" title="{{'Filter' | translate}}">{{'Filter' | translate}}</a>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
                <table class="table table-striped table-bordered">
                  <thead>
                  <tr>
                    <th>{{'ID' | translate}}</th>
                    <th>{{'Date / Time' | translate}}</th>
                    <th>{{'Sport' | translate}}</th>
                    <th>{{'DESCRIPTION' | translate}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <ng-template let-item ngFor [ngForOf]="Data">
                    <tr (click)="viewLine(item)" [ngClass]="{'announcement-selected': selectedItem && selectedItem.Id == item.Id}">
                      <td>{{item.Id}}</td>
                      <td>{{item.Date | date: 'dd mm'}}</td>
                      <td>None</td>
                      <td>{{item.Description}}</td>
                    </tr>
                  </ng-template>

                  </tbody>
                </table>

              </div>
            </div>

          </div>
          <div class="col-xs-right">
            <div class="box offset-bottom0">
              <div class="box-header">
                <h2>
                  {{'Details' | translate}}
                </h2>
              </div>
              <div class="box-content">
                <div class="announcements_main_content summary" *ngIf="selectedItem">
                  <div class="betslip-row">
                    <div class="title padd-v5 padd-h5">
                      <div class="pull-left" title="COUPON-TUESDAY FOOTBALL">{{'COUPON-TUESDAY FOOTBALL' | translate}}</div>
                    </div>
                    <div class="sum_container-slips">
                      <ul>
                        <li style="border-bottom: 1px solid #111111;">
                          <div class="details-announcement-row">[enter text]</div>
                        </li>
                        <li>
                          <div class="details-announcement-row">
                            <div class="row">
                              <div class="attachment">tuesday football.pdf</div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
              </div>
              <!--/ko-->
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="announcement-msg">
    <!-- ko template: { name: 'error-message', data: ErrorMessage } --><div class="error_message error-effect-jelly" style="display: none;">
    <div class="error_message_icon_close clickable">
      <i class="fa fa-times"></i>
    </div>
    <div class="error_message_text clickable">
      <i class="fa fa-exclamation"></i>
    </div>
    <div class="error-success-progress"></div>
  </div>
  <div class="full_is_busy" *ngIf="loader">
    <div class="full_is_busy_background"></div>
    <div class="blurred_area"></div>
    <div class="full_is_busy_contents">
      <div class="full_is_busy_title">
        <span class="full_is_busy_title_icon"></span>
        <span class="full_is_busy_title_text">{{'Please wait...' | translate}}</span>
      </div>
      <div class="full_is_busy_message"></div>
    </div>
  </div>

  </div>
</div>
<!--<annoucment-popup></annoucment-popup>-->
