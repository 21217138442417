import {Component, OnInit} from '@angular/core';
import * as enums from '../../../../core/enums/consts';
import {Settings} from '../../../../core/services/settings';
import {RequestsService} from '../../../../core/services/requests/requests.service';
import {CoreService} from '../../../../core/services/core.service';

declare var $: any;
declare var moment: any;

@Component({
  selector: 'betting-history',
  templateUrl: './betting-history.component.html'
})

export class BettingHistoryComponent implements OnInit {
  public BetStatesList = enums.BetStatesList;
  public SelectedRowId = 0;
  public openedFilter = true;
  public isLoading = false;
  public FilterObj = {
    fromDate: moment().set({hour: 0, minute: 0, second: 0, millisecond: 0}),
    toDate: moment().set({hour: 23, minute: 59, second: 59, millisecond: 0}),
    betState: '',
    isLiveBet: '',
  };
  public Data = [];
  public BetInfo: any;

  constructor(
    private settings: Settings,
    private http: RequestsService,
    public _core: CoreService
  ) {
  }

  ngOnInit() {
    setTimeout(() => {
      $('select').selectric();
    }, 500);
    $('#datetimeBettingHistoryFrom').datetimepicker({
      format: 'dddd, MMM Do YYYY, HH:mm',
      defaultDate: this.FilterObj.fromDate
    }).on('dp.change', (e) => {
      this.FilterObj.fromDate = e.date ? new Date(e.date.format()) : '';
    });
    $('#datetimeBettingHistoryTo').datetimepicker({
      format: 'dddd, MMM Do YYYY, HH:mm',
      defaultDate: this.FilterObj.toDate
    }).on('dp.change', (e) => {
      this.FilterObj.toDate = e.date ? new Date(e.date.format()) : new Date();
    });

    $('#select3').on('change', (e) => {
      this.FilterObj.betState = $('#select3').val();
      console.log($('#select3').val());
    });
    $('#select2').on('change', (e) => {
      this.FilterObj.isLiveBet = $('#select2').val();
    });
  }

  getBets() {
    this.isLoading = true;
    this.http.httpPost(this.settings.apiUrls.reports.BettingHistory, this.FilterObj).subscribe((e: any) => {
      if (!e.HasError)
        this.Data = e.Data;
      this.isLoading = false;
    });
  }

  getBetSlip() {
    this.isLoading = true;
    this.http.httpGet(`${this.settings.apiUrls.reports.GetBetSleepDetails}BetSleepId=${this.SelectedRowId}&language=en`)
      .subscribe((data: any) => {
        if (!data.HasError) {
          this.BetInfo = data.Data;
          this.BetInfo['SystemSelections'].forEach((item, ind) => {
            item.SelectionStake = JSON.parse(item.SelectionStake);
          });
        }
        this.isLoading = false;
      });
  }

  RemoveBetslipData() {
    this.BetInfo = null;
  }
}
