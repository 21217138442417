import {Component} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Settings} from '../../../core/services/settings';
import {RequestsService} from '../../../core/services/requests/requests.service';

declare var $: any;
declare var location: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})

export class LoginComponent {
  public pin = '';
  public passwordError: boolean;
  public hasError = {
    hasError: false,
    errMsg: ''
  };
  public loginStates = {
    'shopId': 0,
    'shopPin': 1,
    'terminalList': 2,
    'login': 4,
    'userType': 3,
  };

  public TerminalTypes = {
    0: 'Shop User / Guest User',
    1: 'Shop User',
    2: 'Guest User',
  };
  public valForm: FormGroup;
  private keyCodeList = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
  public state = 0;
  private shopInfo = {
    ShopId: '',
    Pin: '',
    IpAddress: ''
  };

  public requestLoading: boolean;

  public TerminalsList = [];

  constructor(
    private http: HttpClient,
    private httpCustom: RequestsService,
    private fb: FormBuilder,
    private route: Router,
    private settings: Settings
  ) {
    if (localStorage.getItem('cashirToken')) {
      this.route.navigate(['/']);
    }
    if (localStorage.getItem('terminalId')) {
      this.state = 3;
    } else if (localStorage.getItem('shopId') && localStorage.getItem('shoptoken')) {
      this.state = 2;
      this.getTerminals();
    }

    this.valForm = this.fb.group({
      'Username': [null, Validators.required],
      'Password': [null, Validators.required],
    });

    $(document).on('keyup', (e) => {
      e.stopPropagation();
      if (this.keyCodeList.indexOf(e.keyCode) > -1 && (this.state === 0 || this.state === 1)) {
        this.pushNumber(e.key);
      }
      if (e.keyCode === 13) {
        this.confirm();
      }
      if (e.keyCode === 8) {
        this.pin = this.pin.substring(0, this.pin.length - 1);
      }
    });
  }

  pushNumber(number) {
    this.pin = this.pin + number;

  }

  clear() {
    this.pin = '';
  }

  confirm() {
    this.hasError.hasError = false;
    if (this.state === 0) {
      this.shopInfo.ShopId = this.pin;
      this.state = 1;
      return this.clear();
    }
    if (this.state === 1) {
      this.shopInfo.Pin = this.pin;
      // this.state = 1;
      this.requestLoading = true;
      this.clear();
      this.http.post(`${this.settings.platformUrl}api/${this.settings.partnerId}/account/ShopLogin`, this.shopInfo)
        .subscribe((e: any) => {
          this.requestLoading = false;
          if (!e.HasError) {
            localStorage.setItem('shopId', e.Data.Id);
            localStorage.setItem('shoptoken', e.Data.Token);
            this.getTerminals();
          }
          if (e.HasError) {

            this.hasError.hasError = true;
            this.hasError.errMsg = 'Wrong Shop id or shop pin';
          }
        }, (error) => {
          this.requestLoading = false;

          this.hasError.hasError = true;
          this.hasError.errMsg = 'Wrong Shop id or shop pin';
        });
    }
  }

  getTerminals() {
    this.requestLoading = true;
    this.http.get(`${this.settings.platformUrl}api/${this.settings.partnerId}/account/GetShopTerminals`, this.addHeaders()).subscribe((e1: any) => {
      this.requestLoading = false;
      this.TerminalsList = e1.Data;
      this.state = 2;
      this.clear();
    });
  }

  selectTerminal(item) {
    localStorage.setItem('terminalId', item.Id);
    this.state = 3;
  }

  loginByUser() {
    this.state = 4;
  }

  login($ev, value: any) {
    $ev.preventDefault();
    this.requestLoading = true;
    for (const c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      this.hasError.hasError = false;
      const obj = {
        'ShopId': localStorage.getItem('shopId'),
        'ShopToken': localStorage.getItem('shoptoken'),
        'TerminalId': localStorage.getItem('terminalId'),
        'Username': value.Username,
        'Password': value.Password
      };
      this.http.post(`${this.settings.platformUrl}api/${this.settings.partnerId}/account/CashierLogin`, obj, this.addHeaders())
        .subscribe((e: any) => {
          this.requestLoading = false;
          if (e.HasError) {
            this.hasError.hasError = true;
            this.hasError.errMsg = 'Wrong Username or Password';
          }
          if (!e.HasError) {
            localStorage.setItem('cashirId', e.Data.Id);
            localStorage.setItem('cashirToken', e.Data.Token);
            localStorage.removeItem('guestMode');
            this.settings.guestMode = false;
            this.settings.cashierData = e.Data;
            this.httpCustom.httpGet(this.settings.apiUrls.GetSportOptions)
              .subscribe((e1: any) => {
                if (!e1.HasError) {
                  this.settings.sportOptions = e1.Data;
                  localStorage.setItem('sportOptions', JSON.stringify(e1.Data));
                }
              });
            this.route.navigate(['/sport']);
            localStorage.setItem('timeZone', this.settings.cashierData.TimeZoneId);
            localStorage.setItem('cashierData', JSON.stringify(e.Data));
          }
        }, (error) => {
          this.requestLoading = false;
          if (error.status === 401) {
            this.requestLoading = false;
            localStorage.clear();
            setTimeout(() => {
              location.reload();
            }, 10);
          }
        })
      ;
    }
  }

  guestLogin($ev) {
    $ev.preventDefault();
    this.requestLoading = true;
    localStorage.setItem('cashirId', '');
    localStorage.setItem('cashirToken', 'guestCashierToken');
    this.settings.guestMode = true;
    this.settings.cashierData = {CashierName: 'Guest User', LanguageId: 'en', TimeZoneId: 1};
    localStorage.setItem('guestMode', 'true');
    /*localStorage.setItem('cashierData', JSON.stringify(this.settings.cashierData));*/

    localStorage.setItem('timeZone', this.settings.cashierData.TimeZoneId);
    localStorage.setItem('cashierData', JSON.stringify(this.settings.cashierData));
    setTimeout(() => {
      this.route.navigate(['/sport']);
    }, 500);

  }

  addHeaders() {
    let headers;
    headers = new HttpHeaders({
      'shop-id': localStorage.getItem('shopId'),
      'shop-token': localStorage.getItem('shoptoken'),
      'cashier-id': localStorage.getItem('cashirId') || '',
      'cashier-token': localStorage.getItem('cashirToken') || ''
    });
    return {headers: headers};
  }


}
