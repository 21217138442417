<div class="tab-content">
  <div class="tab-pane active">
    <div class="box">
      <div class="box-header">
        <h2>
          <i class="fa fa-cog"></i>
          <span class="break"></span>
        </h2>
      </div>

      <div class="box-content">
        <div class="dashsett">

          <!-- ko template: {name: 'error-message', data: Message}-->
          <div class="error_message error-effect-jelly" data-bind="visible: IsVisible, css: MessageTypeClass" style="display: none;">
            <div class="error_message_icon_close clickable" data-bind="click: CloseButtonClick, clickBubble: false">
              <i class="fa fa-times"></i>
            </div>
            <div class="error_message_text clickable" data-bind="click: CloseButtonClick, clickBubble: false">
              <i class="fa fa-exclamation"></i>
            </div>
            <div class="error-success-progress"></div>
          <!--<div class="error_message_close_button" data-bind="click: CloseButtonClick, clickBubble: false"></div>-->
          </div>
          <div class="title" data-bind="text: Name">{{'Betslip' | translate}}</div>
          <div data-bind="foreach: Items">
            <!--<div class="check-row">
              &lt;!&ndash; could have used something like css: {checked: IsChecked} instead of css: Class but IsChecked will have to be an observable &ndash;&gt;
              <div class="checkBox menuband_checkbox checkbox medium" [ngClass]="{'checked': sS.AllowMultipleBetSlip}" (click)=" sS.AllowMultipleBetSlip = !sS.AllowMultipleBetSlip"></div>
              <div class="txt" data-bind="text: Name">Allow multi betslip (disable search by code)</div>
              <div class="option-2nd_level">
                <div data-bind="foreach: Items"></div>
              </div>
            </div>-->

            <div class="check-row">
              <div class="checkBox menuband_checkbox checkbox medium" [ngClass]="{'checked': sS.QuickBetSlip}" (click)=" sS.QuickBetSlip = !sS.QuickBetSlip"></div>
              <div class="txt" data-bind="text: Name">{{'Quick betslip' | translate}}</div>
              <div class="option-2nd_level">
                <div data-bind="foreach: Items"></div>
              </div>
            </div>

            <div class="check-row">
              <!-- could have used something like css: {checked: IsChecked} instead of css: Class but IsChecked will have to be an observable -->
              <div class="checkBox menuband_checkbox checkbox medium "  [ngClass]="{'checked': sS.AllowSystem}" (click)=" sS.AllowSystem = !sS.AllowSystem"></div>
              <div class="txt" data-bind="text: Name">{{'Allow systems' | translate}}</div>
              <div class="option-2nd_level">
                <div data-bind="foreach: Items">
                  <div class="checkBox menuband_checkbox checkbox medium" [ngClass]="{'checked': sS.AllowBankers}" (click)=" sS.AllowBankers = !sS.AllowBankers" title="Allow Bankers"></div>
                  <div class="txt" data-bind="text: Name">{{'Allow Bankers' | translate}}</div>

                  <div class="checkBox menuband_checkbox checkbox medium" [ngClass]="{'checked': sS.AllowGroups}" (click)=" sS.AllowGroups = !sS.AllowGroups" title="Allow Groups"></div>
                  <div class="txt" data-bind="text: Name">{{'Allow Groups' | translate}}</div>

                  <div class="checkBox menuband_checkbox checkbox medium"  [ngClass]="{'checked': sS.PermutationAnaliysis}" (click)=" sS.PermutationAnaliysis = !sS.PermutationAnaliysis" title="Permutation analysis"></div>
                  <div class="txt" data-bind="text: Name">{{'Permutation analysis' | translate}}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="title" data-bind="text: Name">{{'Second window' | translate}}</div>
          <div data-bind="foreach: Items">
            <div class="check-row">
              <!-- could have used something like css: {checked: IsChecked} instead of css: Class but IsChecked will have to be an observable -->
              <div class="checkBox menuband_checkbox checkbox medium" [ngClass]="{'checked': sS.AutoNavigateTVOdds}" (click)=" sS.AutoNavigateTVOdds = !sS.AutoNavigateTVOdds" title="Auto-navigate TV Odds Viewer"></div>
              <div class="txt" data-bind="text: Name">{{'Auto-navigate TV Odds Viewer' | translate}}</div>
              <div class="option-2nd_level">
                <div data-bind="foreach: Items"></div>
              </div>
            </div>

            <div class="check-row">
              <!-- could have used something like css: {checked: IsChecked} instead of css: Class but IsChecked will have to be an observable -->
              <div class="checkBox menuband_checkbox checkbox medium" [ngClass]="{'checked': sS.AutoNavigateLiveCenter}" (click)=" sS.AutoNavigateLiveCenter = !sS.AutoNavigateLiveCenter" title="Auto-navigate Live Center"></div>
              <div class="txt" data-bind="text: Name">{{'Auto-navigate Live Center' | translate}}</div>
              <div class="option-2nd_level">
                <div data-bind="foreach: Items"></div>
              </div>
            </div>
          </div>
          <!-- /ko --><!-- /ko -->

          <div class="row">
            <div class="col-xs-12 offset-top10 col-xs-pull-6 col-xs-offset-6">
              <div class="footer-buttons">
                <button (click)="saveSettings()" class="btn btn-shop-primary-color btn-auto">{{'SAVE OPTIONS' | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="clearfix"></div>
  </div>

  <div class="success_message">
    <div class="error_message error-effect-jelly" data-bind="visible: IsVisible, css: MessageTypeClass" style="display: none;">
    <div class="error_message_icon_close clickable" data-bind="click: CloseButtonClick, clickBubble: false">
      <i class="fa fa-times"></i>
    </div>
    <div class="error_message_text clickable" data-bind="click: CloseButtonClick, clickBubble: false">
      <i class="fa fa-exclamation"></i>
    </div>
    <div class="error-success-progress"></div>
  </div>
  </div>
</div>


<div class="success_message">
  <div class="error_message error-effect-jelly confirmation_message"  [ngStyle]="{display: isSuccess ? 'block': 'none'}">
    <div class="error_message_icon_close clickable">
      <i class="fa fa-times"></i>
    </div>
    <div class="error_message_text clickable">
      <span class="highlighted">{{'Success' | translate}}!</span>
      <span>{{'Settings Saved' | translate}}</span>
      <i class="fa fa-exclamation"></i>
    </div>
    <div class="error-success-progress"></div>
  </div>
</div>
