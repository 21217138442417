import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'annoucment-popup',
  templateUrl: './annoucment-popup.component.html'
})
export class AnnoucmentPopupComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
