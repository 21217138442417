<div id="modalWindow" tabindex="-1" role="dialog" aria-labelledby="modal-windowLabel" style="display: block;" class="modal betslip-modal keyboard-short">
  <div class="modal-dialog" role="document">

    <div class="modal-content">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <i class="fa fa-times"></i>
      </button>

      <div class="modal-header">
        <h4 class="modal-title">{{'Keyboard Shortcuts' | translate}}</h4>
      </div>

      <!--Modal body-->
      <div class="modal-body">
        <div class="row">
          <div class="col-xs-3">
            <div class="ks-keys-group">
              <div class="ks-key alt"> ALT </div>
              <div class="ks-plus"> + </div>
              <div class="ks-key"> M </div>
            </div>
            <div class="ks-text">
              {{'Focus MarketID textbox' | translate}}
            </div>
          </div>

          <div class="col-xs-3">
            <div class="ks-keys-group">
              <div class="ks-key alt"> ALT </div>
              <div class="ks-plus"> + </div>
              <div class="ks-key"> S </div>
            </div>
            <div class="ks-text">
              {{'Check the Systems checkbox' | translate}}
            </div>
          </div>

          <div class="col-xs-3">
            <div class="ks-keys-group">
              <div class="ks-key alt"> ALT </div>
              <div class="ks-plus"> + </div>
              <div class="ks-key"> G </div>
            </div>
            <div class="ks-text">
              {{'Check the Groups checkbox' | translate}}
            </div>
          </div>



          <div class="col-xs-3">
            <div class="ks-keys-group">
              <div class="ks-key alt"> ALT </div>
              <div class="ks-plus"> + </div>
              <div class="ks-key alt"> 1 - 9 </div>
            </div>
            <div class="ks-text">
              {{'Focus stake textbox on system Any [Number] of M' | translate}}
            </div>
          </div>

          <div class="col-xs-3">
            <div class="ks-keys-group">
              <div class="ks-key alt"> ALT </div>
              <div class="ks-plus"> + </div>
              <div class="ks-key"> T </div>
            </div>
            <div class="ks-text">
              {{'Focus Total Stake' | translate}}
            </div>
          </div>

          <div class="col-xs-3">
            <div class="ks-keys-group">
              <div class="ks-key alt"> ALT </div>
              <div class="ks-plus"> + </div>
              <div class="ks-key"> C </div>
            </div>
            <div class="ks-text">
              {{'Copy last betslip' | translate}}
            </div>
          </div>



          <div class="col-xs-3">
            <div class="ks-keys-group">
              <div class="ks-key alt"> ALT </div>
              <div class="ks-plus"> + </div>
              <div class="ks-key"> N </div>
            </div>
            <div class="ks-text">
              {{'New betslip (clear betslip)' | translate}}
            </div>
          </div>

          <div class="col-xs-3">
            <div class="ks-keys-group">
              <div class="ks-key alt"> ALT </div>
              <div class="ks-plus"> + </div>
              <div class="ks-key"> P </div>
            </div>
            <div class="ks-text">
              {{'Place bet' | translate}}
            </div>
          </div>

          <div class="col-xs-3">
            <div class="ks-keys-group">
              <div class="ks-key alt"> ALT </div>
              <div class="ks-plus"> + </div>
              <div class="ks-key"> F </div>
            </div>
            <div class="ks-text">
              {{'Display OMR Codes' | translate}}
            </div>
          </div>

          <div class="col-xs-3">
            <div class="ks-keys-group">
              <div class="ks-key alt"> ALT </div>
              <div class="ks-plus"> + </div>
              <div class="ks-key"> A </div>
            </div>
            <div class="ks-text">
              {{'Display Account Statements' | translate}}
            </div>
          </div>

          <div class="col-xs-3">
            <div class="ks-keys-group">
              <div class="ks-key alt"> ALT </div>
              <div class="ks-plus"> + </div>
              <div class="ks-key"> B </div>
            </div>
            <div class="ks-text">
              {{'Display Bet Approvals' | translate}}
            </div>
          </div>

          <div class="col-xs-3">
            <div class="ks-keys-group">
              <div class="ks-key alt"> ALT </div>
              <div class="ks-plus"> + </div>
              <div class="ks-key"> H </div>
            </div>
            <div class="ks-text">
              {{'Display Bet History' | translate}}
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
