import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Settings} from '../../../../core/services/settings';
import {RequestsService} from '../../../../core/services/requests/requests.service';

@Component({
  selector: 'password-info',
  templateUrl: './Password.component.html'
})

export class PasswordComponent {
  public valForm: FormGroup;
  public isSuccess = false;
  public isError = false;
  public ErrorList = [];

  constructor(
    private fb: FormBuilder,
    private settings: Settings,
    private http: RequestsService
  ) {
    this.valForm = this.fb.group({
      OldPassword: [null, Validators.required],
      NewPassword: [null, Validators.required],
      ConfirmPassword: [null, Validators.required],
    });
  }


  changePass($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
      this.valForm.controls[c].markAsTouched();
    }
    if (this.valForm.valid) {
      this.isSuccess = false;
      this.http.httpPost(this.settings.apiUrls.UpdateCashierPassword, value)
        .subscribe((e: any) => {
          if (!e.HasError) {
            this.isSuccess = true;
          }
        }, (err) => {
          if (err.error.ModelState) {
            this.isError = true;
            for (const key in err.error.ModelState) {
              this.ErrorList.push(err.error.ModelState[key]);
            }
          }
        });
    }
  }
}
